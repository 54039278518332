import React, { useEffect } from 'react';
import { useContext  } from 'react';

import { /*DataTableState,*/ DataTableContext } from "./DataTableState";

 import { /*DataTableState,*/ DataTableChildContext } from "./DataTableChildState";

 import { useSearchParams } from 'react-router-dom';
import { DEFAULT_RECORD_LIMIT } from './DataTableConsts';
import { strings, translate } from "../../../services/Localization";
import { getDataFromSessionStorage, setDataToSessionStorage } from '../../../utils/sessionStorage';

interface RecordLimitDropDownProps {
    globalScope:string
}

const RecordLimitDropDown: React.FC<RecordLimitDropDownProps> = ( props ) => {
    let DataTableContextObj;
    if(props.globalScope === 'child') {
        DataTableContextObj = useContext(DataTableChildContext);
    }else {
        DataTableContextObj = useContext(DataTableContext);
    }

    let [searchParams, setSearchParams] = useSearchParams();
 
    if(searchParams) {
        
    }
    let menuLimits = getDataFromSessionStorage("menuLimits")
    if(menuLimits && typeof(menuLimits) === 'string') menuLimits = JSON.parse(menuLimits)
    
    const options = [{value: 5,  label: translate(strings.TABLE_SHOWING, { value: 5 }) },
                    {value: 10,  label: translate(strings.TABLE_SHOWING, { value: 10 }) },
                    {value: 20,  label: translate(strings.TABLE_SHOWING, { value: 20 }) },
                    {value: 50,  label: translate(strings.TABLE_SHOWING, { value: 50 }) },
                    {value: 100, label: translate(strings.TABLE_SHOWING, { value: 100 }) }];

    let limit = DataTableContextObj.searchState.limit;
                
            
    const setLimitHandler = (limit: number) => {
        let searchState = DataTableContextObj.searchState;
        searchState.limit = limit;
        searchState.page = '';
        searchState.searchFields['limit'] = limit;
        setSearchParams({...searchState.searchFields});
        DataTableContextObj.setSearchState((prevSearchState: any) => { return {...searchState} });

    }                
    
    const handleChange = (e:any) => {
        const newMenus= menuLimits || []
        if(menuLimits) {
            const index = menuLimits.findIndex((menu) => menu.url == location.pathname)
            if(index != -1){
                newMenus[index].limit = parseInt(e.target.value)
            } else {
                newMenus.push({
                    url: location.pathname,
                    limit: parseInt(e.target.value)
                })
            }
        } else {
            newMenus.push({
                url: location.pathname,
                limit: parseInt(e.target.value)
            })
        }
        setDataToSessionStorage("menuLimits", JSON.stringify(newMenus))
        setLimitHandler(parseInt(e.target.value));        
    }

    useEffect(() => {
        if(searchParams.get("limit") && parseInt(searchParams.get("limit")) != DEFAULT_RECORD_LIMIT) {
            let searchState = DataTableContextObj.searchState;
            searchState.limit = searchParams.get("limit");
            searchState.page = '';
            limit=searchParams.get("limit");
            DataTableContextObj.setSearchState((prevSearchState: any) => { return {...searchState} });
        } else if(menuLimits) {
            const index = menuLimits.findIndex((menu) => menu.url == location.pathname)
            if(index != -1 && menuLimits[index].limit != DEFAULT_RECORD_LIMIT){
                let searchState = DataTableContextObj.searchState;
                searchState.limit = menuLimits[index].limit;
                searchState.page = '';
                searchState.searchFields['limit'] = menuLimits[index].limit;
                setSearchParams({...searchState.searchFields});
                DataTableContextObj.setSearchState((prevSearchState: any) => { return {...searchState} });
            }
        } 
    },[])
    //let direction:any = (smallScreen) ? "column" : 'row';
    // if(DataTableContextObj.dataType === 'memory') {
    //     return null;
    // }else {
        return (
        //  <div style={{display: 'flex', flexDirection: direction, alignItems: 'center', justifyContent: 'left'}}>
        <div className='showing-data'>
                {/*!smallScreen && <div className="pe-4 text-nowrap">Per page</div>*/}
            
                    <select onChange={handleChange} className="form-select" aria-label="Default select " value={limit}>
                        {options.map(function(option, index) {
                            return (<option value={option.value} key={index}>{option.label}</option>);
                        })}
                    </select>
            </div>
        )
    //}
}


export default RecordLimitDropDown;