import { strings } from "./../../services/Localization";
import {  DetailPageActionType, DetailPagePropsType } from '../../datatypes/datatypes';
import { faList, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { getAppBase } from '../../utils/consts';
import { useContext } from "react";
import AppContext from '../../context/AppContext'
import { TAB_GROUP_INFO, TAB_SEND_MULTICAST, TAB_GROUP_ACTIVITY  } from '../../datatypes/tabsconstants'
import { IConstants } from "src/types";
declare const constants: IConstants;


const detailPageNav = ( actionArgs ) => {

    const { navigate, tab, id, row } = actionArgs;
    let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : null;
    navigate(`${getAppBase()}/groups/${id}/${tab}`, {state: { row: row, prevPageUrl:prevPageUrl}})
}


const GroupDetailPage:DetailPagePropsType =  {

    getBreadCrumbArr: ( breadCrumbArgs ) => {
        
        let id = breadCrumbArgs.hasOwnProperty('id') ? breadCrumbArgs['id'] : 0;
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : '';
        return [
            {label: strings.NAV_MANAGE_GROUPS, url: prevPageUrl}, 
            {label: `${id}`, url:''}
        ]
    },

    getActions : (actionArgs, navigate) => {

        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : '-1';
        const { row } = actionArgs;
        const AppContextObj = useContext(AppContext);
        const user = AppContextObj.user;
        const groupid = row.hasOwnProperty('groupid') ? row.groupid : (row.hasOwnProperty('id') ? row.id : null);
                
        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.EDIT_GROUP,
                icon: faList,
                key: TAB_GROUP_INFO,
                action: (row) => detailPageNav({ navigate: navigate, tab:TAB_GROUP_INFO, id:groupid, row:row, prevPageUrl:prevPageUrl}),
                content: {
                    type: 'SchemaModal',
                    url:'/rest/groups?get_schema=dassui,put',
                    backUrl: prevPageUrl
                },
                EditObject: groupid ? { groupid } : null
            }
        ]

        if(constants.server_type !== "dmp") {
            actions.push({  
                type: "tab",
                text: strings.SEND_MULTICAST,
                icon: faPaperPlane,
                key: TAB_SEND_MULTICAST,
                action: (row) => detailPageNav({ navigate: navigate, tab:TAB_SEND_MULTICAST, id:groupid, row:row, prevPageUrl:prevPageUrl}),
                visible: () => !user?._readonly,
                content: {
                    type: 'SchemaModal',
                    url: `/rest/multicast/${groupid}/payloads/dl?get_schema=dassui,post`,
                    backUrl: `${getAppBase()}/groups/${groupid}/group-activity`
                },
                EditObject: null
            })
            actions.push( {
                type: "tab",
                text: strings.GROUP_ACTIVITY_TITLE,
                icon: faList,
                key: TAB_GROUP_ACTIVITY,
                action: (row) => detailPageNav({ navigate: navigate, tab:TAB_GROUP_ACTIVITY, id:groupid, row:row, prevPageUrl:prevPageUrl}),
                content: {
                    type: 'Component',
                    url: '',
                    backUrl: prevPageUrl,
                    props: { groupid:groupid },
                    ComponentName: 'GroupActivityDataTable'
                }
            })
        }

        return actions;
    }
}

export default GroupDetailPage;


