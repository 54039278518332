// tslint:disable:max-line-length
import React, { Suspense, lazy, ReactNode, useContext, useEffect, useState } from "react";
import { NavigateFunction, Params, useLocation, Location, useNavigate, useParams } from "react-router-dom";

import { Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

import stripJsonComments from "strip-json-comments";
import { GenericDassQuery, IRequestMethod, IRequestReturnData } from "../../services/BasicDassQueries";

import { toast } from "../../utils/Toaster";
import { strings } from "../../services/Localization";

import { IJsonSchemaObject } from "../../schemaengine/UiJsonSchemaTypes";
import { IGetResourcesOptions, IUiSchemaElemArgs, IUiSchemaUpdateState } from "../../schemaengine/client/SchemaController";

import SchemaPanel from "../../schemaengine/client/SchemaPanel";
import { Container } from "react-bootstrap";

// The good old status lights
import yellowLight from "../../../resources/images/yellow_light.png";
import redLight from "../../../resources/images/red_light.png";
import greenLight from "../../../resources/images/green_light.png";
import amberLight from "../../../resources/images/amber_light.png";

import AppContext, { IDassUiAppContext } from '../../context/AppContext'

// Import for map extension
import "./SchemaModal.css";

const VisTimeline = lazy(() => import("../../schemaengine/client/VisTimeline"));

import { IConstants } from "src/types";
import { ipadWidth, windowWidth } from '../../utils/consts';

declare const headerContentTypeJson;
declare const constants: IConstants;

import { dialog } from "../Common/ConfirmDialog";
import { faInfo } from "@fortawesome/pro-regular-svg-icons";

import { setTranslationHandler } from "../../schemaengine/client/SchemaTranslationAudit";

// Standard SchemaModal extensions
import "../../schemaengine/client/ExtCarousel";
import "../../schemaengine/client/ExtMapView";
import "../../schemaengine/client/ExtChartJs";
import "../../schemaengine/client/ExtAmChart";
import "../../schemaengine/client/ExtVegaChart";
import "../../schemaengine/client/ExtColorPicker";
import "../../schemaengine/client/ExtProgressBar";
import "../../schemaengine/client/ExtMarkdown"
import "../../schemaengine/client/ExtNavbar"

import "../../schemaengine/client/ExtSchemaFormAceEdit";
import "../../schemaengine/client/ExtSchemaFormMonacoEdit";

import "../../schemaengine/client/SchemaLayoutGridStack";
import "../../schemaengine/client/ExtDateRangePicker";
import "../../schemaengine/client/ExtJsonObjectInspector";
import "../../schemaengine/client/ExtAgGrid";
import "../../schemaengine/client/ExtDashboardStatus";
import "../../schemaengine/client/ExtDashboardSelect";

// FIXME: since masonry is no longer working with react 18, we simply for the moment use the stack layout in place of masonry.
//import "../../schemaengine/client/SchemaLayoutMasonry";       FIXME:
import SchemaLayout from "../../schemaengine/client/SchemaLayout";
import { registerExtensionLayoutComponent, registerExtensionTextMarkerHandler } from "../../schemaengine/client/SchemaExtensions";


registerExtensionLayoutComponent("masonry", SchemaLayout);



// Dedicated extensions
import "./RadioConfig";
import "./PageTable";

import { BreadCrumbType } from "src/datatypes/datatypes";
import { BreadcrumbComponent } from "../Common/BreadcrumbComponent";
// import ScrollButton from "../ScrollButton";



const testPrefix = ""; // "/uitest";       // Note NEVER commit with this enabled.

export interface ISchemaModalHooks {
    updateValues?: (obj: any) => void;
}

interface ISchemaModalState {
    activeTab: string;
    close: boolean,
    success: boolean;
    initialReadOnly: boolean;
    loggedIn: boolean;
    updateCount: number;

    extensions: {
        [ext: string]: (args: IUiSchemaElemArgs) => ReactNode;
    };
    textMarkerExtensions: {
        [ext: string]: (key: string, style: any) => JSX.Element[];
    };
    libExtensions: {
        [name: string]: (...any) => any;
    }

    debug: boolean;
    ShowModal: boolean;
    modalFullScreen: true | string;
}

interface ISchemaModalProps {
    Schema?: IJsonSchemaObject;
    SchemaUrl?: string;
    EditObject?: any;
    editMode?: boolean;
    breadCrumbArr?: BreadCrumbType[];
    loadDataOnOpen?: boolean;           // if set, data resources with onOpenOnLoadRequest will be loaded
    warningFooter?: boolean;            // ?? should be removed
    OnClose?: (obj: any, refreshRequired: boolean) => void;
    OnChange?: (currentObject: any, lastObject: any) => void;
    type: "modal" | "page" | "navbar" | "page-fluid";
    hooks?: ISchemaModalHooks;
}



// Register the "standard" text handlers that are defined as standard
const fa = (fa: string, key: string, style: any) => {
    return <i key={key} className={"fa-regular " + fa + " fa-fw"} style={style} />;
};

registerExtensionTextMarkerHandler("ok",       (key, style) => [fa("fa-circle-check", key, style)]);
registerExtensionTextMarkerHandler("warning",  (key, style) => [fa("fa-circle-exclamation", key, style)]);
registerExtensionTextMarkerHandler("error",    (key, style) => [fa("fa-circle-xmark", key, style)]);
registerExtensionTextMarkerHandler("cog",      (key, style) => [fa("fa-cog", key, style)]);
registerExtensionTextMarkerHandler("copy",     (key, style) => [fa("fa-copy", key, style)]);
registerExtensionTextMarkerHandler("download", (key, style) => [fa("fa-download", key, style)]);
registerExtensionTextMarkerHandler("trash",    (key, style) => [fa("fa-trash-alt", key, style)]);
registerExtensionTextMarkerHandler("bell",     (key, style) => [fa("fa-bell", key, style)]);
registerExtensionTextMarkerHandler("plane",    (key, style) => [fa("fa-paper-plane", key, style)]);
registerExtensionTextMarkerHandler("terminal", (key, style) => [fa("fa-rectangle-terminal", key, style)]);
registerExtensionTextMarkerHandler("add",      (key, style) => [fa("fa-add", key, style)]);
registerExtensionTextMarkerHandler("import",   (key, style) => [fa("fa-file-import", key, style)]);
registerExtensionTextMarkerHandler("file-arrow-down", (key, style) => [fa("fa-file-arrow-down", key, style)]);
registerExtensionTextMarkerHandler("tower-broadcast", (key, style) => [fa("fa-tower-broadcast", key, style)]);
registerExtensionTextMarkerHandler("play",    (key, style) => [fa("fa-play", key, style)]);
registerExtensionTextMarkerHandler("stop",    (key, style) => [fa("fa-stop", key, style)]);

registerExtensionTextMarkerHandler("yellow-light", (key) => [<img key={key} src={yellowLight} style={{ width: "1em", height: "auto" }} />]);
registerExtensionTextMarkerHandler("amber-light",  (key) => [<img key={key} src={amberLight}  style={{ width: "1em", height: "auto" }} />]);
registerExtensionTextMarkerHandler("red-light",    (key) => [<img key={key} src={redLight}    style={{ width: "1em", height: "auto" }} />]);
registerExtensionTextMarkerHandler("green-light",  (key) => [<img key={key} src={greenLight}  style={{ width: "1em", height: "auto" }} />]);


interface Hooks {
    location: Location;
    navigate: NavigateFunction;
    params: Readonly<[string] extends [string] ? Params<string> : Partial<string>>;
    context: IDassUiAppContext;
}


interface ISchemaModalClassProps extends ISchemaModalProps {
    useHooks: Hooks;
}



async function downloadAsFile(value: string, filename: string) {
    await constants.wait;
    const response = await fetch("/download_storage", {
        credentials: "same-origin",
        headers: headerContentTypeJson,
        method: "POST",
        body: JSON.stringify({ filename, value }),
    });
    if (response.status === 401) { // the status should be changed!!!
        window.location.href = '/app/signout?resignin';
    }
    if (response.status === 200) {
        window.location.href = await response.text();
    }
}


async function showMessage(type: "success" | "error" | "confirm", message: string) {

    const messageTxt = (message || "").substring(0, 1).toUpperCase() + (message || "").substring(1);

    // this.log("Toast, type=" + type + ", message=" + message);
    if (type === "success") {
        toast.success(messageTxt);
        return true;
    } else if (type === "error") {
        toast.error(messageTxt);
        return false;
    } else {
        return await dialog({
            title: "Confirm",
            description: messageTxt,
            actionLabel: strings.OK,
            cancelLabel: strings.CANCEL,
            faIcon: faInfo,
        });
    }
}

class SchemaModalClass extends React.Component<ISchemaModalClassProps, ISchemaModalState> {

    linkRef = React.createRef<HTMLAnchorElement>();
    schemaRef = React.createRef<SchemaPanel>();
    develMonitorTimer = null;
    debugLogBuf: any[] = [];
    isLocalhost = ["localhost", "127.0.0.1"].includes(window.location.hostname);
    argDebug = window.location.search?.indexOf("debuG") > 0;
    readOnlyMode = false;
    modifyQueryExecuted = false;

    constructor(props: ISchemaModalClassProps) {
        super(props);

        setTranslationHandler(async (translations, verifications) => {

            try {
                this.setState({ updateCount: this.state.updateCount + 1 });
                await GenericDassQuery("/rest/audit-translations", {
                    method: "POST",
                    data: { translations, verifications }
                });
            } catch (e) {
                console.log("Can't save translation audits", e.message);
            }
        });
   

        let modalFullScreen = (windowWidth() < ipadWidth) ? true : "xxl-down";

        if (props.hooks) {
            props.hooks.updateValues = this.updateValues;
        }

        this.state = {
            ShowModal: true,
            initialReadOnly: this.props.EditObject ? this.props.EditObject.__readonly !== false : false,
            debug: false,

            loggedIn: this.props.useHooks.context?.user?.userid != null,
            updateCount: 0,

            close: false,
            success: false,
            activeTab: "",
            modalFullScreen: modalFullScreen,
            extensions: {
                timeline: this.timelineExtension,
            },
            textMarkerExtensions: {
            },
            libExtensions: {
                downloadAsFile,
                console: (...args) => this.log("EXPR", ...args),
                navigate: this.props.useHooks?.navigate,
                getLanguage: () => this.props.useHooks.context?.navBarState?.language,
                stripComments: (str: string) => stripJsonComments(str),
            },
        };

        this.log("EditDevice", {...this.props.EditObject});
    }



    public updateValues = (obj: any) => {
        this.schemaRef.current?.updateValues({ values: obj}, null, null);
    }


    public log = (...args) => {
        if (this.state.debug) {
            console.log(...args);
        } else {
            if (this.debugLogBuf && this.debugLogBuf.length < 100) {
                this.debugLogBuf.push(args);
            }
        }
	}



    public getSettings = (scope: string) => {
        if (scope === "default-map-center") {
            const ui = this.props.useHooks.context.user?.ui_settings || {};
            return {
                lat: ui.map_center_latitude, lng: ui.map_center_longitude, zoom: ui.map_zoom
            };
        }
        return {};
	}



    public timelineExtension = (args: IUiSchemaElemArgs) => {

        const { key, value } = args;
        const { options, items, groups } = value || {};

        return args.embedObject(
            <Suspense key={key} fallback={<div><i className="fa-regular fa-spinner fa-spin"></i></div>}>
                <VisTimeline options={options} items={items} groups={groups}></VisTimeline>
            </Suspense>,
            { isContainer: true }
        );
    }




    public async componentDidMount() {
        if (this.props.useHooks.context?.user?.userid && !this.state.loggedIn) {
            this.setState({ loggedIn: true })
        }
    }


    public getResources = async (reguestMethod: string, url: string, options: IGetResourcesOptions) => {
        let abort = false;

        const returnData: IRequestReturnData = {} as any;
        const abortHandle = () => {
            if (abort) { return; }
            abort = true;
            if (returnData?.abort) { returnData.abort(); }
        };


        try {
            if (options.addAbortHandler) {
                options.addAbortHandler(abortHandle);
            }

            const method: IRequestMethod = (reguestMethod || "get").toUpperCase() as any;
            if (method !== "GET") {
                this.modifyQueryExecuted = true;
            }

            this.log(`Loading resource ${method} '${url}'`);


            let prefix: string = null;
            if (url.startsWith(":test:")) {
                url = url.substring(6);
                if (testPrefix && ["localhost", "127.0.0.1"].includes(window.location.hostname)) {
                    prefix = testPrefix;
                }
            }

            // When we call the fetch() we receive back the response object so we can extract the headers.
            // This function will take the raw headers and turns them into an object.
            //
            const unpackReturnData = () => {
                if (options.responseObject && !options.responseObject.headers && returnData.response) {
                    options.responseObject.headers = {};
                    returnData.response.headers.forEach((value, key) => {
                        options.responseObject.headers[key] = value;
                    });
                }
            }


            const chunkCallback = async (data: string, chunkIdx: number) => {
                if (abort) { return; }
                unpackReturnData();
                if (options?.chunkCallback) {
                    options.responseObject.chunkIdx = chunkIdx;
                    await options.chunkCallback(data, chunkIdx);
                }
            }

            // FIXME: why is the context not set sometimes?
            const data = url === "/rest/users/$" && method === "GET" && this.props.useHooks.context?.user?.userid
                ? { data: this.props.useHooks.context.user, status: 200 }
                : await GenericDassQuery(url, {
                    data: options && options.body,
                    headers: options && options.headers,
                    method, prefix, chunkCallback, returnData,
                });

            if (abort) {
                throw new Error("ABORT");
            }

            unpackReturnData();
            delete options.responseObject.chunkIdx;

            // If we update the user and set the ui_settings, we need to reflect the update immediately
            if (url === "/rest/users/$" && method === "PUT"  &&  options?.body?.ui_settings) {
                this.props.useHooks.context.updateUser({ ...this.props.useHooks.context.user, ui_settings: options?.body?.ui_settings });
            }
            if (options.removeAbortHandler) {
                options.removeAbortHandler(abortHandle);
            }

            return { ok: data.status >= 200 && data.status <= 206, data: data.data, status: data.status };
        } catch (e) {

            if (options.removeAbortHandler) {
                options.removeAbortHandler(abortHandle);
            }

            if (abort) {
                return { ok: false, data: null, status: -1 };
            } else {
                console.log("Error loading resource '" + url + "'", e);
            }
            return { ok: false, data: e.message, status: e.status };
        }
    };



    
    public componentWillUnmount() {
        // document.removeEventListener("mousedown", this.handleClickOutside);
        clearInterval(this.develMonitorTimer);
    }

    // What should this function do??
    public handleClickOutside = (e: any) => {
        this.log("Inside handleClickOutside", {...e});
    };

    public closeModal = () => {
        this.setState({ ShowModal: false });
        if (this.props.OnClose) {
            this.props.OnClose(null, this.modifyQueryExecuted);
        }
    };



    public updateObjStates = async (state: IUiSchemaUpdateState) => {
        
        if (state.close && !this.state.close) {
            this.setState({ ShowModal: false });
            if (this.props.OnClose) {
                this.props.OnClose(state.success ? this.schemaRef?.current?.self.objects.newValues : null, state.success || this.modifyQueryExecuted);
            }
        }

        if (state.close != null) {
            this.setState({ close: state.close });
        }


        if (state?.debug && this.debugLogBuf) {
			// When debugging is enabled first time, we dump the buffer of the first log messages that was recorded up till now
            for (const db of this.debugLogBuf) {
                console.log(...db);
            }
            this.debugLogBuf = null;
        }
        if (state?.debug != null) {
            this.setState({ debug: state.debug });
        }


        if (state.currentValues && this.props.OnChange) {
            this.props.OnChange(state.currentValues, state.lastValues)
        }

        if (state.activeTab != null) {
            this.setState({ activeTab: state.activeTab });
        }

        if (state.update) {
            this.setState({ updateCount: this.state.updateCount + 1 });
        }
    };


    public componentDidUpdate(prevProps: Readonly<ISchemaModalProps>, prevState: Readonly<ISchemaModalState>, snapshot?: any): void {
        if (this.props.useHooks.context?.user?.userid && !this.state.loggedIn) {
            this.setState({ loggedIn: true })
        }
    }
   
    // helpLinkCallback is invoked when the user press the context help link.
    // As we need to be authenticated when accessing the documentation in the DASS
    // we first get the token from the DASS, then append it as an query to the 
    // help link, then finally "click" the link.
    public helpLinkCallback(link: string) {
        this.getResources("get", "/rest/users?get_doctoken=true", { responseObject: {} as any, addAbortHandler: null, removeAbortHandler: null }).then((tok) => {

            const [root,hash] = link.split("#");
            const [path,query] = root.split("?");
            const q = (query || "") + (tok.data.token ? (query ? "&t=" : "t=") + tok.data.token : "");

            if (tok.ok) {
                this.linkRef.current.href = path + (q ? "?" + q : "") + (hash ? "#" + hash : "");
                this.linkRef.current.click();
            }
        });
        return false;
    }



    public render() {
        if (!this.state.loggedIn) {
            return <div><i className="fa-regular fa-spinner fa-spin"></i></div>
        }

        const dict = {
			"false": strings.NO,
			"true": strings.YES,
            click_to_unlock: strings.CLICK_TO_UNLOCK,
            cancel: strings.CANCEL,
		};
		const body = (
            <SchemaPanel
                ref={this.schemaRef}
                jsonSchema={this.props.Schema}
                jsonSchemaUrl={this.props.SchemaUrl}
                object={this.props.EditObject}
                initialReadOnly={this.state.initialReadOnly}
                initialActiveTab={this.state.activeTab}
                extensions={this.state.extensions}
                textMarkerExtensions={this.state.textMarkerExtensions}
                updateState={this.updateObjStates}
                getResources={this.getResources}
                showMessage={showMessage}
                loadDataOnOpen={this.props.loadDataOnOpen || false}
                localeDictionary={dict}
                libExtensions={this.state.libExtensions}
                debug={this.state.debug}
                log={this.log}
                getSettings={this.getSettings}
                helpLinkCallback={(link) => this.helpLinkCallback(link)}
                defaultLayoutOptions={{
                    titleLayout: "left",
                    descriptionLayout: "popup",
                    componentLayout: "right",
                    titleWidthPercent: 40,
                    numColumns: 0,
                    cardStyle: "normal",
                    panelLayout: "masonry",
                    cardSize: "col-2",
                    arrayElementRenderMode: "render-always",
                    cardRenderMode: "render-after-visible",
                    panelRenderMode: "render-after-visible",
                }}
            />
		);

        // This is not really clean. But the problem is that when calling this function, the state of the SchemaPanel
        // has not yet been updated, so it can't genereate the buttons based on its own inner state.
        const controlButtons = this.schemaRef?.current
            ? this.schemaRef?.current.getControlButtons(this.readOnlyMode,
                                                        this.isLocalhost || this.argDebug, this.state.debug)
            : [];

        if (this.props.type === "modal") {
            return this.renderModal(body, controlButtons);
        } else if (this.props.type === "navbar") {

            return body;

        } else {
            return this.props.breadCrumbArr 
                    ? this.renderFullPage(this.props.type === "page-fluid", body, controlButtons) 
                    : this.renderPage(this.props.type === "page-fluid", body, controlButtons);
        }
    }



    public renderModal(body: JSX.Element, controlButtons: ReactNode) {

        const uiSchema = this.schemaRef?.current?.self.objects?.rootJsonSchema?.$uiSchema;

        return (
            <div>
                <a href={"#"} ref={this.linkRef} target="_blank" rel="noopener noreferrer" style={{display: "none"}}/>
                <Modal
                    show={this.state.ShowModal}
                    onHide={() => this.closeModal()}
                    className="schema-engine-content"
                    size="xl"
                    fullscreen={this.state.modalFullScreen}
                    backdrop="static"
                >
                    <Modal.Header closeButton={true} className="schema-engine-header">
                        <Modal.Title>
                            {uiSchema && uiSchema.modal && uiSchema.modal.title || ""}
                        </Modal.Title>
                    </Modal.Header>


                    <Modal.Body className="schema-engine-body">
                        {body}
                    </Modal.Body>


                    <Modal.Footer className=" schema-engine-footer">
                        <div className="buttons">
                            {controlButtons}
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }



    // renderPage()
    // This render function is called when the schema modal type is set to "page". I contains all the outer
    // HTML to render the actual body of the schema, but inside a page

    public renderPage(fluid: boolean, body: JSX.Element, controlButtons: ReactNode) {

        return (
            <Container {...{fluid}} className="schema-detail-page" style={{width: '100%'}}>
                <a href={"#"} ref={this.linkRef} target="_blank" rel="noopener noreferrer" style={{display: "none"}}/>

                {controlButtons && <div className="header">
                    <div className="buttons">
                        {controlButtons}
                    </div>
                </div>}

                <div className="body" id="schema-detail-page-body">
                    {body}
                </div>
      {/*        <ScrollButton />   */}
            </Container>
        );
    }


    public renderFullPage(fluid: boolean, body: JSX.Element, controlButtons: ReactNode) {

//            <a href={"#"} ref={this.linkRef} target="_blank" rel="noopener noreferrer" style={{display: "none"}}/>


        return (<div className={"child-tab-wrapper ow-dashboard"} >
            <div className="row mb-2 mr-0-only d-lg-flex single-page-header justify-content-between border-2 besides-data-table" >
                <div className="col-lg-4 col-md-auto col-xs-12">
                    {this.props.breadCrumbArr && <BreadcrumbComponent breadCrumbArr={this.props.breadCrumbArr}  />}
                </div>

                <div className="col-lg-80 col-md-auto col-xs-12 d-flex align-items-start flex-wrap">
                    {controlButtons}
                </div>
            </div> 

            { this.renderPage(fluid, body, null) }


        </div>);
    }

}


/*
            <div className="d-flex flex-column flex-grow border-2 border-primary" style={{height: '100%'}}>
                { this.renderPage(fluid, body, null) }
            </div>
*/

  

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();
        const context = useContext(AppContext);
        const hooks: Hooks = { location, navigate, params, context };
        return (
            <Component
            {...props}
            useHooks={hooks}
        />);
    }
  
    return ComponentWithRouterProp;
}

export const SchemaModal: React.FC<ISchemaModalProps> = withRouter(SchemaModalClass);


let privateSetModal: (obj) => void;

export const SchemaModalContainer = () => {

    const [modal, setModal] = useState(null);

    useEffect(() => {
        privateSetModal = setModal;
        return () => privateSetModal = null;
    }, [])
    
    return modal;
}



export const ShowSchemaAsModal = async (schemaUrl: string, returnModelValue: boolean = false) => {

    return new Promise<boolean | object>((resolve, reject) => {
          
        privateSetModal?.(<SchemaModal
            key="modal"
            SchemaUrl={schemaUrl}
            OnClose={(action) => { 
                privateSetModal?.(null);
                resolve(returnModelValue ? action : (action != null));          
            }}
            type="modal"
        />);
    });
}

