// tslint:disable:max-line-length
import React, { ReactNode, Ref} from "react";
import { ButtonToolbar, Col, Form, Nav, Card, Row, ToggleButton, ButtonGroup, OverlayTrigger, Tooltip, Button, Tab, Table, Accordion, Popover, Alert, Dropdown } from "react-bootstrap";

import CollapseCard from "./CollapseCard"

import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Dropzone from "react-dropzone";

import SchemaController, { flatCardList, IElemNode, IEmbedObjectOptions, ISchemaModalProps, IUiSchemaCardArgs, IUiSchemaElemArgs, IUiSchemaSetValueResult, Self } from "./SchemaController";
import { IJsonSchemaObject, IUiActionButton, IUiSchema, IUiSchemaDropFile, IUiSchemaPanelOptions } from "../UiJsonSchemaTypes";


import { evalExpr, evalString, getPathAndKey, IControl, IExprObjects, IExprScope, ISchemaLib, verificationDescription } from "./SchemaTools";
// Controllers

import LazyLocaleDatePicker from "./LazyLocaleDatePicker";
import SchemaForm, { ISchemaFormProps } from "./SchemaForm";

import "./SchemaLayout";		// default render
import { ISchemaLayoutComponentRef } from "./SchemaLayout";
import { HoverOverlay } from "./HoverOverlay";
import { isMobile } from "react-device-detect";
import { registeredExtensionCardHandlers, registeredExtensionFormsComponents, registeredExtensionHandlers, registeredLayoutComponents,
	     registerExtensionCardHandler } from "./SchemaExtensions";
import DateRangePicker from "./ExtDateRangePicker";


const sizeClass = {
	"col-3": "schema-engine-card-width-column3",
	"col-2": "schema-engine-card-width-column2",
	"col-1": "schema-engine-card-width-column1",
	"fill-100%": "schema-engine-card-100-percent"
};


// This should be moved to the style CSS.
const loaderStyle: any = {
	position: 'absolute',
	top: "50%",
	left: "50%"
}
const loaderMobileStyle: any = {
	position: 'absolute',
	top: "40%",
	left: "45%"
}



/**
 * card handlers
 */
function addCollapsibleCardHandle(args: IUiSchemaCardArgs, children: ReactNode) {

	const { layoutOptions, key, self, hasError, title, readOnly } = args;
	const { objects } = self;

	const scope = { readOnly };
	const collapsible = layoutOptions.cardStyle === "uncollapsed" || layoutOptions.cardStyle === "collapsed";
	const cardState   = objects.values["__cardstate_" + key] ?? layoutOptions.cardStyle === "uncollapsed";
	const showError   = collapsible && !cardState && hasError;
	const titleObj    = <div className="schema-engine-card-header-title">
		{args.stringToComponent(title)}
		{showError ? " " : ""}
		{showError ? fa("fa-circle-exclamation", {color:"red"}) : null}
	</div>;

	const bodyWrapClass = {
		"dashboard":        "schema-engine-dashboard-card-layout-1",
		"dashboard-center": "schema-engine-dashboard-card-layout-2",
		"dashboard-flex":   "schema-engine-dashboard-card-layout-3",
	}[layoutOptions.cardStyle];

	const gridCard  = layoutOptions.cardStyle?.startsWith("dashboard");
	const cardClass = (layoutOptions.cardStyle?.startsWith("dashboard") ? "schema-engine-dashboard-card" : "schema-engine-card")
						+ (gridCard ? " grid-stack-item-content" : "") 
						+ " " + sizeClass[layoutOptions.cardSize];

	const reactActionButtons = getActionButtons(args.actionButtons, self, scope,
									args.updateValues, 
									(a: string, b: string) => args.stringToComponent(a)) ;

	const body = bodyWrapClass ? (<div className={bodyWrapClass}>{children}</div>) : children;
	const card = (
		<CollapseCard id={key} key={key} header={titleObj} headerRight={reactActionButtons} body={body}
				className={cardClass}
				state={cardState}
				collapsible={collapsible}
				onFinishAnimation={args.updateLayout}
				onChangeState={() => args.updateValues({ values: { ["__cardstate_" + key]: !cardState }})}
		/>
	);

	return gridCard ? <div className="grid-stack-item" id={key} key={key}>{card}</div> : card;
}

function addNoFrameCardHandle(args: IUiSchemaCardArgs, children: ReactNode) {

	const { key, layoutOptions } = args;

	return (<div key={key} id={key} className={"schema-engine-card " + sizeClass[layoutOptions.cardSize]}>
		{children}
	</div>);

}

registerExtensionCardHandler("normal",           addCollapsibleCardHandle);
registerExtensionCardHandler("collapsed",        addCollapsibleCardHandle);
registerExtensionCardHandler("uncollapsed",      addCollapsibleCardHandle);
registerExtensionCardHandler("dashboard",        addCollapsibleCardHandle);
registerExtensionCardHandler("dashboard-center", addCollapsibleCardHandle);
registerExtensionCardHandler("dashboard-flex",   addCollapsibleCardHandle);
registerExtensionCardHandler("noframe",          addNoFrameCardHandle);


interface IUiActionButtonExt extends IUiActionButton {
	ref?: Ref<any>;
} 

/**
 * getActionButtons take an array of actionButtons (IUiActionButton) and generate the actual
 * react buttons
 * 
 */

function getActionButtons(actionButtons: IUiActionButtonExt[], self: Self, scope: IExprScope,
						  updateValues: (update: IUiSchemaSetValueResult) => void,
						  parseAndFormatText: (str: string, key: string) => ReactNode,
						  inDropdown?: boolean) {

	if (!actionButtons || actionButtons.length === 0) { return null; }

	const reactButtons: ReactNode[] = [];

	// Render the button array. This implementation using array will make it easy to add more custom buttons
	// later...
	for (let idx = 0; idx < actionButtons.length; idx++) {

		const but = actionButtons[idx];
		const butUi = but.uiSchema || {};
		if (butUi.hidden === true 
			|| (typeof butUi.hidden === "string" && evalExpr(butUi.hidden, self.lib, self.objects, scope, false))) {
			continue
		}

		const evReadOnly = typeof butUi.readOnly === "boolean" ? butUi.readOnly
							: typeof butUi.readOnly === "string"
								? evalExpr(butUi.readOnly, self.lib, self.objects, scope, false) : null;
		const readOnly = typeof evReadOnly === "boolean" ? evReadOnly : self.objects.control.readOnly;

		const value = butUi.getValue    && evalExpr(butUi.getValue, self.lib, self.objects, scope, false);
		const title = but.title       && parseAndFormatText(but.title, "action-button-txt-" + idx);
		const desc  = but.description && parseAndFormatText(but.description, "action-button-desc-" + idx);
		let   onClick = !butUi.setValue ? null : () => updateValues(evalExpr(butUi.setValue, self.lib, self.objects, scope, {}) || {});

		const wrapHelp = (obj: ReactNode, key: string) => {
			return desc ? (<HoverOverlay
				key={key}
				big={false}
				overlay={(<>
					<Popover.Header>Help</Popover.Header>
					<Popover.Body>{desc}</Popover.Body>
					</>)}
				>
				<span>{obj}</span>
			</HoverOverlay>) : obj;
		}


		let obj: ReactNode = null;
		const key = "action-button-" + idx;

		if (but.buttons) {

			const buttons = getActionButtons(but.buttons, self, scope, updateValues, parseAndFormatText, true);

			reactButtons.push(
				wrapHelp(
					<Dropdown key={key}>
						<Dropdown.Toggle 
							className={butUi.type === "borderless" ? "schema-engine-dropdown-no-frame py-0": "ms-1"}
							variant={butUi.type === "borderless" ? "dark-link" : "outline-dark"}>
							{title}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{buttons}
						</Dropdown.Menu>
					</Dropdown>,
					key
				)
			);

		} else if (butUi.type as any === "radio") {

			const args: IUiSchemaElemArgs = {
				key,
				fullkey: key,
				elem: {},
				uiElem: butUi,
				readOnly,
				elemReadOnly: readOnly,
				required: false,
				title: null,
				description: null,
				helpLink: null,
				value,
				values: {},
				error: null,
				errors: null,
				enums: butUi.enum,
				enumLabels: butUi.enumLabels?.reduce((p, c) =>  ({ ...p, [c.value]: c.label }), {}),
				update: (update) => butUi.setValue && updateValues(evalExpr(butUi.setValue, self.lib, self.objects, { ...scope, value: update.value }, {}) || {}),
				type: butUi.type,
				objects: self.objects,
				lib: self.lib,
				embedObject: null,
				getSettings: null,
				stringToComponent: text => parseAndFormatText(text, key),
			};

			obj = self.componentHandlers[butUi.type](args);

		} else if (butUi.type === "switch" || butUi.type === "checkbox") {

			obj = wrapHelp(
				<div className="m-2 float-start" key={"action-button-" + idx}>
					<Form.Check
						id={key}
						key={key}
						checked={value}
						type={butUi.type === "switch" ? "switch" : "checkbox"}
						reverse={true}
						label={title}
						disabled={readOnly}
						onChange={(ev) => {
							if (!readOnly) {
								ev.stopPropagation();
								const value = ev.target.checked;
								updateValues(evalExpr(butUi.setValue, self.lib, self.objects, { ...scope, value }, {}) || {})
							}
						}}
					/>
				</div>,
				key
			);

		} else if (butUi.type === "borderless") {

			if (but.ref) {

				// TODO: should be made more general.
				// This is used for the special case of the readonly button. Here we encapsulate button in another div that will be used
				// to highlight the button.
				obj = wrapHelp(
					<div ref={but.ref} key={key} className="p-2 schema-engine-highlight-button">
						<a key={"action-button-" + idx} onClick={onClick} >
							{title}
						</a>
					</div>,
					key
				);

			} else {

				if (inDropdown) {
					reactButtons.push(
						wrapHelp(<Dropdown.Item key={key} as="div" eventKey={idx} onClick={onClick} >{title}</Dropdown.Item>, key)
					);
				} else {
					obj = wrapHelp(
						<a key={key} onClick={onClick} >)
							{title}
						</a>,
						key
					);
				}
			}


		} else if (butUi.type as any === "date-range-picker") {

			obj = wrapHelp(<DateRangePicker
				className="ms-1"
				key={key}
				title={title}
				onChange={(value) => {
					if (butUi.setValue) {
						updateValues(evalExpr(butUi.setValue, self.lib, self.objects, { ...scope, value }, {}) || {})
					}
				}}
				range={value}
			/>, key);

		} else {

			obj = wrapHelp(<Button
				key={key}
				onClick={onClick}
				variant={butUi.type === "primary" ? "dark ms-1" : "outline-dark ms-1"}
				disabled={readOnly}
			>
				{title}
			</Button>, key);

		}

		if (obj) {
			if (inDropdown) {
				reactButtons.push(<Dropdown.Item key={key} as="div" eventKey={idx}>{obj}</Dropdown.Item>)
			} else {
				reactButtons.push(obj);
			}
		}

	}

	return reactButtons as ReactNode;
}




function fa(fa: string, style?: any) {
	return style ? <i className={"fa-regular " + fa + " fa-fw"} style={style}/> 
				 : <i className={"fa-regular " + fa + " fa-fw"} />;
};

export default class SchemaPanel extends SchemaController {

	loadedPanels: { [key: string]: boolean; } = {};
	loadedCards: { [key: string]: boolean; } = {};
	formInnerState: {
		[key: string]: any;
	} = {};

	// parameters for handling the highlighting of the unlock button
	unlockDivRef = React.createRef<HTMLDivElement>();
	overlayRef = React.createRef<HTMLDivElement>();
	highlightValue = 0;
	highlightTarget = 0;
	highlightTimer: NodeJS.Timeout | undefined;

	layoutRef = React.createRef<ISchemaLayoutComponentRef>();
	updateLayoutTimer: NodeJS.Timeout | undefined = undefined;



    constructor(props: ISchemaModalProps) {
		super(props);

		this.state = {
			...this.state,
        };

		Object.assign(this.componentHandlers, {
			// register our type handlers.
			boolean: this.addBoolean,
			number: this.addInput,
			integer: this.addInput,
			string: this.addInput,
			"old-password": this.addInput,
			"new-password": this.addInput,
			hex: this.addInput,
			radio: this.addRadioButton,
			checkbox: this.addCheckbox,
			select: this.addDropdown,
			"creatable-select": this.addDropdown,
			html: this.addShowHtml,
			text: this.addShowText,
			date: this.addDate,
			image: this.addShowImage,
			separator: this.addSeparator,
			"object-json": this.addInput,
			button: this.addButton,
			"button-small": this.addButton,
			table: this.addTable,
			iframe: this.addIFrame,

			"dashboard-status": this.addDashboardStatus,

			...registeredExtensionHandlers,
			...this.props.extensions,
		});

		// Add the component handler for the form components.
		for (const type of Object.keys(registeredExtensionFormsComponents)) {
			this.componentHandlers[type] = this.addInput;
		}

	}



	public highlightUnlock(target = 1) {

		if (target === this.highlightTarget) { return; }
		this.highlightTarget = target;
		
		const update = () => {

			clearTimeout(this.highlightTimer);
			this.highlightTimer = undefined;

			if (this.highlightTarget !== this.highlightValue && this.overlayRef?.current && this.unlockDivRef?.current) {

				this.highlightValue += (this.highlightValue > this.highlightTarget ? -1 : 1) * 0.2;
				this.highlightValue  = Math.max(0, Math.min(1, this.highlightValue));

				this.overlayRef.current.style.display      = this.highlightValue > 0 ? "block" : "none";
				this.overlayRef.current.style.background   = `rgba(0,0,0,${this.highlightValue / 2})`;
				this.unlockDivRef.current.style.zIndex     = this.highlightValue > 0 ? "99999" : "1";
				this.unlockDivRef.current.style.background = `rgba(255,255,255,${this.highlightValue})`;

				if (this.highlightValue !== this.highlightTarget) {
					this.highlightTimer = setTimeout(update, 20);
				} else {
					// After finishing the animation, if the unlock is not highlighted, we schedule the
					// return to normal immediately
					if (this.highlightTarget) {
						this.highlightTimer = setTimeout(() => this.highlightUnlock(0), 1000);
					}
				}
			}
		}

		update();
	}



	public updateLayout = (delay?: number) => {
		delay = delay || 50;
		clearTimeout(this.updateLayoutTimer);
		this.updateLayoutTimer = setTimeout(() => {
			if (this.layoutRef.current) {
				(this.layoutRef.current as any).reLayout();
			}
		}, delay || 50);
	}





	private addCheckbox = (args: IUiSchemaElemArgs) => {

		const { objects, value, readOnly, uiElem, elem, fullkey} = args;
		const lib = this.self.lib;

		const multi = uiElem?.multiSelect;

		// Render ENUM as a RADIO button set
		const vals: JSX.Element[] = [];
		if (args.enums) {
			for (const en of args.enums) {

				let label = args.enumLabels?.[en + ""] != null ? args.enumLabels[en + ""] : (en + "");
				if (uiElem?.enumFormatString) {
					const formatStr = evalString(uiElem.enumFormatString,
						lib, objects, { fullkey, value: { value: en, label }, readOnly, schema: elem }) + "";
					label = formatStr ? this.formatTextArgs(formatStr, {...args, value: { value: en, label }}) : null;
				} else {
					label = args.stringToComponent(label);
				}

				const type = multi ? "checkbox" : "radio";
				vals.push(
					<Form.Check type={type} key={fullkey + en}>
						<Form.Check.Input
							type={type}
							checked={multi ? value?.includes(en) || false : value === en}
							disabled={readOnly}
							onChange={() => {
								const res = multi ? [] : en;
								if (multi) {
									for (const e of args.enums) {
										if ((e === en && !value.includes(e)) ||
											(e !== en && value.includes(e))) { res.push(e); }
									}
								}
								!readOnly && args.update({ value: res });
							}}
						/>
						<Form.Check.Label>{label}</Form.Check.Label>
					</Form.Check>
				);
			}
		} else {
			vals.push(
				<Form.Check type="checkbox" key={fullkey} >
					<Form.Check.Input
						type="checkbox"
						checked={!!value}
						disabled={readOnly}
						onChange={() => { !readOnly && args.update({ value: !value }); }}
					/>
				</Form.Check>
			);
		}

		return this.embedObject(args,
			<div id={fullkey} key={fullkey} onClick={() => console.log("clicking")}>
					{vals}
			</div>, { useFlex: true }
		);
	};



	private addRadioButton = (args: IUiSchemaElemArgs) => {

		const { objects, error, value, readOnly, key, uiElem, elem, fullkey } = args;
		const lib = this.self.lib;
		const multi = uiElem?.multiSelect;

		// Render ENUM as a RADIO button set
		const vals: JSX.Element[] = [];
		for (const en of args.enums) {

			let label = args.enumLabels?.[en + ""] != null ? args.enumLabels[en + ""] : (en + "");
			if (uiElem?.enumFormatString) {
				const formatStr = evalString(uiElem.enumFormatString,
					lib, objects, { fullkey, value: { value: en, label }, readOnly, schema: elem }) + "";
				label = formatStr ? this.formatTextArgs(formatStr, {...args, value: { value: en, label }}) : null;
			} else {
				label = args.stringToComponent(label);
			}

			vals.push(
				<ToggleButton key={en} id={"ID" + en}
					type="radio"
					disabled={readOnly}
					value={en}
					checked={multi ? value?.includes(en) || false : value === en}
					variant={error ? "outline-danger" : "outline-secondary"}
					onClick={() => {
						const res = multi ? [] : en;
						if (multi) {
							for (const e of args.enums) {
								if ((e === en && !value.includes(e)) ||
								    (e !== en && value.includes(e))) { res.push(e); }
							}
						}
						!readOnly && args.update({ value: res });
					}}
				>
					{label}
				</ToggleButton>
			);
		}

		return this.embedObject(args,
			<ButtonToolbar key={args.fullkey}>
				<ButtonGroup id={key} >
					{vals}
				</ButtonGroup>
			</ButtonToolbar>, { useFlex: true }
		);
	};


	private addSeparator = (args: IUiSchemaElemArgs) => {

		const { title, fullkey } = args;
		return <div key={fullkey}>
			{title && <h2 className="schema-engine-separator-title">{this.formatTextArgs(title, args)}</h2>}
			<hr className="schema-engine-separator-hr" />
		</div>;
	};



	private addBoolean = (args: IUiSchemaElemArgs) => {

		const { value, readOnly, key } = args;

		return this.embedObject(args,
			<Form.Switch
				key={args.fullkey}
				id={"switch_" + key}
				checked={value || false}
				disabled={readOnly}
				onChange={(e) =>  {!readOnly && args.update({ value: !value }); }}
			/>, { useFlex: true }
		);
	};


	private addButton = (args: IUiSchemaElemArgs) => {

		const { value, readOnly, key, fullkey, values, uiElem } = args;
		const size = uiElem.type === "button-small" ? "sm" : "";

		return this.embedObject(args, (
			<Button variant="outline-dark" key={fullkey} disabled={readOnly} size={size as any}
				id={"switch_" + key}
				onClick={() =>  {!readOnly && args.update({ value: !values[key] }); }}
			>
				{this.formatTextArgs(value, args)}
			</Button>),
			{ useFlex: true}
		);
	};

	private addIFrame = (args: IUiSchemaElemArgs) => {

		const { value, fullkey, uiElem } = args;
		const src = value.src;
		const width = value.width != null ? value.width : "100%";
		const height = value.height != null ? value.height :
				uiElem.multiLine != null ? uiElem.multiLine * 12 + "px" : undefined;

		return this.embedObject(args,
			(<iframe title={fullkey} key={"iframe_" + fullkey} src={src} width={width} height={height}/>),
			{ useFlex: true, isContainer: true });
	};


	private addTable = (args: IUiSchemaElemArgs) => {

		const { value, fullkey } = args;

		const header: string[] = value?.header || [];
		const body: string[][] = value?.body || [];

		const tableJsx = (
			<Table key={fullkey} className="schema-engine-table">
				<thead className="schema-engine-table-header">
					<tr>{header.map((a, i) => <th key={"idx" + i}>{a}</th>)}</tr>
				</thead>
				<tbody>
					{body.map((row, i) => (<tr key={"row" + i}>{row.map((col, i) => <td key={"col" + i}>{typeof col === "number" ? String(col) : col}</td>)}</tr>))}
				</tbody>
			</Table>
		)

		return this.embedObject(args, tableJsx, { useFlex: true});
	};







	private addDropdown = (args: IUiSchemaElemArgs) => {

		const { objects, value, readOnly, uiElem, error, elem, fullkey, type } = args;
		const lib = this.self.lib;

		const multi = uiElem && uiElem.multiSelect ? true : false;
		const clearable = uiElem && uiElem.clearable ? true : false;


		// Use DROPDOWN
		const selectOptions: any[] = [];
		let entryValue: any = multi ? [] : null;
		for (const en of args.enums || []) {
			const entry = { value: en, label: args.enumLabels[en + ""] != null ? args.enumLabels[en + ""] : en + "" };
			selectOptions.push(entry);

			if (multi && Array.isArray(value) && value.includes(en)) { entryValue.push(entry); }
			if (!multi && en === value) { entryValue = entry; }
		}

		const selectStyles = { menu: (styles: any) => ({ ...styles, zIndex: 999 }) };

		const formatOptionLabel: ((data: any) => ReactNode) | undefined = uiElem?.enumFormatString ? (data: any) => {
			const formatstr = evalString(uiElem?.enumFormatString,
											lib, objects, { fullkey, value: data, readOnly, schema: elem }) + "";
			return formatstr ? this.formatTextArgs(formatstr, {...args, value: data}) : null;
		} : ((data: any) => this.formatTextArgs(data.label, {...args, value: data}))


		return this.embedObject(args,
			type === "select" ?
			<Select
				key={fullkey}
				className={error ? "has-error" : ""}
				isDisabled={readOnly}
				options={selectOptions}
				isClearable={clearable}
				placeholder={uiElem.placeholder}
				closeMenuOnSelect={!multi || !uiElem?.componentOptions?.selectKeepOpenOnMultiSelect}
				styles={selectStyles}
				isMulti={multi}
				formatOptionLabel={formatOptionLabel}
				backspaceRemovesValue={true}
				onChange={(event) => args.update({ value: multi ? event && event.map((a: any) => a.value) : event && event.value })}
				value={entryValue}
			/> :
			<CreatableSelect
				key={fullkey}
				className={error ? "has-error" : ""}
				isDisabled={readOnly}
				options={selectOptions}
				isClearable={clearable}
				closeMenuOnSelect={!multi}
				placeholder={uiElem.placeholder}
				isMulti={multi}
				styles={selectStyles}
				formatOptionLabel={formatOptionLabel}
				backspaceRemovesValue={true}
				onChange={(event) => args.update({ value: multi ? event && event.map((a: any) => a.value) : event && event.value })}
				value={entryValue}
			/>
		);
	};


	private addShowImage = (args: IUiSchemaElemArgs) => {

		const { value, fullkey, uiElem } = args;

		if (!value) { return null; }

		const src    = typeof value === "string" ? value : value.src;
		const width  = typeof value === "object" && value.width != null ? value.width : undefined;
		const height = typeof value === "object" && value.height != null ? value.height :
						uiElem.multiLine != null ? uiElem.multiLine * 12 + "px" : undefined;

		return this.embedObject(args,
			<div><img className="img-thumbnail ow-device-profile-image" key={fullkey} src={src} width={width} height={height} /></div>
		);
	};




	private addShowHtml = (args: IUiSchemaElemArgs) => {

		const { value, uiElem, fullkey } = args;
		if (uiElem && uiElem.colBehaviour === "fullwidth") {
			return <div className="mb-2" key={fullkey} dangerouslySetInnerHTML={{__html: value}} />
		} else {
			return this.embedObject(args, <div key={fullkey} dangerouslySetInnerHTML={{__html: value}} />, { isContainer: true });
		}
	};



	private addDashboardStatus = (args: IUiSchemaElemArgs) => {

		const variant = "schema-engine-dashboard-default-variant";
		return (
			<div key={args.fullkey} className={"schema-engine-dashboard-status-elem " + variant}>
                <div>
                <div className="schema-engine-dashboard-status-elem-value">{args.value}</div>
                <div className="schema-engine-dashboard-status-elem-title">{args.title}</div>
                </div>
                <div className="schema-engine-dashboard-status-elem-description mb-2 align-self-end">{args.description}</div>
            </div>
		);
	};



	private addShowText = (args: IUiSchemaElemArgs) => {

		const { value } = args;
		return this.embedObject(args,
			<div key={args.fullkey} className="schema-engine-textview" >{this.formatTextArgs(value, args)}</div>, { isContainer: true }
		);
	};



	private addDate = (args: IUiSchemaElemArgs) => {

		const { value, readOnly, fullkey } = args;
		const date = (value && new Date(value)) || null;

		return args.embedObject(
			<LazyLocaleDatePicker
				key={fullkey}
				disabled={readOnly}
				showTimeSelect
				selected={date}
				timeFormat="p"
				dateFormat="Pp"
				timeIntervals={15}
				onChange={(a: any) => args.update({ value: new Date(a).toJSON() })}
			/>,
			{ useFlex: true }
		);
	};


	private addInput = (args: IUiSchemaElemArgs) => {

		const debugKey = "";
		const { objects, value, elem, uiElem, fullkey, readOnly, error } = args;
		const lib = this.self.lib;
		let   type = uiElem.type || (typeof elem.type === "string" ? elem.type  : "");
		const hidden = !!(args?.uiElem?.security === "secret" || args?.uiElem?.security === "sensitive");


		// The form handling is pretty complex due to performance issues.
		//
		// The issue is that the "normal" react model using a onChange function paired with the value input to the component
		// doesn't work well for form input as this will cause a complete rerender at schema level for each keypress.
		// To avoid that, the SchemaForm component maintain its own value state, but callback to here for each input. The
		// input can be filtered and error checked, etc. and the value is passed back to the component. The global state of
		// the schema however is not updated on each keypress. Instead a 500ms timer is maintained to aggregate fast key types
		// and update the state only after finishing typing.
		//
		// The following out-of-state variables are maintained:
		// this.formInnerState[fullkey + "$text"]   current content of input form
		// this.formInnerState[fullkey + "$value"]  current value (i.e. integer etc) of content of input, ready to be set to state
		// this.formInnerState[fullkey + "$lastSetValue"]   last value that was set to state, meaning what should be the current state
		// this.formInnerState[fullkey + "$context"]        counter updated on every update. Allow the SchemaForm to detect update
		// this.formInnerState[fullkey + "$time"]           time used to update every 500ms.
		//
		this.formInnerState[fullkey + "$context"] = (this.formInnerState[fullkey + "$context"] || 0) + 1;

		const debugInnerState = (where: string) => {
			if (debugKey && fullkey === debugKey) {
				console.log(where, fullkey, value, this.formInnerState[fullkey + "$text"],
							this.formInnerState[fullkey + "$value"], this.formInnerState[fullkey + "$lastSetValue"],
							this.formInnerState[fullkey + "$context"]);
			}
		}
		const safeIsEq = (a: any, b: any) => {
			if (typeof a !== typeof b) { return false; }
			if (typeof a === "number" && isNaN(a) && isNaN(b)) { return true; }	// this is the weird case
			return a === b;
		}
		const safeParseNumber = (str: string, radix?: number) => {
			let res = radix ? parseInt(str, radix) : parseFloat(str);
			return isNaN(res) ? 0 : res;
		}
		const safeToString = (value: any, radix?: number) => {
			if (typeof value === "number") { return isNaN(value) ? "" : (radix ? value.toString(radix) : value.toString()) }
			return (value || "").toString();
		}


		debugInnerState("entry");
		if (this.formInnerState[fullkey + "$text"] != null && (
				this.formInnerState[fullkey + "$lastSetValue"] == null ||
				!safeIsEq(this.formInnerState[fullkey + "$lastSetValue"], value))) {
			clearTimeout(this.formInnerState[fullkey + "$timer"]);
			this.formInnerState[fullkey + "$timer"] = null;
			this.formInnerState[fullkey + "$text"] = null;
			this.formInnerState[fullkey + "$lastSetValue"] = null;

			debugInnerState("clear");
		}

		let cnvVal: string = value == null ? "" 
							: type === "hex" ? safeToString(value, 16)
								: type.endsWith("object-json") ? JSON.stringify(value, null, 4)
									: safeToString(value);
		let strVal: string = this.formInnerState[fullkey + "$text"] != null
							? this.formInnerState[fullkey + "$text"]
							: cnvVal;

		const nullValue = () => {
			return uiElem.treatEmptyAs ? evalExpr(uiElem.treatEmptyAs, lib, objects, { fullkey, value, error, readOnly, schema: elem }, null) : null;
		}

		const onTextChange = (text: string) => {

			if (readOnly) { return null; }

			const type = uiElem.type || (typeof elem.type === "string" ? elem.type  : "");
			const stringVal: string = text != null ? text + "" : "";
			let   targetVal: string | number | null;

			if (uiElem.inputFilterPattern && !stringVal.match(new RegExp(uiElem.inputFilterPattern))) {
				return null;
			}
			if (typeof elem.maxLength === "number" && stringVal.length > elem.maxLength) { return null; }

			if (type === "integer" && stringVal !== "" && !stringVal.match(/^[-+]?[0-9]*$/)) { return null; }
			if (type === "number"  && stringVal !== "" && !stringVal.match(/^[-+]?[0-9]*[.]?[0-9]*[eE]?[-+]?[0-9]*$/)) { return null; }
			if (type === "hex"     && stringVal !== "" && !stringVal.match(/^[0-9a-fA-F]*$/)) { return null; }

			const fKey = fullkey.replace(/[.]/g, "/");
			this.innerStates[fKey] = {...this.innerStates[fKey], modified: true };
			
			delete (this.innerStates[fKey] as any)?.error;	// FIXME: type hack

			if (type === "hex") {
				targetVal = stringVal === "" ? nullValue() : safeParseNumber(stringVal, 16);
			} else if (type === "integer" || type === "number") {
				targetVal = stringVal === "" ? nullValue() : safeParseNumber(stringVal);
			} else if (type.endsWith("object-json")) {
				try {
					targetVal = JSON.parse(stringVal);
				} catch (e) {
					targetVal = {} as any;
					this.innerStates[fKey] = {...this.innerStates[fKey], error: "Invalid JSON" };
				}
			} else {
				targetVal = (stringVal === "" && uiElem.treatEmptyAs) ? nullValue() : stringVal;
			}

			// when the user is typing 
			if (typeof targetVal === "number" && isNaN(targetVal)) { targetVal = 0;}


			// Doing delayed update
			this.formInnerState[fullkey + "$text"]  = text;
			this.formInnerState[fullkey + "$value"] = targetVal;


			debugInnerState("update");
			clearTimeout(this.formInnerState[fullkey + "$timer"]);
			this.formInnerState[fullkey + "$timer"] = setTimeout(() => {
				this.formInnerState[fullkey + "$timer"] = null;
				this.formInnerState[fullkey + "$lastSetValue"] = this.formInnerState[fullkey + "$value"];
				debugInnerState("flush");
				args.update({ value: this.formInnerState[fullkey + "$value"] });
			}, 500)

			return text;
		};
		const onBlur = () => {
			if (this.formInnerState[fullkey + "$timer"]) {
				clearTimeout(this.formInnerState[fullkey + "$timer"]);
				this.formInnerState[fullkey + "$timer"] = null;
				this.formInnerState[fullkey + "$text"] = null;			// FIXME: why clear this??

				this.formInnerState[fullkey + "$lastSetValue"] = this.formInnerState[fullkey + "$value"];
				debugInnerState("blur");

				args.update({ value: this.formInnerState[fullkey + "$value"] });
			}
		}

		const props: ISchemaFormProps = {
			args,
			onBlur,
			value: strVal,
			onTextChange,
			hidden,
			context: this.formInnerState[fullkey + "$context"]
		};

		// Default is the SchemaForm component, but if there is another FormObject registered on the type, wy use that
		const Form = registeredExtensionFormsComponents[type] || SchemaForm;

		return this.embedObject(args, <Form key={"input-" + fullkey} {...props} />);
	};


	public numberOrExpr(value: string | number | null, defaultValue: number, args: IUiSchemaElemArgs) {
		if (typeof value === "number") {
			return value;
		} else if (typeof value === "string") {
			if (args) {
				const { objects, readOnly } = args;
				return evalExpr(value, this.self.lib, objects, { fullkey: args.fullkey, readOnly }, defaultValue)
			} else {
				const readOnly = !!this.self.objects.control.readOnly;
				return evalExpr(value, this.self.lib, this.self.objects, { readOnly }, defaultValue)
			}
		} else {
			return defaultValue;
		}
	}


	// When Dropfile is used, this function is called whenever the dropfile action is used.
	public onDropFile = (files: File[], dropFile: IUiSchemaDropFile, fullkey: string, args: IUiSchemaElemArgs) => {

		const self = this.self;
		const maxNumFiles = this.numberOrExpr(dropFile.maxNumFiles, 1, args);
		if (!files || files.length < 1) { return; }
		if (files.length > maxNumFiles) {
			// Toast error
			return;
		}

		const loadedFiles: Array<{ name: string; content: string; }> = [];
		const readers: FileReader[] = [];

		for (const file of files) {

			const name = file.name;
			const maxSize = this.numberOrExpr(dropFile.maxFileSize, 300000, args);
			if (file.size > maxSize) {
				// toast
				this.props.showMessage("error", "File is too big (max size is " + maxSize + " bytes)")
				return;
			}
			// Now let's read the file

			const reader = new FileReader();
			readers.push(reader);

			reader.onabort = () => console.log('file reading was aborted')
			reader.onerror = () => console.log('file reading has failed')
			reader.onload = () => {

				loadedFiles.push({ name, content: reader.result as string });
				self.log("Finished reading file", name);

				if (loadedFiles.length === readers.length) {

					try {
						const lib = this.self.lib;
						const { key, keypath } = getPathAndKey(fullkey);
						const objects  = args?.objects  ?? this.self.objects;
						const readOnly = args?.readOnly ?? objects.control.readOnly;

						const targetObj = evalExpr(dropFile.onDropFile || "", lib, objects, { fullkey, value: loadedFiles, readOnly }, Error);
						this.updateValues(targetObj, keypath, key + "");
					} catch (e) {
						self.log(e);
					}
				}
			}
			reader.readAsDataURL(file);
		}
	}





	public embedObject(args: IUiSchemaElemArgs, obj: JSX.Element, options?: IEmbedObjectOptions) {

		const { elemReadOnly, readOnly, fullkey, required, uiElem, elem, title, description, helpLink, error } = args;

		if (uiElem?.embedding === "bypass") { return obj; }

		let   titleLayout = uiElem?.titleLayout       || args.layoutOptions?.titleLayout;
		const descLayout  = uiElem?.descriptionLayout || args.layoutOptions?.descriptionLayout;
		let marginClass = options?.noMargin !== true ? "mb-3" : "";

		if (titleLayout === "none" && descLayout?.startsWith("popup")) { marginClass = ""; }

		// @Swara comment this line out.
		if (titleLayout === "in-frame") { titleLayout = "top" as any; }


		const titleElem = title ? this.formatTextArgs(title, args) : null;
		const descriptionElem  = description  ? this.formatTextArgs(description, args) : null;
		const moreInfoText = "More Info...";
		const descElem = !helpLink ? descriptionElem :
				<>
					{descriptionElem}
					{helpLink ? 
						this.props.helpLinkCallback 
						    ? (<><br /><a href={helpLink} onClick={(e) => { e.preventDefault(); this.props.helpLinkCallback(helpLink) }} >{moreInfoText}</a></>)
							: (<><br /><a href={helpLink} target="_blank" rel="noopener noreferrer">{moreInfoText}</a></>) : null}
				</>;
		const helpTip = descLayout?.startsWith("popup") && descElem ? 
			(<>
				<Popover.Header>Help</Popover.Header>
				<Popover.Body>
					{descElem}
				</Popover.Body>
			 </>) : null;

		const errFeedbackObj = error && error.err && (
			typeof error.err === "string" && error.err.trim()
				? <small className="schema-engine-error-text">{fa("fa-circle-exclamation")} {this.formatTextArgs(error.err.trim(), args)}</small>
				: <Form.Control.Feedback type="invalid" />
		);

		const inputInfo = this.props.debug && <div><Form.Text>{fullkey + ": " + verificationDescription(elem)}</Form.Text></div>;

		// If the onDropFile handler is provided, we encapsulate the object in a DropFile 
		const dropZoneEvt = (innerObj: ReactNode) => {
			if (args.dropFile && !args.readOnly && obj) {
				return (
					<Dropzone noClick={args.dropFile.openFileDialogOnClick !== true} 
						onDrop={files => this.onDropFile(files, args.dropFile, args.fullkey, args)}
					>
						{({getRootProps, getInputProps}) => (
							<section>
								<div {...getRootProps()}>
									<input {...getInputProps()} />
									{innerObj}
								</div>
							</section>
						)}
					</Dropzone>
				)
			}
			return innerObj;
		}


		// If this object is a readonly and the whole schema is in readonly mode, we put an outer div with a
		// onClick() function that intercept mouse clicks and popup the highlight of the unlock button.

		const readOnlyEvt = (innerObj: JSX.Element | JSX.Element[]) => {
			if (readOnly && !elemReadOnly && this.self.objects.control.readOnly && !options?.isContainer) {
				return <div key={fullkey} onClick={() => this.highlightUnlock()}>{innerObj}</div>;
			}
			return innerObj;
		}


		// Construct the title object which may or may not have the description in an popover.
		const titleObj = (<>
			{titleElem}
			{required && <span style={{color:"red"}}>*</span>}
			{helpTip && <HoverOverlay
				big={descLayout==="popup-big"}
				overlay={helpTip}
			>
				<span> {fa("fa-info-circle")}</span>
			</HoverOverlay>}
		</>);


		// null; //error && error.err && <Form.Control.Feedback type="invalid">{error.err}</Form.Control.Feedback>;

		let compositElem: JSX.Element;

		if (options?.noWrapper) {

			// The noWrapper option allow us to use the embed to render basically (but including description, etc.)
			// as basic objects inside another object, e.g. like for a navbar.

			compositElem = (<React.Fragment key={fullkey}>
				{titleElem && <>{titleObj}</>}
				{descElem && descLayout === "top" && <>{descElem}</>}

				{dropZoneEvt(readOnlyEvt(obj))}
				{inputInfo}
				{errFeedbackObj}

				{descElem && descLayout === "bottom" && <>{descElem}</>}
			</React.Fragment>);

		} else if (args.type === "object" && uiElem.titleLayout == null) {		// FIXME: how to specify this

			compositElem = dropZoneEvt(
				// stack form layout put title above component on separate line

				<Accordion key={fullkey} className={"schema-engine-object-container mb-2"} defaultActiveKey={"0"} >
					<Accordion.Item	eventKey={"0"} className="schema-engine-object-container-item">
						<Accordion.Header>
							<div className="d-flex justify-content-between" style={{ width: "inherit" }}>
								<div>{titleObj}</div>
							</div>
						</Accordion.Header>
						<Accordion.Body>
							{obj}
						</Accordion.Body>
					</Accordion.Item>

					{inputInfo}
					{descElem && descLayout === "bottom" && <Form.Text>
						<small className="new_style_help_block_color" >{descElem}</small>
					</Form.Text>}
				</Accordion>

			) as JSX.Element;

		} else if (titleLayout === "left") {

			const titleWidth = Math.round((uiElem.titleWidthPercent != null 
										? uiElem.titleWidthPercent
										: args.layoutOptions?.titleWidthPercent || 40) / 100 * 12);
			const bodyWidth = 12 - titleWidth;

			// Inline layout put title and component on same line
			compositElem = (
				<Form.Group key={fullkey} controlId={"label" + fullkey} as={Row}  className={"mb-2"}>
					<Form.Label column sm={titleWidth} className="text-right col-12"  >
						{titleObj}
					</Form.Label>

					<Col sm={bodyWidth} className={options?.useFlex ? "d-flex justify-content-end col-12" : "col-sm-" + bodyWidth}>
						{descElem && descLayout === "top" && <Form.Text>
							<small className="new_style_help_block_color" >{descElem}</small>
						</Form.Text>}

						{dropZoneEvt(readOnlyEvt(obj))}
						{inputInfo}
						{errFeedbackObj}

						{descElem && descLayout === "bottom" && <Form.Text>
							<small className="new_style_help_block_color" >{descElem}</small>
						</Form.Text>}
					</Col>

				</Form.Group>
			);

		} else if (titleLayout === "in-frame") {

			compositElem = dropZoneEvt(readOnlyEvt(
				// stack form layout put title above component on separate line
				<Form.Group key={fullkey} controlId={"label" + fullkey} className={"form-floating " + marginClass} /* validationState={error ? "error" : null}*/ >

					{descElem && descLayout === "top" && <Form.Text>
							<small className="new_style_help_block_color" >{descElem}</small>
					</Form.Text>}
					{titleElem && <Form.Label className="text-right">{titleObj}</Form.Label>}
					{obj}
					{inputInfo}
					{errFeedbackObj}
					{descElem && descLayout === "bottom" && <Form.Text>
						<small className="new_style_help_block_color" >{descElem}</small>
					</Form.Text>}

				</Form.Group>
			)) as JSX.Element;

		} else {

			compositElem = (
				// stack form layout put title above component on separate line
				<Form.Group key={fullkey} controlId={"label" + fullkey} className={marginClass} /* validationState={error ? "error" : null}*/ >

					{titleElem &&  titleLayout === "top"  && <Form.Label className="text-right">
						{titleObj}
					</Form.Label>}

					{descElem && descLayout === "top" && <Form.Text>
							<small className="new_style_help_block_color" >{descElem}</small>
					</Form.Text>}

					{dropZoneEvt(readOnlyEvt(obj))}
					{inputInfo}
					{errFeedbackObj}

					{descElem && descLayout === "bottom" && <Form.Text>
						<small className="new_style_help_block_color" >{descElem}</small>
					</Form.Text>}

				</Form.Group>
			);
		}


		// 



		return compositElem;

	}



	// -- TABS -----------------------------------------------------------------------------------------







	public addTabElem(tabKey: string, isActive: boolean, hasError: boolean, title: Array<JSX.Element|string>, onClick: () => void) {
		return (
			<Nav.Item key={tabKey}>
				<Nav.Link
					className={(isActive ? "active" : "") + (hasError ? " has-error" : "")}
					eventKey={tabKey}
					onClick={onClick}
				>
					{title}
					{hasError ? " " : null}
					{hasError ? fa("fa-circle-exclamation", {color:"red"}) : null}
				</Nav.Link>
			</Nav.Item>
		);
	}



	public embedArrayElement(key: string, element: JSX.Element, rem: () => void, add: () => void) {
		// Override in extended class
		return (<tr className="schema-engine-array-element-container">
			<td>{element}</td>
			{(add || rem) && (<td>
				{add && <Button variant="outline-secondary" onClick={add} size="sm">
							{fa("fa-add")}
				</Button>}
				{rem && <Button variant="outline-secondary" onClick={rem} size="sm">
							{fa("fa-trash-alt")}
				</Button>}
			</td>)}
		</tr>);
	}

	public embedNonObjectArrayElements(elements: JSX.Element[]) {
		<Table>{elements}</Table>
	}


	public embedArrayElementObject(args: IUiSchemaElemArgs, elements: JSX.Element[], type: "embox" | "table" | "accordion",
								   rem: () => void, add: () => void) {

		const {key, fullkey} = args;
		const addBut = add ? (
			<Button as="div" variant="outline-secondary" className="mr-1" onClick={(ev) => {ev.stopPropagation(); add(); }} size="sm">
				{fa("fa-add")}
			</Button>) : null;
		const remBut = rem ? (
			<Button as="div" variant="outline-secondary" onClick={(ev) => {ev.stopPropagation(); rem(); }} size="sm">
				{fa("fa-trash-alt")}
			</Button>) : null;

		const titleElem = args.title ? this.formatTextArgs(args.title, args) : "#" + args.key;

		if (type === "embox") {

			return (<Card key={fullkey} className="schema-engine-array-element-object">
				<Card.Header>
					{titleElem}
					{addBut}
					{remBut}
				</Card.Header>
				<Card.Body>
					{elements}
				</Card.Body>
			</Card>);

		} else if (type === "accordion") {

			return (
				<Accordion.Item key={fullkey} eventKey={key} className="schema-engine-array-accordion-element-object">
					<Accordion.Header onClick={() => this.updateLayout(200)}>
						<div className="d-flex justify-content-between" style={{width : ' inherit'}}>
							<div>
								{titleElem}
							</div>
							<div className="mx-2">
								{addBut}
								{remBut}
							</div>
						</div>
					</Accordion.Header>
					<Accordion.Body>
						{elements}
					</Accordion.Body>
				</Accordion.Item>
			);

		} else if (type === "table") {

			const cols = elements.map((elem, idx) => <td key={"col" + idx}>{elem}</td>);
			if (addBut || remBut) { cols.push(<td key="addrow">{addBut}{remBut}</td>); }
			return (<tr key={fullkey}>{cols}</tr>);

		} else {

			return <div>Error, unexpected type</div>

		}
	}



	public embedArrayContainer(args: IUiSchemaElemArgs, schema: IJsonSchemaObject,
								elements: JSX.Element[], add: (() => void) | null, boxType: "embox" | "table" | "accordion" | "card") {

		const { title, fullkey } = args;
		const elems = schema.type === "object" 
			? elements
			: <div className="px-2"><Table bordered={false} className="schema-engine-array-container-table"><tbody>{elements}</tbody></Table></div>

		if (boxType === "embox") {

			return (
				<Card key={fullkey} className="schema-engine-array-container">
					{title && <Card.Title>
						{title}
					</Card.Title>}
					<Card.Body>
						{elems}
						{add && <Button variant="outline-secondary" onClick={add} size="sm">
							{fa("fa-add")}
						</Button>}
					</Card.Body>
				</Card>
			);

		} else if (boxType === "accordion") {

			let body: JSX.Element;

			if (elements && elements.length > 0) {
				const acckey = "/__acc_" + fullkey.replace(/[/]/g, ".");
					body = <Accordion
					className={"schema-engine-array-accordian-container " + (add ? "mb-2" : "mb-3")}
					activeKey={this.instantValues[acckey]}
					onSelect={k => this.updateValues({ values: { [acckey]: k }}, "", "" )}
				>
					{elems}
				</Accordion>
	
			} else {

				const placeholder = args.uiElem?.placeholder || "[[fa-brackets]] Empty";
				body = <Alert variant="light">{this.parseAndFormatText(placeholder, fullkey)}</Alert>;
			}

			return (
				<div key={fullkey}>
					{body}

					{add && <Button className="mb-3" variant="outline-secondary" onClick={add} size="sm">
						{fa("fa-add")}
					</Button>}
				</div>
			);

		} else if (boxType === "table") {

			const header: JSX.Element[] = [];

			const { lang } = this.props;
			const lib = this.self.lib;
			const { errors, values, oldValues, oldRootJsonSchema, rootCondJsonSchema, rootJsonSchema, jsonSchema, control } = this.self.objects;
			const readOnly = !!(control.readOnly || rootJsonSchema?.$uiSchema?.modal?.readOnly);
			const objects = { values, errors, oldRootJsonSchema, rootJsonSchema, rootCondJsonSchema, jsonSchema, oldValues, control, uiSchema: rootJsonSchema.$uiSchema || {} };
	
			for (const eKey of Object.keys(schema?.properties || {})) {

				if (!schema.properties || !schema.properties[eKey]) { continue; }

				const efullkey = eKey;	// FIXME: add path

				const elem = schema.properties[eKey];
				// const uiElem = (elem || {}).$uiSchemaObject || {};

				const descLayout = "popup";
				const description = evalString((lang && (elem as any)["description[" + lang + "]"]) || elem.description || "",
												lib, objects, { fullkey: efullkey, readOnly, schema: elem }) + "";
				const title       = evalString((lang && (elem as any)["title[" + lang + "]"]) || elem.title || eKey,
												lib, objects, { fullkey: efullkey, readOnly, schema: elem }) + "";

				const titleElem = title ? this.formatTextArgs(title, null) : null;
				const descElem  = description  ? this.formatTextArgs(description, null)  : null;
				const helpTip   = descLayout === "popup" && descElem ? <Tooltip>{descElem}</Tooltip> : null;
				const required = schema.required?.includes(eKey);

				header.push(<th key={eKey}>
					<Form.Label>
						{titleElem}
						{required && <span style={{color:"red"}}>*</span>}
						{helpTip && <OverlayTrigger
							placement="auto"
							delay={{ show: 250, hide: 400 }}
							trigger={["hover", "focus"]}
							overlay={helpTip}
						>
							<span> {fa("fa-info-circle")}</span>
  						</OverlayTrigger>}
					</Form.Label>
				</th>);
			}


			return (
				<div>
					<Table key={fullkey} bordered={ false } className="schema-engine-array-container-table">
						<thead><tr>{header}</tr></thead>
						<tbody>{elements}</tbody>
					</Table>

					{add && <Button variant="outline-secondary" onClick={add} size="sm">
							{fa("fa-add")}
					</Button>}
				</div>
			);

		} else {

			return <div/>

		}
	}


	


	public getControlButtons(readOnly: boolean, allowDebug: boolean, debug: boolean) {


		const rootJsonSchema = this.self.objects.rootJsonSchema

		if (!rootJsonSchema) { return []; }

		const uiSchema: IUiSchema = rootJsonSchema?.$uiSchema || {};
		const hasErrors = Object.keys(this.self.objects.errors || {}).length > 0;

		const buttons: IUiActionButton[] = [...(uiSchema.modal?.actionButtons ? uiSchema.modal.actionButtons : [])];
		const controlButtons = [];
		const control = this.self.objects.control;

		if (allowDebug) {
			const debugButton: IUiActionButton = {
				uiSchema: {
					type: "switch",
					getValue: debug ? "true" : "false",
					setValue: "({ debug: " + !debug + " })",
					readOnly: false,
				},
				title: "Debug",
			}
			buttons.unshift(debugButton);
		}

		if (!readOnly && !control.modalReadOnly && control.readOnly) {
			const unlockButton: IUiActionButtonExt = {
				uiSchema: {
					setValue: "({ readOnly: false })",
					type: "borderless",
					readOnly: false,
				},
				title: "[[fa-lock]] " + this.props.localeDictionary.click_to_unlock + " ",
				ref: this.unlockDivRef,
			}
			buttons.unshift(unlockButton);


			controlButtons.push(<div ref={this.overlayRef} key="readonlyoverlay" className="schema-engine-overlay"
										onClick={() => this.highlightUnlock(0)} />);
		}

		if (uiSchema.modal?.closeButton !== "") {
			const cancelButton: IUiActionButton = { 
				title: this.props.localeDictionary.cancel,
				uiSchema: {
					setValue: "({ close: true })",
					type: "secondary",
					readOnly: false,
				}
			}
			buttons.push(typeof uiSchema.modal?.closeButton === "string"
				? { ...cancelButton, title: uiSchema.modal?.closeButton }
				: { ...cancelButton, ...uiSchema.modal?.closeButton, uiSchema: {...cancelButton.uiSchema, ...uiSchema.modal?.closeButton?.uiSchema} })
		}
		if (uiSchema.modal?.applyButton != null && !readOnly && !control.modalReadOnly) {
			const applyButton: IUiActionButton = { 
				uiSchema: {
					setValue: "({ apply: true })",
					readOnly: hasErrors || control.readOnly,
					type: "primary",
				},
				title: "", 
			}
			buttons.push(typeof uiSchema.modal?.applyButton === "string"
				? { ...applyButton, title: uiSchema.modal?.applyButton }
				: { ...applyButton, ...uiSchema.modal?.applyButton, uiSchema: {...applyButton.uiSchema, ...uiSchema.modal?.applyButton?.uiSchema} })
		}

		return controlButtons.concat(getActionButtons(buttons, this.self, { readOnly },
				(values: IUiSchemaSetValueResult) => this.updateValues(values, "", ""),
				(str: string, key: string) => this.parseAndFormatText(str, key)
		));

	}



    public render() {

		const lib = this.self.lib;
		const objects = this.self.objects;
		const control = objects.control;

		// While not ready we do no further rendering
		if (!control.ready) {
			return (
				<Col sm={12} style={isMobile ? loaderMobileStyle : loaderStyle} className="LoaderWrapper">
					<i className="fa-regular fa-spinner fa-2x fa-spin"></i>
				</Col>
			);
		}

		this.updateLayout(300);		// we trigger an post layout update of the masonry after 300ms: FIXME: I think we can remove this now.
		const ts0 = Date.now();

		const rootCondJsonSchema = objects.rootCondJsonSchema;
		const uiSchema: IUiSchema = objects.rootJsonSchema?.$uiSchema || {};

		const layoutOptions = {...this.props.defaultLayoutOptions, ...uiSchema.layoutOptions };
		const activeTabKey = control.activeTab || this.getFirstTabKey() || "";
		const uiTabItems = this.getTabItems(activeTabKey, objects);
		const readOnly = !!control.readOnly;

		// const uiHeader = this.renderSchema("$header", condJsonSchema, layoutOptions);
		// const uiBody = this.renderSchema("$body");


		const defPanels: {
			[key: string]: IUiSchemaPanelOptions;
		} = {
			defpanel: {
				title: "",
				cards: Object.keys(uiSchema.cards || {}),
			},
		};

		const tabElems: JSX.Element[] = [];
		const tabview = uiTabItems.length > 0;		// FIXME: make this optional
		const panels = (tabview ? uiSchema.panels : defPanels) || {};
		let panelContent: ReactNode;

		for (const tabkey of Object.keys(panels)) {
			const panel = panels[tabkey];
			const reactCards: ReactNode[] = [];
			const tabLayoutOptions = {...layoutOptions, ...panel.layoutOptions};

			// If we are rendering a tabbed panel view, there are three options to decide if the inactive
			// panels are rendered or not; render-always, render-when-visible and render-after-visible (default).
			if (tabview) {
				if (tabLayoutOptions.panelRenderMode !== "render-always" && activeTabKey !== tabkey && 
						(!this.loadedPanels[tabkey] || tabLayoutOptions.panelRenderMode === "render-when-visible")) {
					tabElems.push(<Tab.Pane key={tabkey} eventKey={tabkey}><div></div></Tab.Pane>);
					continue;
				}
				this.loadedPanels[tabkey] = true;
			}


			// First we process all the cards regardless of the layout structure, so we first extract all the
			// card is from the list
			const panelCardsMap = {};
			flatCardList(panelCardsMap, panel.cards);

			for (const cardkey of Object.keys(panelCardsMap)) {
				if (!uiSchema.cards || !uiSchema.cards[cardkey]) { continue; }

				const card = uiSchema.cards[cardkey];
				const hidden: boolean | null = card.hidden == null ? null
							: typeof card.hidden === "boolean" ? card.hidden
								: typeof card.hidden === "string" && card.hidden
									? evalExpr(card.hidden, lib, objects, { readOnly }, false) : null;
				if (hidden === true) { continue; }
				const cardLayoutOptions = {...tabLayoutOptions, ...card.layoutOptions}

				// Check if we need to render this card, depending on it collapsed state and the renderMode options.
				const cardState = objects.values["/__cardstate_" + cardkey];
				let renderCard = true;
				if (cardState === false || (cardState == null && cardLayoutOptions.cardStyle === "collapsed")) {
					// Content is hidden. 
					if (cardLayoutOptions.cardRenderMode === "render-when-visible" ||
					    (cardLayoutOptions.cardRenderMode === "render-after-visible" && !this.loadedCards[cardkey])) {
							renderCard = false;
					}
				}
				if (renderCard) { this.loadedCards[cardkey] = true; }

				// TODO: when renderCard === false, we should not call this function, but use a lighter one that
				// render check only error the card.

				const cardData = this.renderSchema(cardkey, rootCondJsonSchema, cardLayoutOptions);
				
				// Normally there is only one card, BUT, it is possible in some dynamically generate 
				// additional cards, so we have to loop over the cards again.

				for (let subCardIdx = 0; subCardIdx < cardData.cards.length; subCardIdx++) {
					const subCard = cardData.cards[subCardIdx];

					if (subCard.jsxElements.length > 0 || hidden === false) {

						if (registeredExtensionCardHandlers[cardLayoutOptions.cardStyle]) {

							const args: IUiSchemaCardArgs = {
								key: cardkey + (subCardIdx > 0 ? ":" + subCardIdx : ""),
								readOnly,
								title: card.title,
								hasError: cardData.hasError,
								layoutOptions: cardLayoutOptions,
								actionButtons: card.actionButtons,
								
								self: this.self,
								getSettings: this.props.getSettings,

								updateValues: (values) => this.updateValues(values, "", ""),
								updateLayout: () => this.updateLayout(),

								stringToComponent: (text: string) => {
									const txt = evalString(text, lib, objects, { readOnly }) + "";
									const jsx = this.formatTextArgs(txt, null, cardkey);
									return jsx;
								}
							}

							reactCards.push(registeredExtensionCardHandlers[cardLayoutOptions.cardStyle](args, renderCard ? subCard.jsxElements : []));

						} else {

							reactCards.push(subCard.jsxElements);
						
						}

					}
				}
			}


			const SchemaLayoutComponent = registeredLayoutComponents[tabLayoutOptions.panelLayout];
			
			if (SchemaLayoutComponent) {
				const layoutKey = "__panel_" + tabkey + "_layoutData";
				const onLayoutDataChange = (layoutData: any) => {
					this.updateValues({ values: { [layoutKey]: layoutData } }, "", "");
				};
				panelContent = (
					<SchemaLayoutComponent ref={this.layoutRef} cardList={panel.cards} layoutData={objects.values[layoutKey] || {}} onLayoutDataChange={onLayoutDataChange}>
						{ reactCards }
					</SchemaLayoutComponent>
				);
			} else if (tabLayoutOptions.panelLayout === "none") {
				panelContent = reactCards;		// FIXME: fix the types
			} else {
				panelContent = undefined;
			}

			if (tabview) {
				tabElems.push(
					<Tab.Pane key={tabkey} eventKey={tabkey}>{panelContent}</Tab.Pane>
				);
			}
		}

		this.logTime("render", Date.now() - ts0);



		const dropZoneEvt = (innerObj: ReactNode) => {
			if (uiSchema.modal?.dropFile && !control.readOnly) {
				return (
					<Dropzone noClick={uiSchema.modal.dropFile.openFileDialogOnClick !== true} 
						onDrop={files => {
							this.onDropFile(files, uiSchema.modal.dropFile, "root-dropfile", null)}
						}
					>
						{({getRootProps, getInputProps}) => (
							<section>
								<div {...getRootProps()}>
									<input {...getInputProps()} />
									{innerObj}
								</div>
							</section>
						)}
					</Dropzone>
				)
			}
			return innerObj;
		}


		if (tabview && tabElems.length > 1) {
			
			const scrollToTop = () => {
				const scrollElement:any =document.getElementById('schema-tab-modal')
				if(scrollElement){
					scrollElement.scrollTo({
						top: 0,
						behavior: "smooth",
					});
				}				
			}

			// FIXME: The uiTabItems below is of incompatible type

			return dropZoneEvt(
				<Tab.Container defaultActiveKey={this.getFirstTabKey()} onSelect={(e) => {scrollToTop()}}>
					<Row className="schema-engine-tabs mx-0">
						<Col sm={2} className="px-0">
							<Nav variant="pills" className="flex-sm-column flex-xs-row schema-tab-list">
								{uiTabItems as any}
							</Nav>
						</Col>
						<Col sm={10} className="px-0">
							<Tab.Content className="schema-engine-tabs-content" id="schema-tab-modal">
								{tabElems}
							</Tab.Content>
						</Col>
					</Row>
				</Tab.Container>
			);

		} else {
			return dropZoneEvt(panelContent);
		}

	}	// render()
}





/**
 * NOTE: this code is part of a transition from the old class based component to a new function based component. At the moment
 * some of the functionality here is replicated.
 * 
 * renderArrayTable take the ArrayRow[] data and generate a complete table layout version of the array.
 * 
 * TODO: correct the sorted list of properties.
 */

interface ArrayRowControls {
	add: () => void;
	rem: () => void;
	up?: () => void;
	down?: () => void;
}
export interface ArrayRow {
	elemNodes: IElemNode[];
	controls: ArrayRowControls;
}


function makeArrayRowControlButtons(row: ArrayRow) {

	const jsxButtons: ReactNode[] = [];

	if (row.controls.add) {
		jsxButtons.push(
			<Button key="add" variant="outline-secondary" onClick={row.controls.add} size="sm">
				{fa("fa-add")}
			</Button>
		)
	}
	if (row.controls.rem) {
		jsxButtons.push(
			<Button key="remove" variant="outline-secondary" onClick={row.controls.rem} size="sm">
				{fa("fa-trash-alt")}
			</Button>
		)
	}

	return jsxButtons;
}


export function renderArrayTable(
			rows: ArrayRow[], 
			add: (() => void) | null,
	
			args: IUiSchemaElemArgs, 
			schema: IJsonSchemaObject,
			control: IControl,
			thisObjects: IExprObjects,
			thisProps: ISchemaModalProps,
			thisLib: ISchemaLib
) {

	const { fullkey } = args;

	const header: JSX.Element[] = [];

	const { lang } = thisProps;
	const lib = thisLib;
	const { errors, values, oldValues, oldRootJsonSchema, rootCondJsonSchema, rootJsonSchema, jsonSchema } = thisObjects;
	const readOnly = !!(control.readOnly || rootJsonSchema?.$uiSchema?.modal?.readOnly);
	const objects = { values, errors, oldRootJsonSchema, rootJsonSchema, rootCondJsonSchema, jsonSchema, oldValues, control, uiSchema: rootJsonSchema.$uiSchema || {} };

	const ctrlColStyle = { width: "1%", "white-space": "nowrap", "text-align": "center" };


	let hasControls = false;
	for (const row of rows) {
		if (row.controls.add || row.controls.rem) {
			hasControls = true;
		}
	}

	const elements = rows.map((row, idx) => {
		const cols = row.elemNodes.map(e => <td key={e.args.fullkey}>{e.jsxElem}</td>);
		if (hasControls) {
			cols.push(<td key="row-controls"style={ctrlColStyle}>{makeArrayRowControlButtons(row)}</td>);
		}
		return <tr key={"row" + idx}>{cols}</tr>
	});




	for (const eKey of Object.keys(schema?.properties || {})) {

		if (!schema.properties || !schema.properties[eKey]) { continue; }

		const efullkey = eKey;	// FIXME: add path

		const elem = schema.properties[eKey];
		// const uiElem = (elem || {}).$uiSchemaObject || {};

		const descLayout = "popup";
		const description = evalString((lang && (elem as any)["description[" + lang + "]"]) || elem.description || "",
										lib, objects, { fullkey: efullkey, readOnly, schema: elem }) + "";
		const title       = evalString((lang && (elem as any)["title[" + lang + "]"]) || elem.title || eKey,
										lib, objects, { fullkey: efullkey, readOnly, schema: elem }) + "";

		const titleElem = title ? args.stringToComponent(title) : null;
		const descElem  = description  ? args.stringToComponent(description)  : null;
		const helpTip   = descLayout === "popup" && descElem ? <Tooltip>{descElem}</Tooltip> : null;
		const required  = schema.required?.includes(eKey);

		header.push(<th key={eKey}>
			<Form.Label>
				{titleElem}
				{required && <span style={{color:"red"}}>*</span>}
				{helpTip && <OverlayTrigger
					placement="auto"
					delay={{ show: 250, hide: 400 }}
					trigger={["hover", "focus"]}
					overlay={helpTip}
				>
					<span> {fa("fa-info-circle")}</span>
				  </OverlayTrigger>}
			</Form.Label>
		</th>);
	}

	if (hasControls) {
		header.push(<th key="control-header-column"  style={ctrlColStyle}>{fa("fa-circle-ellipsis")}</th>)
	}

	return (
		<div>
			<Table key={fullkey} bordered={ false } className="schema-engine-array-container-table">
				<thead><tr>{header}</tr></thead>
				<tbody>{elements}</tbody>
			</Table>

			{add && <Button variant="outline-secondary" onClick={add} size="sm">
					{fa("fa-add")}
			</Button>}
		</div>
	);



}
