import React, { lazy, Suspense } from "react";
import { ISchemaFormProps } from "./SchemaForm";
import { registerExtensionFormComponent } from "./SchemaExtensions";

const SchemaFormAceEdit = lazy(() => import("./SchemaFormAceEdit"));

export default function LazySchemaFormAceEdit(props: ISchemaFormProps) {
    return (
        <Suspense fallback={<div><i className="fa-regular fa-spinner fa-spin"></i></div>}>
            <SchemaFormAceEdit {...props}/>
        </Suspense>
    );
};

registerExtensionFormComponent("aceedit", LazySchemaFormAceEdit);
registerExtensionFormComponent("aceedit-object-json", LazySchemaFormAceEdit);