import React from 'react';

import AppState from "./AppState";

import { MapState } from "./MapState";

import { DataTableState } from "../components/Common/DataTable/DataTableState";

import { DataTableChildState } from "../components/Common/DataTable/DataTableChildState";

import { DataTableCommonState } from "../components/Common/DataTable/DataTableCommonState";

import { WebSocketProvider } from '../pages/LiveLog/WebSocketLiveLog';

const ParentDataTableState = DataTableCommonState(DataTableState);

const SubDataTableState = DataTableCommonState(DataTableChildState);

const MainState = ( props ) => {

    return  (
        <AppState>
            <WebSocketProvider>
            <ParentDataTableState >
                <SubDataTableState>
                    <MapState>
                        {props.children}
                    </MapState>
                </SubDataTableState>
            </ParentDataTableState>
            </WebSocketProvider>
        </AppState>
    )
}


export default MainState;

