import React, { lazy, Suspense } from "react";
import { IUiSchemaElemArgs } from "./SchemaController";
import { registerComponentHandler } from "./SchemaExtensions";


const ObjectInspector = lazy(() => import("./JsonObjectInspector"));

const jsonObjectInspector = (args: IUiSchemaElemArgs) => {

	const { value } = args;
	return (
		args.embedObject(
            <Suspense fallback={<div><i className="fa-regular fa-spinner fa-spin"></i></div>}>
                <ObjectInspector 
                    data={value}
                />
            </Suspense>
        )
	);
};

registerComponentHandler("json-object-inspector", jsonObjectInspector);
