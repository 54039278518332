import React, { useState, useContext, useRef, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { strings } from "../../services/Localization";
import { toast } from "../../utils/Toaster";
import "./signin.css";
import { useNavigate } from "react-router-dom";
import AppContext from '../../context/AppContext'
import ReCAPTCHA from "react-google-recaptcha";

import orbiwiseLogo from '../../../resources/images/orbiwise-logo-dark.png';

import { Button, Container, Form } from "react-bootstrap";
import { IConstants } from "src/types";
import { IUser, IWhoAmI } from "src/dassTypes";
import { GenericDassQuery } from "../../services/BasicDassQueries";

declare const constants: IConstants;
export function SignIn() {
    
    return (
        <Container fluid className="background-login">
            <div className="d-flex align-items-center justify-content-center" style={{height: "500px"}}>
                <Container>

                    <div className="row  login-wrapper justify-content-center">
                        <div className="col-md-6 col-sm-6 text-center form-signin">
                            {(constants._license & (1<<16))
                                ? (<img src={constants.left_logo_file} className="img-responsive img_padding_bottom" />)
                                : (<img className="img-responsive img_padding_bottom" src={orbiwiseLogo} />)
                            }
                            <LoginForm />
                        </div>
                    </div>

                </Container>
            </div>
        </Container>
    );
}



const LoginForm = () =>  {

    let navigate = useNavigate();
    const appContextObj = useContext(AppContext);

    const loginStateDefault = {
        password: "",
        otp: "",
        needOtp: false,
        signedIn: false,
        userid: "",
        auco: (constants.disable_autocomplete_for_credentials === true) ? "off" : "on",
        hideContent: false,
    };

    const [loginState, setLoginState] = useState(loginStateDefault);
    const [captchaToken, setCaptchaToken] = useState<string>(null);
    const passwordRef = useRef(null);

    useEffect(() => {

        // If the user is set, then we can't stay on the signin page, and we immediately jump to the root
        if (appContextObj.user?.userid) {
            navigate("/");
        }

    }, [appContextObj.user?.userid]);


    const validateForm = () => {
        return loginState.userid.length > 0 && loginState.password.length > 0 && 
                (!constants._recaptcha_site_key || captchaToken);
    }

    const userIdHandleKeyPress = (e) => {
        if(e.key === "Enter") {
            passwordRef.current?.focus();
        }
    }
    const handleKeyPress = (e) => {
        if(e.key === "Enter") {
            handleSubmit(e);
        }
    }

    const needOtp = loginState.needOtp;

    const canRedirectToApp = (user: IUser) => {
        if (user.ui_settings?.landing_page === "nst") {
            return Object.keys(user).filter(k => k.startsWith("omc_")).length === 0;
        } 
        return true
    }

    // Press login button
    const  handleSubmit = async (event) => {
        event.preventDefault();
        
        if(validateForm()) {
            try {
                const tz = new Date().getTimezoneOffset();
                const tf = new Date().toLocaleTimeString().match(/am|pm/i) ? "ampm" : "24h";
                const { userid, password, otp } = loginState;

                const response = await GenericDassQuery("/signin?tz=" + tz + "&tf=" + tf, {
                    data: {
                        password: password || undefined,
                        otp: otp || undefined,
                        userid: typeof userid === "string" ? userid : undefined,
                        captcha_token: captchaToken || undefined,
                    },
                    waitOn429: true, waitOn429Type: "password",
                    prefix: "", resigninOn401: false,
                    method: "PUT",
                });
                const whoami: IWhoAmI = response.data;
                const user = whoami?.user;


                if (user?.reset_password) {
                    window.location.href = "new_password.html?userid=" + user?.userid;  // FIXME - this page doesn't seem to exist
                } else {
                    appContextObj.updateUser(user);
                    if (!canRedirectToApp(user)) {
                        window.location.href = "/nst/network_map";
                    } else {
                        window.location.href = constants.landing_page_after_signin || `/`;
                    }
                }

            } catch (e) {
                if (e.message?.match(/MFA/)) {

                    // First time we receive the Multifactor required, we set the needOtp to show the OTP input
                    if (loginState.needOtp === false) {
                        setLoginState(prevState => ({ ...prevState, needOtp: true, signedIn: false }));
                    } else {
                        // But seconds time (i.e. if the MFA is wrong), we reset otp and password and go back to password screen
                        setLoginState(prevState => ({ ...prevState, needOtp: false, otp: "", password: "", signedIn: false }));
                        toast.error(strings.LOGIN_FAILED, { autoClose: 3000 });
                    }

                } else if (e.message?.match(/too many failed password/i)) {

                    toast.error(strings.LOGIN_BLOCKED_DUE_TO_TOO_MANY_ATTEMPTS, { autoClose: 5000 });
                    
                } else {

                    setLoginState( prevState => { return {...prevState, signedIn: false} } );
                    if (e.status === 402) {
                        toast.error(e.message, { autoClose: 3000 });
                    } else {
                        toast.error(strings.LOGIN_FAILED, { autoClose: 3000 });
                    }
                }
            }
        }

    }


    return (
        <div>

        {constants._recaptcha_site_key && <ReCAPTCHA className="mb-2"
            sitekey={constants._recaptcha_site_key}
            onChange={(value) => setCaptchaToken(value)}
        />}

        <Form autoComplete={loginState.auco}>
            {!needOtp && <Form.Group className="mb-3">
                <Form.Control
                    autoComplete={loginState.auco} type="text" value={loginState.userid}
                    onChange={v => setLoginState(prev => ({...prev, userid: v.target.value}))} onKeyDown={userIdHandleKeyPress}>
                </Form.Control>
            </Form.Group>}

            {!needOtp && <Form.Group>
                <Form.Control
                    ref={passwordRef}
                    autoComplete={loginState.auco} type="password" value={loginState.password} onKeyDown={handleKeyPress}
                    onChange={v => setLoginState(prev => ({...prev, password: v.target.value}))}>
                </Form.Control>
            </Form.Group>}

            {needOtp && <Form.Group>
                <Form.Control
                        autoComplete="off" type="text" value={loginState.otp} onKeyDown={handleKeyPress}
                        onChange={v => setLoginState(prev => ({...prev, otp: v.target.value}))}
                        placeholder="MFA Code">
                </Form.Control>
            </Form.Group>}


            <div className="pt-2 mt-5 d-grid">
                <Button variant="dark" disabled={!validateForm()} onClick={handleSubmit}>{strings.SIGN_IN}</Button>
            </div>

            <div className="row pb-2">
                {((constants.forgot_username_link !== undefined) &&
                    (constants.forgot_username_link === true)) && (
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <a href="/app/reset_password"
                            className="returnLogin a-link"
                        >
                            {strings.FORGOT_USERNAME}
                        </a>
                    </div>
                )}
                {((constants.forgot_password_link !== undefined) &&
                    (constants.forgot_password_link === true)) && (
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <a href="/app/reset_password"
                            className="returnLogin pull-right a-link"
                        >
                            {strings.FORGOT_PASSWORD}
                        </a>
                    </div>
                )}
            </div>

        </Form>
        </div>
    );
}