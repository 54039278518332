import React, { useState , useEffect, useRef } from "react";
import { Modal, Button } from 'react-bootstrap';
import confirmDialogImage from "../../../resources/images/delete.png";

import LocStr$ from "../../localization/LocaleString";

import { faTriangleExclamation, IconDefinition }  from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createRoot } from "react-dom/client";
import { strings } from "../../services/Localization";
import { formatText } from "../../schemaengine/client/SchemaTextParser";


export interface IConfirmDialogeSettings {
    title: string;
    description: string | any[];
    actionLabel: string;
    cancelLabel?: string;
    faIcon?: IconDefinition;
};


interface IConfirmDialogProps {

    confirmDialogeSettings: IConfirmDialogeSettings;
    closeConfirmDialog?: () => void;
    confirmAction?: () => void;
    show?: boolean;
}


export { IConfirmDialogProps }

const ConfirmDialog: React.FC<IConfirmDialogProps> = ( props ) => {
    const [show, setShow] = useState(true);

    const handleClose = () =>  {
        setShow(oldshow => false)
        props.closeConfirmDialog();
    }

    const handleClick = () =>  {
        setShow(oldshow => false)
        props.confirmAction();
    }

    useEffect(() => {
        setShow(preShow => props.show);
    },[props.show])

    return (<Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header className="border-bottom-0" closeButton>
            </Modal.Header>
            <Modal.Body className="text-center">

                {props.confirmDialogeSettings.faIcon && <FontAwesomeIcon icon={props.confirmDialogeSettings.faIcon} size="6x" />}
                {props.confirmDialogeSettings.faIcon && <br />}
                {!props.confirmDialogeSettings.faIcon && <img className="mb-3" src={confirmDialogImage} />}
                <br/>
                <p className="confirm-dialog-title">{props.confirmDialogeSettings.title}</p>
                <p> {props.confirmDialogeSettings.description}</p>
            </Modal.Body>
            <Modal.Footer className="border-top-0">
                {(props.confirmDialogeSettings.cancelLabel == null || props.confirmDialogeSettings.cancelLabel) &&
                <Button variant="outline-dark" size="sm" onClick={handleClose}>
                    {props.confirmDialogeSettings.cancelLabel || LocStr$("Cancel") }
                </Button>}
                <Button variant="dark" size="sm" onClick={handleClick}>
                    {props.confirmDialogeSettings.actionLabel}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

const dialog = async (confirmDialogeSettings: IConfirmDialogeSettings) => {

    const result = new Promise<boolean>((resolve, reject) => {

        const container = document.getElementById("dialog-div");
        const root = createRoot(container); // createRoot(container!) if you use TypeScript


        let confirmAction = () => {
            root.unmount();
            resolve(true)
        }

        let closeConfirmDialog = () => {
            root.unmount();
            resolve(false)
        }

        root.render(<ConfirmDialog
            confirmAction={confirmAction}
            confirmDialogeSettings={confirmDialogeSettings}
            show={true}
            closeConfirmDialog={closeConfirmDialog}
        />);
    });

    return result;
}



/**
 * waitDialog - show a dialog with a count down timer
 */

interface IWaitUntilDialogProps {

    confirmAction?: (confirm: boolean) => void;
    epoch: number;
    type: "throttle" | "password";
    show?: boolean;
}

const WaitUntilDialog: React.FC<IWaitUntilDialogProps> = ( props ) => {
    const [show, setShow] = useState(true);
    const [now, setNow] = useState(Date.now());
    const timerRef = useRef(null);

    const handleClose = (confirm: boolean) =>  {
        setShow(oldshow => false);
        clearInterval(timerRef.current);
        props.confirmAction(confirm);
    }

    useEffect(() => {
        setShow(preShow => props.show);
    },[props.show])

    useEffect(() => {

        clearInterval(timerRef.current);
        timerRef.current = setInterval(() => {
            const n = Date.now()
            setNow(n);
            if (props.epoch < n) {
                handleClose(true);
            }

        }, 1000)

    },[props.epoch]);

    const seconds = Math.max(0, Math.round((props.epoch -  now) / 1000));
    const message = formatText((props.type === "password" ? strings.MSG_TOO_MANY_ATTEMPTS_WAIT : strings.MSG_TOO_MANY_REQUESTS_WAIT)
                                    .replace(/\{seconds\}/, String(seconds)), "msg", null, null);

    return (<Modal
            show={show}
            onHide={() => handleClose(false)}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header className="border-bottom-0" closeButton>
            </Modal.Header>
            <Modal.Body className="text-center">
                {message}
            </Modal.Body>

            <Modal.Footer className="border-top-0">
                <Button variant="outline-dark" size="sm" onClick={() => handleClose(false)}>
                    { LocStr$("Cancel") }
                </Button>
            </Modal.Footer>
        </Modal>
    );
}




export const waitDialog = async (waitUntil: number, type: "throttle" | "password") => {

    const result = new Promise<boolean>((resolve, reject) => {

        const container = document.getElementById("dialog-div");
        const root = createRoot(container); // createRoot(container!) if you use TypeScript

        let confirmAction = (confirm: boolean) => {
            root.unmount();
            resolve(confirm)
        }

        root.render(<WaitUntilDialog
            confirmAction={confirmAction}
            epoch={waitUntil}
            type={type}
            show={true}
        />);
    });

    return result;
}








const dialogDescription = async(ids) => {

    if (ids[0].length < 17 && ids.length == 1) {
        return `${ids[0]}`;
    } else if (ids[0].length < 17 && ids.length == 2) {
        return `${ids[0]} and ${ids[1]}`;
    } else if (ids[0].length < 17 && ids.length == 3) {
        return `${ids[0]}, ${ids[1]} and ${ids[2]}`;
    } else if (ids[0].length > 17 && ids[0].length < 50 && ids.length == 1 ) {
        return `${ids[0]}`;
    } else if (ids[0].length > 17 && ids[0].length < 35 && ids.length == 2 ) {
        return `${ids[0]} and ${ids[1]}`;
    } else if (ids[0].length > 17 && ids[0].length < 33 && ids.length > 2 ) {
        return "";
    } else if (ids[0].length > 34 && ids.length > 1) {
        return "";
    } else {
        return ""; 
    } 
}

const notImplemented = async () => {
    return await dialog({
        title: "Not implemented",
        description: "This feature is not yet implemented. Please check back later.",
        actionLabel: "OK",
        cancelLabel: "",
        faIcon: faTriangleExclamation,
    });
}


export { dialog, notImplemented, dialogDescription}
