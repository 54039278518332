import { strings } from "./../../services/Localization";
import { DetailPageActionType, DetailPagePropsType } from '../../datatypes/datatypes';
import { faAdd, faList } from '@fortawesome/free-solid-svg-icons'
import { elipsis } from '../../../src/utils/filters';

//import DeviceDataProps from './DeviceDataProps';

import { TAB_DEVICE_DETAIL, TAB_ADD_DEVICE, TAB_VIEW_DATA, TAB_PROVISION_DEVICES } from '../../datatypes/tabsconstants'

import AppContext from '../../context/AppContext'
import { useContext } from "react";




const detailPageNav = (detailPageNavArgs) => {

    const { navigate, tab, deviceUuid, row } = detailPageNavArgs;

    let prevPageUrl = detailPageNavArgs.hasOwnProperty('prevPageUrl') ? detailPageNavArgs['prevPageUrl'] : null;
    //const deviceUuid = id;
    if (deviceUuid) {

        navigate(`/app/dmp-devices/${deviceUuid}/${tab}`, { state: { row: row, prevPageUrl: prevPageUrl } })

    } else {

        navigate(`/app/dmp-devices/${tab}`, { state: { tab: tab, prevPageUrl: prevPageUrl } })
    }

}


const DevicePageProps: DetailPagePropsType = {
    getBreadCrumbArr: (breadCrumbArgs) => {

        const prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : '-1';
        switch (breadCrumbArgs.tabname) {
        case TAB_ADD_DEVICE:
            return [
                { label: strings.MY_DEVICES_TITLE, url: prevPageUrl },
                { label: strings.ADD_DEVICE_BTN, url: '' }
            ];
        case TAB_PROVISION_DEVICES:
            return [
                { label: strings.MY_DEVICES_TITLE, url: prevPageUrl },
                { label: strings.PROVISION_DEVICES_BTN, url: '' }
            ];
        default: return [];
        }
    },
    getActions: (actionArgs, navigate) => {

        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : '';

        return [
            {
                type: "tab",
                text: strings.ADD_DEVICE_BTN,
                icon: faAdd,
                key: TAB_ADD_DEVICE,
                action: (id) => detailPageNav({ navigate: navigate, tab: TAB_ADD_DEVICE, deviceUuid: '', row: {}, prevPageUrl: prevPageUrl }),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/nodes?get_schema=dassui,post`,
                    backUrl: prevPageUrl
                },
            },
            {
                type: "tab",
                text: strings.PROVISION_DEVICES_BTN,
                icon: faList,
                key: TAB_PROVISION_DEVICES,
                action: (id) => detailPageNav({ navigate: navigate, tab: TAB_PROVISION_DEVICES, deveui: '', row: {}, prevPageUrl: prevPageUrl }),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/devices/batch-provision?get_schema=dassui,post`,
                    backUrl: prevPageUrl
                },
            },

        ]
    }
}

export { DevicePageProps };




const DeviceRowProps: DetailPagePropsType = {
    getBreadCrumbArr: (breadCrumbArgs) => {
        const { id, row, prevPageUrl } = breadCrumbArgs;
        const deviceName = (row.name && row.name.length > 20) ? elipsis(row.name, 20) : row.name

        return [
            { label: strings.MY_DEVICES_TITLE, url: prevPageUrl },
            { label: deviceName, url: '', comment: id }
        ]
    },
    getActions: (actionArgs, navigate) => {

        const { row } = actionArgs;
        const deviceUuid = row.hasOwnProperty('deviceUuid') ? row.deviceUuid : (row.hasOwnProperty('id') ? row.id : null);
        const prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs.prevPageUrl : null;
        const AppContextObj = useContext(AppContext);
        const user = AppContextObj.user;
        //const DeviceDataObj = new DeviceDataProps(deviceUuid, user);
        

        const actions: DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.MY_DEVICES_DETAIL,
                icon: faAdd,
                key: TAB_DEVICE_DETAIL,
                action: (row) => detailPageNav({ navigate: navigate, tab: TAB_DEVICE_DETAIL, deviceUuid, row: row, prevPageUrl: prevPageUrl }),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/nodes?get_schema=dassui,put`,
                    backUrl: prevPageUrl
                },
                EditObject: deviceUuid ? { deviceUuid } : null
            }

        ]
     
        if(actionArgs && actionArgs.prevPageUrl){
            actions.push({
                type: "tab",
                text: strings.MY_DEVICES_ACTION_DATA,
                icon: faList,
                key: TAB_VIEW_DATA,
                action: (row) => detailPageNav({ navigate: navigate, tab: TAB_VIEW_DATA, deviceUuid, row: row, prevPageUrl: prevPageUrl }),
                content: {
                    type: 'Component',
                    url: '',
                    backUrl: prevPageUrl,
                    props: { deviceUuid, user },
                    ComponentName: 'DmpDeviceViewDataTable'
                    // pageButtons: DeviceDataObj.getPageButtons(),
                    // columns: DeviceDataObj.getColumns(),
                }
            });
        } else {
            actions.push({
                type: "tab",
                text: strings.MY_DEVICES_ACTION_DATA,
                icon: faList,
                key: TAB_VIEW_DATA,
                action: (row) => detailPageNav({ navigate: navigate, tab: TAB_VIEW_DATA, deviceUuid, row: row, prevPageUrl: prevPageUrl }),
                content: {
                    type: 'Component',
                    url: '',
                    backUrl: prevPageUrl,
                    props: {deviceUuid, user},
                    ComponentName: 'DeviceViewDataTable'
                    // pageButtons: DeviceDataObj.getPageButtons(),
                    // columns: DeviceDataObj.getColumns(),
                }
            });
        }

        

        return actions;
    }
}


export { DeviceRowProps }