import React from 'react'

import { getPageDisplayStatus } from '../datatypes/tabsconstants'

import { Outlet } from 'react-router-dom';

import { DataTable, PageHeader,  DetailPageContainer, DataTableWrapper} from '../components/Common';

const PageContent = ( props ) => {
    
    const {id, tabname, actions, breadCrumbArr, pageButtons, countLabel, dataTableOption, refresh, name, exportInit, isRowDeleted, serviceProgress, rowHeight, dataTableView  } = props;
    
    let display = getPageDisplayStatus(id, tabname);

    let opts = {};
    opts['display'] = display;
    if(props.wrapperClass) {
        opts['wrapperClass'] = props.wrapperClass;
    }
    

    
    return (<>
            <DataTableWrapper {...opts}>
                <PageHeader name={name} breadCrumbArr={breadCrumbArr} serviceProgress={serviceProgress} columns={dataTableOption.columns} pageButtons={pageButtons} countLabel={countLabel} dataTableView={dataTableView} />
                <DataTable name={name} display={display}  dataTableOption={dataTableOption} refresh={refresh} exportInit={exportInit} countLabel={countLabel}  isRowDeleted={isRowDeleted} rowHeight={rowHeight} dataTableView={dataTableView} />
            </DataTableWrapper>
        <div>
                <DetailPageContainer >
                    <Outlet  context={{actions: actions}} />
                </DetailPageContainer>
            </div>
            {props.children}
        </>
    )
}

export default PageContent;