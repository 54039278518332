import React, {useState, useContext, useCallback, useEffect } from "react";
import * as ReactDOMServer from 'react-dom/server';
// import { ProfilesTable } from "./../../components/Profiles/ProfilesTable";
import { strings } from "./../../services/Localization";
import AppContext from '../../context/AppContext'

import { useParams, useNavigate  } from 'react-router-dom';

import { dialog, dialogDescription  } from '../../components/Common';

import { dateTimeString, getVisibleActions } from '../../utils/filters';

import greenLight from "../../../resources/images/green_light.png";

import redLight from "../../../resources/images/red_light.png";

import yellowLight from "../../../resources/images/yellow_light.png";

// import amberLight from "../../../resources/images/amber_light.png";

import { DataTableContext} from "../../components/Common/DataTable/DataTableState";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

declare const constants;

import { BreadCrumbType } from '../../datatypes/datatypes';

//import { Outlet  } from 'react-router-dom';

import { toast } from "./../../utils/Toaster";

import { getAppBase, autoRefreshInterval } from "./../../utils/consts";

import {  PageButtonType } from '../../datatypes/datatypes';

import { TAB_GATEWAY_CONFIGURATION, TAB_GATEWAY_BULK_OTA, TAB_AUTHORIZE_GATEWAY } from '../../datatypes/tabsconstants'

import PageContent from "../PageContent";

import{ ID_INPUT_VALIDATION, DEFAULT_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";

import {
    faTrashAlt,
    faBoltAuto, 
    faRefresh,
    faSignalStreamSlash,
    faCog,
    faUpload,
    faFileImport
 }  from '@fortawesome/pro-regular-svg-icons';

import { IUser } from "src/dassTypes";
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { ActionType, BulkActionType, ColumnType, DataTableOption } from "../../components/Common/DataTable/DataTypes";
import { faTag } from "@fortawesome/free-solid-svg-icons";


interface IMyGatewaysStates {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    pageTitle: string;
    editEntityId: string | null;            // The entity in this view is a gateway
    schemaMethod: "post" |"put" | "" | string;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;
    autoRefresh:boolean;
    isRowDeleted?:boolean;
}


export interface IRowType {
    gatewayUuid?: string;
    activated: boolean;
    alarmCounters: {
        numAlarms: number;
        worstSeverity: number;
        activeAlarmIds: number[],
        omServerConnStatus: "DISCONNECTED"
    };
    antennaParameters: Array<{
        cableLoss: number;
        antennaGain: number;
    }>;
    backhaulCellRssi: string;
    backhaulType: string; // "eth0"
    beaconEnabled: boolean;
    beaconFreq: number;

    boot: {
        cause: string;  // "Missed netserver keep-alive"
        firmware_version: string;   // "pktfwd v1.0.5 Hal v1.0.5"
        gwdaemon_version: string;   // "v6.2.0-RC1"
        info: {
            hw_version: string; // "V1.1"
            product_id: string; // "UG67-L00E-868M"
            serial_number: string; //"6222B0201292"
            sw_version: string; // "60.0.0.36"
        };
        hw_version: string;     // "V1.1"
        product_id: string;     // "UG67-L00E-868M"
        serial_number: string;  // "6222B0201292"
        sw_version: string;     // "60.0.0.36"
        reboot_count: number;   // 8
        uuid: string;          // "24e124fffef15450"
        version: "5" | "6";
    }

    cellularConnectionType: string;     // ""
    concentratorState: "ENABLE" | "DISABLE";        // TODO
    enabledFrequencies: number[];
    gatewayCapabilities: {


        bands_supported: Array<{
            beg: number;        // freq
            end: number;
        }>;

        distant_reboot: boolean;
        freq_update: boolean;
        fw_update: boolean;
        is_beacon_tx_capable: boolean;
        is_gps_sync: boolean;
        logs_upload: boolean;
        max_tx_pwr: number;
        ns_time_alignment: boolean;
        num_1301: number;
        num_antenna_connectors: number;
        radio_type: "v1.5" | "v2";      // TODO:
        rssi_scan: boolean;
        ssh_terminal: boolean;
        tx_filtering_below_868: boolean;
        tx_pwr_ctrl: boolean;
    };

    gtwDaemonVersion: string;       // "v6.2.0-RC1"
    gtwFmwVersion: string;          // "pktfwd v1.0.5 Hal v1.0.5"
    gtwState: "GTW_CONFIGURED" | ""; // TODO:
    gtwType: string;                // "Milesight UG67, 865-867MHz"
    ipaddress: string;              // "0.0.0.0"
    keepalive: {
        info: {
            ps_no_of_pkt: 0,
            cl5: 13,
            cl15: 9,
            uram: 58,
            disk_usage: "5.00 %",
            memory_usage: "36.02 %"
        };
        stat: {
            ns_stat: {
                ka_ok: number;
                ka_ko: number;
                avg_lat: number;
            };
            om_stat: {
                ka_ok: number;
                ka_ko: number;
                avg_lat: number;
            };
            pktfwd_stat: {
                rxok: number;
                txnb: number;
                tx_rej: number;
                ns_pfu: {
                    ok_count: number;
                    ko_count: number;
                    avg_lat: number;
                }
            }
            recov_stat: {
                ok_count: number;
                ko_count: number;
                avg_lat: number;
            };
        };
    }
    lastNetserverKeepaliveDate: string;     // "2021-11-10T17:21:17.266+0000"
    lastOmcUDPKeepaliveDate: string;        // "2021-11-16T09:17:44.102+0000"
    lastRestKeepaliveDate: string;          // "2021-11-16T09:17:23.950+0000"
    latitude: number;
    longitude: number;
    maxDownlinkPowerFreqBands: Array<{
        authorizedAntennaIdx: number[];
        classBdownlinkMaxTxPower: number;
        highFreq: number;
        lowFreq: number;
        rx1MaxTxPower: number;
        rx2MaxTxPower: number;
    }>;

    name: string;
    netIntState: "ENABLE" | ""; // TODO
    nsKeepaliveInterval: number;
    parameters: []
    status: "OFF_OR_BACKHAUL_ISSUE" | "";       // TODO:

    subnets_v6: Array<{
        connection_active: true
        def_route: true
        device: "eth0"
        ip: "192.168.100.225"
        mac: "24:e1:24:f1:54:50"
        type: "WIRED"
    }>;

    supplier: string;
    tenantName: string;
    tenantid: string;               // "00000000-0000-0000-0000-000000000000"
    type: string;                   // "UG67, 865-867MHz"
    uuid: string;                   // "24e124fffef15450"
    id?:string;
    validSubnets: [];
    tags?: Array<{
    tagName: string;
    tagid: number;
    description: string;
    tenantId: string;
    color?: string;
    }>;

}



type filterOptionType = {
    label:string;
    value:string;
}

let filterTypeOptions: filterOptionType[] = [
    { label: "Yes",   value: "true" },
    { label: "No",   value: "false" },
];

const OmcGateways : React.FC<{}> = () =>  {

    let user = {};

    let { id, tabname } = useParams();

    const navigate = useNavigate();
    const AppContextObj = useContext(AppContext);
    // const DataTableContextObj = useContext(DataTableContext);


    const StatusValues = {
		ANY:                   { label: strings.GATEWAY_STATUS_ANY,    value: "" },
		NEVER_SEEN:            { label: strings.GTW_STATUS_NEVER_SEEN,            col: "black" },
		GTW_INIT:              { label: strings.GTW_STATUS_GTW_INIT,              col: "red", t: "o" },
		OK:                    { label: strings.GTW_STATUS_OK,                    col: "green" },
		OFF:                   { label: strings.GTW_STATUS_OFF,                   col: "red", t: "o" },
		BACKHAUL_ISSUE:        { label: strings.GTW_STATUS_BACKHAUL_ISSUE,        col: "red", t: "o" },
		OFF_OR_BACKHAUL_ISSUE: { label: strings.GTW_STATUS_OFF_OR_BACKHAUL_ISSUE, col: "red", t: "o" },
		RADIO_OFF:             { label: strings.GTW_STATUS_RADIO_OFF,             col: "red", t: "o" },

		WARNING:     { text: "Warning",     col: "yellow", t: "n" },
		MINOR_ISSUE: { text: "Minor Issue", col: "orange", t: "n" },
		MAJOR_ISSUE: { text: "Major Issue", col: "red",    t: "n" },
	}
   
	// const useColors = true;

	// statusIcons = {
	// 	red: "fas fa-exclamation-triangle" + (useColors ? " led_red" : ""),
	// 	orange: "fas fa-exclamation-circle" + (useColors ? " led_orange" : ""),
	// 	yellow: "fas fa-info-circle" + (useColors ? " led_yellow" : ""),
	// 	green: "fas fa-check" + (useColors ? "" : ""),
	// 	black: "fas fa-question" + (useColors ? "" : ""),
	// }

    // const statusIcons = {
	// 	red: faExclamationTriangle,
	// 	orange: faExclamationCircle,
	// 	yellow: faInfoCircle,
	// 	green: faCheck,
	// 	black: faQuestion,
	// } 

    const stateInit: IMyGatewaysStates = {
        loggedUser: null,
        showAlertModal: false,
        pageTitle: strings.GATEWAYS_TITLE,
        editEntityId: '',
        schemaMethod: "",
        breadCrumbArr: [{label:strings.NAV_GATEWAYS_ITEM, url:''}],
        refresh:false,
        autoRefresh:false,
        isRowDeleted:false
    };

    const [state, setState] = useState<IMyGatewaysStates>(stateInit)


    const detailPageNav = ( navigate, tab, id, row = null) => {
        if(id) {
            
            navigate(`${getAppBase()}/omc-gateways/${id}/${tab}`, {state: {row: row, prevPageUrl: `${getAppBase()}/omc-gateways`}})

        }else {
            
            navigate(`${getAppBase()}/omc-gateways/${tab}`, {state: {tab: tab, prevPageUrl: `${getAppBase()}/omc-gateways`}})
        }
    }

    const gatewayBulkAction = ( navigate, tab, selectedIds ) => {

        navigate(`${getAppBase()}/omc-gateways/bulkaction/${tab}`, {state: {tab: tab, selectedIds:selectedIds, prevPageUrl: `${getAppBase()}/omc-gateways`}})

    }

    const nagviateToManageGtwAuth = ( navigate, tab) => {
        
        navigate(`${getAppBase()}/gateway-manage-auth/${tab}`, {state: {tab: tab, prevPageUrl: `${getAppBase()}/omc-gateways`}})
    }


    const deleteGateway = async ( gateway: IRowType ) => {
        const confirmDialogeSettings = {
                title:  "You are about to delete a gateway",
                description: "Delete '" + gateway.uuid + "'?",
                actionLabel: 'Delete',
        };

        if (await dialog(confirmDialogeSettings) === true) {
            // spinnerClass.show();
            try {
                await GenericDassQuery("/rest/omc/gateways/" + gateway.uuid, { method: "DELETE" });
                refreshTable(true);
                toast.success("Gateway successfully deleted");
            } catch (e) {
                toast.error(e.message);
            }
            // spinnerClass.hide();getActions
        }
    }


    const deleteBulkGateways = async (selectedRows: IRowType[]) => {
        
        
        const gatewayIds = selectedRows.map((row) => {
            return row['uuid'];
        })

        if(gatewayIds && gatewayIds.length > 0) {
            const confirmDialogeSettings = {
                title:  `Do you want to delete the ${gatewayIds.length} selected gateway(s)`,
                description: await dialogDescription(gatewayIds),
                actionLabel: 'Delete',
            };
    
            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(gatewayIds.map((gatewayId) => {
                        return GenericDassQuery("/rest/omc/gateways/" + gatewayId, { method: "DELETE" })
                    })).then((values) => {
                        refreshTable(true);
                        toast.success("Gateways successfully deleted");
                    });
                } catch (e) {
                    toast.error(e.message);
                }                
            }
        }
    }

    const stopLoraRadioBulk = async (selectedRows: IRowType[]) => {
        const gatewayIds = selectedRows.map((row) => {
            return row['uuid'];
        })
        if(gatewayIds && gatewayIds.length > 0) {
            const confirmDialogeSettings = {
                title:  `Do you want to Stop Lora Radio for ${gatewayIds.length} gateway(s) ?`,
                description: await dialogDescription(gatewayIds),
                actionLabel: 'Stop LoRa Radio',
                faIcon: faSignalStreamSlash
            };
    
            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    let gtwfails = []
                    for(let gatewayId of gatewayIds) {
                        try {
                        await GenericDassQuery("/rest/omc/stop_lora_radio/" + gatewayId, { method: "PUT" });
                        } 
                        catch (e) { 
                            gtwfails.push(gatewayId)
                        }
                    }
                    if(gtwfails.length > 0) {
                        toast.error(`Failed to stop LoRa Radio for ${gtwfails.length} gateways \r\n ${gtwfails.join(", \n")}`);
                    } else {
                        toast.success("Bulk Stop LoRa Radio Command sent successfully");
                    }
                    
                } catch (e) {
                    toast.error(e.message);
                }       
            }
        }
    }

    // Here and using DataTableContext for each row to get filter tags values
    // Fixme need to declare hook above but this is effecting the feature hence had put again need to check solution from this
    function gatewayNameTags(row: IRowType) {  
        const DataTableContextObj = useContext(DataTableContext);      
        const tagfilters = DataTableContextObj.searchState.inputFields.search_tags ? DataTableContextObj.searchState.inputFields.search_tags : null
        const tagfiltersIds = tagfilters ?  tagfilters.split(",") : [];
        let res: JSX.Element[] = [];
        tagfiltersIds.forEach( id => {
            const color = row.tags?.find(v => v.tagid == id)?.color;
            if(color) {
                res.push(<FontAwesomeIcon key={"tag" + id} icon={faTag} color={color} size="xs"></FontAwesomeIcon>)
            };
        })
        res.push(<span key="tagrow">{row.name}</span>)
        return (<div className="ow-nst-server-status-info">{res}</div>) as any;
    }

    const  getActions = () => {
        
        const actions: ActionType<IRowType>[] = [
            {
                type: "action",
                text: strings.DELETE_GATEWAY,
                icon: faTrashAlt,
                action: (gateway: IRowType)=> deleteGateway(gateway),
                visible: (gateway: IRowType) => AppContextObj.user.omc_delete_gtw,
            }
        ];
        const bulkActions: BulkActionType<IRowType>[] = [
            {
                type: "action",
                text: strings.DELETE_GATEWAYS,
                icon: faTrashAlt,
                action: (gatewayIds) => deleteBulkGateways(gatewayIds),
                visible:() => AppContextObj.user.omc_delete_gtw
            },
            {
                type: "action",
                text: "Stop Lora Radio", // Fixme should be like string.xxxxx
                icon: faSignalStreamSlash,
                action: (gatewayIds) => stopLoraRadioBulk(gatewayIds),
                visible:() => AppContextObj.user.omc_mng_gtw_rf_conf
            },
            {
                type: "action",
                text: "Batch OTA Update", // Fixme should be like string.xxxxx
                icon: faUpload,
                action: async(selectedIds) => gatewayBulkAction(navigate, TAB_GATEWAY_BULK_OTA, selectedIds),
                visible:() => AppContextObj.user.omc_mng_gtw_firmware
            }
        ];
        return { actions, bulkActions };
    }

    const navigateToBlockedGateways = () => {
        navigate(`${getAppBase()}/blocked-gateways`)
    }

    const navigateToDefaultGateways = () => {
        navigate(`${getAppBase()}/omc-gateway-defaults`)
    }

    const navigateToGatewayTags = () => {
        navigate(`${getAppBase()}/gateway-tags`)
    }

    const navigateToDefaultGatewayParameters = () => {
        navigate(`${getAppBase()}/gateway-parameters`)
    }

    const toggleAutoRefresh = () => {

        setState(prevState => { 
            return {...prevState, autoRefresh:!state.autoRefresh}
        })
    }

    const getPageButtons = useCallback(() => {
    
        const pageButtons: PageButtonType[] = [
            {
                title: strings.REFRESH_DEVICE_LIST,
                action: () => {  refreshTable() },
                type: 'button',
                icon: faRefresh
            },
            {
                title: (state.autoRefresh) ?  strings.STOP_AUTO_REFRESH : strings.AUTO_REFRESH,
                action: () => {  toggleAutoRefresh() },
                type: 'button_with_icon',
                varient: 'primary',
                icon: faBoltAuto
                
            },
            {
                title: strings.MANAGE_GATEWAY_AUTHORIZATION,
                action: () => nagviateToManageGtwAuth(navigate, TAB_AUTHORIZE_GATEWAY),
                type: 'button',
                varient: 'primary',
                icon: faFileImport,
                visible: () => AppContextObj.user.omc_mng_blocked_gateways
            },
            {
                title: "",
                type: 'dropdown_only_icon',
                icon: faCog,
                dropDownItems: [
                    {
                        title: strings.BLOCKED_GATEWAYS,
                        action: () => { navigateToBlockedGateways() },
                        visible: AppContextObj.user.omc_mng_blocked_gateways
                    },
                    {
                        title: strings.DEFAULT_GATEWAY_PARAMS,
                        action: () => navigateToDefaultGatewayParameters(),
                        visible: AppContextObj.user.omc_mng_gtw_params
                    },
                    {
                        title: strings.DEFAULT_GATEWAYS_CONFIG,
                        action: () => navigateToDefaultGateways(),
                        visible: AppContextObj.user.omc_mng_default_gtw_rf_conf
                        
                    },
                    {
                        title: strings.GATEWAY_TAGS,
                        action: () => navigateToGatewayTags(),
                        visible: AppContextObj.user.omc_mng_gtw_tags 
                        
                    },
                ],
                visible: () => ( AppContextObj.user.omc_mng_gtw_tags ||
                                 AppContextObj.user.omc_mng_default_gtw_rf_conf ||
                                 AppContextObj.user.omc_mng_gtw_params ||
                                 AppContextObj.user.omc_mng_blocked_gateways ||
                                 AppContextObj.user.omc_mng_blocked_gateways )
  
            },
        ]

        return pageButtons;
    }, [state.autoRefresh]);

    const navigateToAlarmPage = (navigate, row) => {

        navigate(`${getAppBase()}/gateway-alarms?search_neid=${row.uuid}`, {state: {row: row}})

    }

    const  initDataTable = () => {

        const { actions, bulkActions } = getActions();

        let filterOptions: filterOptionType[] = [];

		const t = user && AppContextObj.user.can_access_gtw_unfiltered ? "n" : "o";

		for (const key of Object.keys(StatusValues)) {
			const val = StatusValues[key];
			if (!val.t || val.t === t) {
				filterOptions.push({ label: val.label || val.text, value: val.value != null ? val.value : key });
			}
		}

		let columns: ColumnType[] = [
            {
                key: "name",
                dbKey: "name", // Fixme This make an error in console for Unique key (Can we use tags here) ?
                type: "text",
                title: strings.TABLE_NAME,
                newCellWidth: "150px",
                sortable: true,
                sortKey: "sort_by_name",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                filterable: true,
                filterField: 'search_name',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined
                },
                cellWidth: 20,
                render: (row:IRowType) => gatewayNameTags(row)
            },
            {
                key: "uuid",
                type: "text",
                title: strings.TABLE_NST_GATEWAY_ID,
                sortable: true,
                sortKey: "sort_by_uuid",
                newCellWidth: "180px",
                inputValidation: ID_INPUT_VALIDATION,
                filterable: true,
                filterField: 'search_uuid',
                filterType: 'text',
                
                filterParams: {
                    mapper: (x) => x || undefined
                },
                detailLink:true,
                copyLink: true,
                detailPageNav: (row: IRowType) => detailPageNav(navigate, TAB_GATEWAY_CONFIGURATION, row.uuid, row),
                customClass: 'font-monospace nowarp'
            },
            {
                key: "uuid", // Fixme To Avoid Error in console for Unique Key
                title: strings.STATUS,
                type: "icon_with_tooltip",
                newCellWidth: "90px",
                sortable: true,
                sortKey: "sort_by_alarmCounters.worstSeverity",
                filterParams: {
                    options:filterOptions
                },
                dataAlign:'center',
                cellWidth: 'nowrap',
                //render_icon: (x) => statusIcons[StatusValues[x.status].col] || "",
                htmlRender: (row:IRowType) => {

                    // let colr = statusIcons[StatusValues[x.status].col];
                    const colr = row.lastOmcUDPKeepaliveDate ? (row.alarmCounters?.worstSeverity == 3 ? 'red' : row.alarmCounters?.worstSeverity == 0 ? 'green' : 'yellow') : "never_received";
                    
                    let jsDate = row?.lastOmcUDPKeepaliveDate;
                    let fDate = new Date(jsDate).getTime()
                    const dat = dateTimeString(jsDate)
                    const cDate = Date.now();
                    // get total seconds between the times
                    let delta = Math.abs(cDate - fDate) / 1000;
                    // calculate (and subtract) whole days
                    const days = Math.floor(delta / 86400);
                    delta -= days * 86400;
                    // calculate (and subtract) whole hours
                    const hours = Math.floor(delta / 3600) % 24
                    delta -= hours * 3600;
                    // calculate (and subtract) whole minutes
                    const minutes = Math.floor(delta / 60) % 60;
                    delta -= minutes * 60;
                    
                    const tip = `Last UDP keep alive: ${dat} (${days} days ${hours}:${minutes})`;
                    
                    if(colr === 'red') {
                        return ReactDOMServer.renderToString(<div className="d-flex justify-content-center" title={tip}><img src={redLight} width="16px" /></div>) 
                    }else if(colr === 'green') {
                        return ReactDOMServer.renderToString(<div className="d-flex justify-content-center" title={tip}><img src={greenLight} width="16px" /></div>) 
                    }else if(colr === 'yellow') {
                        return ReactDOMServer.renderToString(<div className="d-flex justify-content-center" title={tip}><img src={yellowLight} width="16px" /></div>) 
                    }else {
                        // We can keep here grey icon and tooltip as Never Recieved ?
                        return ReactDOMServer.renderToString(<div className="d-flex justify-content-center" title={""}><img src={""} width="16px" /></div>) 
                    }
                    
                },                
            },
            {
                key: "uuid",    
                dbKey: "activated",
                header_tooltip: "Server has received initial boot message from gateway",
                title: strings.TABLE_NST_GATEWAY_HAS_BEEN_SEEN,
                type: "text",
                newCellWidth: "100px",
                render: (x:IRowType) => x.lastOmcUDPKeepaliveDate ? "Yes" : "No",
                sortable: true,
                sortKey: "sort_by_activated",
                filterable: true,
                filterField: 'search_activated',
                filterType: 'select',
                filterParams: {
                    mapper: (x) => x || undefined,
                    options: filterTypeOptions
                },
                cellWidth: 'nowrap'

            },
            {
                key: "tenantName",
                type: "text",
                title: strings.TABLE_NST_TENANT,
                sortable: true,
                newCellWidth: "100px",
                sortKey: "sort_by_tenantName",

                filterable: false,
                filterField: 'search_tenantName',
                filterType: 'text',
                
                filterParams: {
                    mapper: (x) => x || undefined
                },
            },


            {
                key: "alarmCounters",
                title: strings.TABLE_NST_NUM_ALARMS,
                type: "text",

                sortable: true,
                sortKey: "sort_by_alarmCounters.numAlarms",

                filterable: false,
                newCellWidth: "90px",
                filterField: 'search_name',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined
                },
                render: (gateway: IRowType) => (gateway.alarmCounters?.numAlarms || 0) + "",
                detailLink:true,
                copyLink: false,
                customNavigation: (row: IRowType) => navigateToAlarmPage(navigate, row),
                dataAlign:'center',
                cellWidth:'nowrap'
            },

            {
                key: "lastOmcUDPKeepaliveDate",
                title: strings.TABLE_NST_GATEWAY_LAST_CONNECTION_TIME,
                type: "text",

                sortable: true,
                sortKey: "sort_by_lastRebootTime",

                filterable: false,
                newCellWidth: "190px",
                filterField: 'search_name',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined
                },
                render: (gateway: IRowType) => dateTimeString(gateway.lastOmcUDPKeepaliveDate),
                cellWidth: 'nowrap'
            },

            {
                key: "gtwType",
                title: strings.TABLE_NST_GATEWAY_TYPE,
                type: "text",

                sortable: true,
                sortKey: "sort_by_gtwType",

                filterable: false,
                filterField: 'search_gtwType',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined
                },
                cellWidth: 'nowrap'

            },
            {
                key: "gtwDaemonVersion",
                title: strings.TABLE_NST_GATEWAY_SW_VERSION,
                type: "text",
                newCellWidth: "120px",
                sortable: true,
                sortKey: "sort_by_gtwDaemonVersion",

                filterable: false,
                filterField: 'search_name',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined
                },
            },

            {
                key: "backhaulType",
                title: strings.TABLE_NST_GATEWAY_BACKHAUL,
                type: "text",

                sortable: true,
                sortKey: "sort_by_backhaulType",

                filterable: false,
                filterField: 'search_name',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined
                },
                cellWidth: 'nowrap',
            },

            {
                key: "backhaulCellRssi",
                title: strings.TABLE_NST_GATEWAY_CELLULAR_RSSI,
                type: "text",

                sortable: true,
                sortKey: "sort_by_backhaulCellRssi",

                filterable: false,
                filterField: 'search_name',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined
                },
                newCellWidth: "120px",
            },

            {
                key: "cellularConnectionType",
                title: strings.TABLE_NST_GATEWAY_CELLULAR_CONNECTION,
                type: "text",
                newCellWidth: "140px",
                sortable: true,
                sortKey: "sort_by_cellularConnectionType",
                render: (gateway: IRowType) => gateway.cellularConnectionType === "NO_SIM" ? "No SIM" : gateway.cellularConnectionType,
                filterable: false,
                filterField: 'search_name',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined
                },
            }
        ];
        // URL: https://cto-playground.dev.orbiwise.com:8443/omc/gateways?tags=&untagged=false&page=0&size=10&sort=name,asc&activated=true&projection=gatewayListView&sort=uuid,asc
        const options: DataTableOption = {
            url:'/uiapi/rest/omc/gateways',
            query_param: {
                all: true,
                get_pages: true,
                limit: DEFAULT_RECORD_LIMIT,
                stream: 'progress',
                projection: "gatewayListView"

            } as any,
            serial_number: false,
            id_field:'uuid',
            oboe_path:'pages.*',
            available_key: 'uuid',
            defaultSortField:"sort_by_activated", //should be sortKey field
            defaultSortOrder:"desc",
            columns,
            actions: actions,
            bulkActions : getVisibleActions(bulkActions),
            
            extraSearchProp: {key:'search_tags', label:'', filterType: 'tags', populateUrl:'/uiapi/rest/omc/tags'},

        }

        return options;
	}
    

    const refreshTable = (isRowDeleted=false) => {
        setState(prevState => {
            return {...prevState, refresh:!prevState.refresh,isRowDeleted:isRowDeleted}
        })

    }

    useEffect(() => {
        const interval = setInterval(() => {
            if(state.autoRefresh) {
                refreshTable();
            }
        }, autoRefreshInterval);
        return () => clearInterval(interval);

    }, [state.autoRefresh]);

    
    return (<PageContent
        name={`omc-gateways`} 
        id={id} 
        tabname={tabname} 
        actions={getActions()} 
        breadCrumbArr={state.breadCrumbArr} 
        pageButtons={getPageButtons()} 
        countLabel={`Gateways`} 
        dataTableOption={initDataTable()} 
        isRowDeleted={state.isRowDeleted}
        refresh={state.refresh}>
      </PageContent>)

}


export default OmcGateways;