import React, { useContext, useEffect } from 'react';
import { useParams, useNavigate , useLocation  } from 'react-router-dom';
import AppContext from "../../context/AppContext";
import DeviceViewDataTable from '../../pages/Devices/DeviceViewDataTable';
import DmpDeviceViewDataTable from '../../pages/Devices/DmpDeviceViewDataTable';

import GroupActivityDataTable from '../../pages/Groups/GroupActivityDataTable';

import JobsDataTable from '../../pages/GatewayJobs/GatewayJobsViewTable'

import { SchemaModal } from "../../components/SchemaModal/SchemaModal";

import { Button   } from "react-bootstrap";

import { BreadcrumbComponent } from '../../components/Common';

import { BreadCrumbType, DetailPageActionType } from '../../datatypes/datatypes';

import { isTableViewTab } from '../../datatypes/tabsconstants';
import { getDataFromSessionStorage, removeDataFromSessionStorage, setDataToSessionStorage } from '../../utils/sessionStorage';


const DetailPage = ( props  ) => {
    //const location = useLocation();
    // let tab = '';
    // if(location.state && location.state.hasOwnProperty('tab')) {
    //     tab = location.state['tab'];
    // }
    const appContextObj = useContext(AppContext);

    let  detailProps = props.detailProps;
    
    // TODO: just hardcode the tabname to device-detail to make it render at the moment
    const { id, tabname  } =  useParams();

    const navigate = useNavigate();

    const { state } = useLocation();


    let profileType = null;
    let breadCrumbArr: BreadCrumbType[];

    
    let row: any = { id };
    
    let prevPageUrl = null;
    let selectedIds;
    let selectedDevices;
    let selectedFirmware;
    let RFRegion;

    if(state) {
        row = (state.hasOwnProperty("row")) ? state['row'] : getDataFromSessionStorage("row");
        
        prevPageUrl = (state.hasOwnProperty("prevPageUrl")) ? state['prevPageUrl'] : getDataFromSessionStorage("prevPageUrl");

        selectedIds = (state.hasOwnProperty("selectedIds")) ? state['selectedIds'] : getDataFromSessionStorage("selectedIds");

        selectedDevices = (state.hasOwnProperty("selectedDevices")) ? state['selectedDevices'] : getDataFromSessionStorage("selectedDevices");

        selectedFirmware = (state.hasOwnProperty("selectedFirmware")) ? state['selectedFirmware'] : getDataFromSessionStorage("selectedFirmware");

        RFRegion = (state.hasOwnProperty("RFRegion")) ? state['RFRegion'] : getDataFromSessionStorage("RFRegion");
    } else {
        prevPageUrl = getDataFromSessionStorage("prevPageUrl");
        row = getDataFromSessionStorage("row");
        selectedIds =  getDataFromSessionStorage("selectedIds");
        selectedDevices = getDataFromSessionStorage("selectedDevices");
        selectedFirmware = getDataFromSessionStorage("selectedFirmware");
        RFRegion = getDataFromSessionStorage("RFRegion");
    }

    useEffect(() => {
        return function() {
            removeDataFromSessionStorage("prevPageUrl");
            removeDataFromSessionStorage("row");
            removeDataFromSessionStorage("selectedIds");
            removeDataFromSessionStorage("selectedDevices");
            removeDataFromSessionStorage("selectedFirmware");
            removeDataFromSessionStorage("RFRegion");
        }      
    },[]);

    useEffect(() => {
        if(state){
            if(state.hasOwnProperty("prevPageUrl")){
                setDataToSessionStorage("prevPageUrl", state['prevPageUrl'])
            }
            if(state.hasOwnProperty("row")){
                setDataToSessionStorage("row", state['row'])
            }
            if(state.hasOwnProperty("selectedIds")){
                setDataToSessionStorage("selectedIds", state['selectedIds'])
            }
            if(state.hasOwnProperty("selectedDevices")){
                setDataToSessionStorage("selectedDevices", state['selectedDevices'])
            }
            if(state.hasOwnProperty("selectedFirmware")){
                setDataToSessionStorage("selectedFirmware", state['selectedFirmware'])
            } 
            if(state.hasOwnProperty("RFRegion") && state['RFRegion']){
                setDataToSessionStorage("RFRegion", state['RFRegion'])
            }
        }
    },[state]);

    

    if(props.hasOwnProperty('profileType')) {
        profileType = props.profileType;
    }
    

    let breadCrumbArgs = {}
    let actionArgs = {};
    
    if(id) {
        breadCrumbArgs['id'] = id;
    }

    if (tabname) {
        breadCrumbArgs['tabname'] = tabname;
    }
    
    if(prevPageUrl) {
        breadCrumbArgs['prevPageUrl'] = prevPageUrl;
        actionArgs['prevPageUrl'] = prevPageUrl;
    }

    if(selectedDevices) {
        actionArgs['selectedDevices'] = selectedDevices;
    }

    if(selectedFirmware) {
        actionArgs['selectedFirmware'] = selectedFirmware;
    }

    if(RFRegion){
        actionArgs['RFRegion'] = RFRegion
    }
    if(profileType) {
        breadCrumbArgs['profileType'] = profileType;
        actionArgs['profileType'] = profileType;
    }
    
    if(row) {
        breadCrumbArgs['row'] = row;
        actionArgs['row'] = row;
    }
    
    if(selectedIds) {
        actionArgs['selectedIds'] = selectedIds;
    }

    if (tabname) {
        actionArgs['tabname'] = tabname;
    }

    
    let actions: DetailPageActionType[] = [];
    
    breadCrumbArr = detailProps.getBreadCrumbArr(breadCrumbArgs);


    actions = detailProps.getActions(actionArgs, navigate);

    const filteredActions = actions.filter(item => { return (item.icon && item.type === 'tab' && item.content.type === 'Component') ? true : false  })
                                    .filter(item => typeof item.visible === "function" ? item.visible() : true);

    let countLabel = "Items";
    
    try {
        if(filteredActions) {
            let okeys = Object.keys(filteredActions);
            countLabel = filteredActions[okeys[0]]['content']['props']['countLabel'];
           
        }
    }catch(e) {

    }
    
    const data_table_name = `${tabname == "view-data" || tabname == "group-activity" ? `${tabname}-${id}` : tabname}`;
    
    const user = appContextObj?.user || null; 
    const impersonatedClass = (appContextObj.isSignedIn(user) && appContextObj.isForwardedSignIn() && appContextObj.navBarState.forwardedSignIn )? 'child-tab-wrapper-impersonated' : 'child-tab-wrapper'


    const mainClass = (isTableViewTab(tabname)) ? "child-table-wrapper" : impersonatedClass;

    return (<div className={`${mainClass}`} >
                <div className="mx-0 d-lg-flex border-bottom border-1  " > {/* "ow-position-fixed" class removed*/}
                    <div className="col-lg-8 col-md-auto col-xs-12">
                        {breadCrumbArr && <BreadcrumbComponent breadCrumbArr={breadCrumbArr} countLabel={countLabel} name={data_table_name} isChildTable={true} />}
                    </div>
                    <div  className="col-lg-4 col-md-auto col-xs-12 d-flex justify-content-md-end " >
                        <BuildTabs actions={actions} tab={tabname}  id={id} row={row} />
                        <BuildBulkActionTabs actions={actions} tab={tabname}  id={id} row={row} />
                    </div>
                </div> 
                <div className="d-flex flex-column flex-grow border-2 border-primary" style={{height: '100%'}}>
                    <BuildContent actions={actions} tabname={tabname}  id={id}  row={row} profileType={profileType} />
                    <BuildBulkContent actions={actions} tabname={tabname}  id={id}  row={row} profileType={profileType} />
                </div>
        </div>
        )
}

export default DetailPage;


const BuildTabs: React.FC<{actions: DetailPageActionType[], tab: string, id: string, row: any}> = ({ actions, tab, id, row  }) => {
    
   
    const filteredActions = actions.filter(item => { return (item.icon && item.type === 'tab') ? true : false  })
                                    .filter(item => typeof item.visible === "function" ? item.visible() : true);

    if(filteredActions.length > 1) {
        const list = filteredActions.map(function(action, index) {
            
            if(action.key === tab) {
                return (
                    <div className="mx-2" key={index}>
                        <Button className="active d-flex flex-row active" size='sm' variant="white" onClick={() => action.action(row) }>{action.text}</Button>
                    </div>);
            }else {
                return (<div className="mx-2" key={index}>
                            <Button size='sm' className="d-flex flex-row" variant="white" onClick={() =>  action.action(row) }>{action.text}</Button>
                        </div>);
            }
        })
        return (<div className='primary-tabs' style={{display: 'flex', flexDirection: 'row', justifyContent:'start', alignSelf:'end'}}>{list}</div>)
    }else {
        return null;
    }
}


const BuildBulkActionTabs: React.FC<{actions: DetailPageActionType[], tab: string, id: string, row: any}> = ({ actions, tab, id, row  }) => {
    
   
    const filteredActions = actions.filter(item => { return (item.icon && item.type === 'bulk_action_tab') ? true : false  })
                                    .filter(item => typeof item.visible === "function" ? item.visible() : true);

    if(filteredActions.length > 1) {
        const list = filteredActions.map(function(action, index) {
            
            if(action.key === tab) {
                return (
                    <div className="mx-2" key={index}>
                        <Button className="active d-flex flex-row active" size='sm' variant="white" onClick={() => action.action(row) }>{action.text}</Button>
                    </div>);
            }else {
                return null;
                 return (<div className="mx-2" key={index}>
                             <Button size='sm' className="d-flex flex-row" variant="white" onClick={() =>  action.action(row) }>{action.text}</Button>
                         </div>);
                
            }
        })
        return (<div className='primary-tabs' style={{display: 'flex', flexDirection: 'row', justifyContent:'start', alignSelf:'end'}}>{list}</div>)
    }else {
        return null;
    }
}

const BuildBulkContent: React.FC<{actions: DetailPageActionType[], tabname: string, id: string, row: any, profileType: any}> = ({ actions, tabname, id , row , profileType }) => {
    
    const filteredActions = actions.filter(item => { return (item.icon && item.type === 'bulk_action_tab' && item.content.type === 'SchemaModal') ? true : false  })
                                    .filter(item => typeof item.visible === "function" ? item.visible() : true)
    
    const list = filteredActions.map(function(action, index) {
         return (<OutputSchemaModal action={action} tabname={tabname} id={id} key={index} />)
    })

    const filteredDataTables = actions.filter(item => { return (item.icon && item.type === 'bulk_action_tab' && item.content.type === 'Component') ? true : false  })
    
    const tableList = filteredDataTables.map(function(action, index) {
        return (<OutputDataTable action={action} tabname={tabname} id={id} key={index} row={row} profileType={profileType} />)
   })

    return (<div style={{display: 'flex', flexDirection: 'row', justifyContent:'start', height: '100%'}}>{list}{tableList}</div>)
}

const BuildContent: React.FC<{actions: DetailPageActionType[], tabname: string, id: string, row: any, profileType: any}> = ({ actions, tabname, id , row , profileType }) => {
    
    const filteredActions = actions.filter(item => { return (item.icon && item.type === 'tab' && item.content.type === 'SchemaModal') ? true : false  })
                                    .filter(item => typeof item.visible === "function" ? item.visible() : true)
    
    const list = filteredActions.map(function(action, index) {
         return (<OutputSchemaModal action={action} tabname={tabname} id={id} key={index} />)
    })

    const filteredDataTables = actions.filter(item => { return (item.icon && item.type === 'tab' && item.content.type === 'Component') ? true : false  })
    
    const tableList = filteredDataTables.map(function(action, index) {
        return (<OutputDataTable action={action} tabname={tabname} id={id} key={index} row={row} profileType={profileType} />)
   })

    return (<div style={{display: 'flex', flexDirection: 'row', justifyContent:'start', height: '100%'}}>{list}{tableList}</div>)
}

// interface OutputDataTableState {
//     exportInit:number;
// }

const OutputDataTable: React.FC<{action: DetailPageActionType, id: string, tabname: string, row: any, profileType: any}> = ( { action, id, tabname, row, profileType } ) => {
    
//    const navigate = useNavigate();
        // const initState = {
        //     exportInit:0
        // }

    //const [state, setState] = React.useState<OutputDataTableState>(initState);

        
    const getDisplay = ( selected, current ) => {

        if(selected === current) {
            return "d-flex";
        }else {
            return "d-none";
        }
    }
    

    // const exportTable = () => {
        
    //     setState(prevState => {
    //         let exportInit = (prevState.exportInit === 1)  ? 2 : 1;
    //         return {...prevState, exportInit:exportInit}
    //     })
    // }
    //this functino is used to add export button in child data table. child data table does not have parent table
    //detailPage is the parent table of child data table.
    // const mergeExprtButton = ( pageButtons ) => {
    //     let findIndex;
    //     let tempArr = pageButtons.map((btn, index) => {
    //         if(btn.type !== 'export') {
    //             return btn;
    //         }else {
    //             findIndex = index;
    //         }
    //     })
        
    //     const btnProps = {
    //         title: strings.EXPORT_DEVICE_BTN,
    //         action: (  ) => { exportTable() },
    //         type: 'button',
    //         icon: faFileExport
    //     }
    //     tempArr[findIndex] = btnProps;
    //     return tempArr;
    // }

    let disp = getDisplay(action.key, tabname);
    
    //let pageButtons = action.content.pageButtons;
    
    // let tempPageButtons = null;

    // if(action.content.pageButtons) {
    //     tempPageButtons = mergeExprtButton(pageButtons);
    // }else {
    //     tempPageButtons = action.content.pageButtons;
    // }

    
    //let countLabel = 'Items';
    // if(action['content']['props']['countLabel']) {
    //     countLabel = action['content']['props']['countLabel'];
    // }
    const ComponentName = action.content['ComponentName']
    const componentProps = action.content.props

    const RendrTable = ( props ) => {
        if(props.ComponentName === 'DeviceViewDataTable') {
            return (<DeviceViewDataTable   {...componentProps} key="device-view-data-table" />);
        } else if(props.ComponentName === 'DmpDeviceViewDataTable') {
            return (<DmpDeviceViewDataTable   {...componentProps} key="dmp-device-view-data-table" />);
        } else if (props.ComponentName === 'GroupActivityDataTable') {
            return (<GroupActivityDataTable   {...componentProps} key="group-activity-data-table" />);
        } else if (props.ComponentName === 'GatewayJobsViewTable') {
            return (<JobsDataTable   {...componentProps} key="jobs-data-table" />);
        } else {
            return null;
        }

    }
    
    return (
        <div className={`${action.key} ${getDisplay(action.key, tabname)} flex-column flex-grow`} id={action.key} style={{width: '100%'}}> {/* marginTop: '60px' */}
            {disp === 'd-flex' && <RendrTable ComponentName={ComponentName} />}
        </div>
    )

}

const OutputSchemaModal: React.FC<{action: DetailPageActionType, id: string, tabname: string}> = ({ action, id, tabname }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const getDisplay = ( selected, current ) => {

        if(selected === current) {
            return "d-flex";
        }else {
            return "d-none";
        }
    }


    const onChange = (curObject: any, lastObject: any) => {

        if (action.monitorKeys) {

            let change = false;
            for (const key of action.monitorKeys) {
                if (curObject[key] !== lastObject[key]) {
                    change = true;
                }
            }

            if (change && navigate && location) {
                navigate(location.pathname, {state: { row: id ? {...curObject, id } : curObject } })
            }
        }
    }



    let displayProp = getDisplay(action.key, tabname);

    return (
        <div className={`${action.key} ${displayProp} flex-grow`} id={action.key} style={{width: '100%'}}>
            {displayProp === 'd-flex' && <SchemaModal
                SchemaUrl={`${action.content.url}`}
                EditObject={action.EditObject}
                OnChange={onChange}

                loadDataOnOpen={true}
                OnClose={(flag, refreshRequired) => {
                    if(action.content.backUrl) {
                        if(action.content.backUrl === '-1') {
                            navigate(-1);
                            
                        }else {
                            navigate(action.content.backUrl, { replace: false, state: {updatedRow: flag, refreshRequired:refreshRequired}})
                        }
                    }
                    else {

                        navigate(-1);
                    }
                    
                }}
                type="page"
            />}
        </div>
    )
}