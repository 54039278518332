import React, { useContext, useEffect, useState } from "react";
import { strings } from "../../services/Localization";
import "./welcome.css";
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { IConstants } from "src/types";

import OrbiwisePowerLogo from '../../../resources/images/logo-orbiwise-noir-horizontal.png';
import AppContext from "../../context/AppContext";


declare const constants: IConstants;


export function Welcome() {

    const [customPage, setCustomPage] = useState<string>(null);
    const [signedIn, setSignedIn] = useState(false);
    const [ready, setReady] = useState(false);

    const context = useContext(AppContext)


    useEffect(() => {
        async function getEnv() {
            if (context.user?.userid) {
                let customPage = "";
                if (context.user._environment.welcome_page_id) {
                    try {
                        const res = await GenericDassQuery("/rest/users/$?_get_welcome_page=true");
                        const pages = res.data;
                        customPage = pages.welcomePage;
                    } catch (e) {}
                }
                setCustomPage(customPage);
                setSignedIn(true);
            }
            setReady(true)
        }
        getEnv();

    }, []);

    return (
        <div>
            {ready && customPage && <div dangerouslySetInnerHTML={{__html: customPage}} />}
            {ready && !customPage && <div className=" ">

                    {constants.hideWelcomeBox !== true && <div className=" ">
                        <div className="col-md-10 welcomePage position-relative">
                            <div className="row d-flex align-items-center">
                                <div className="col-md-6 px-5">
                                    <WelcomePageContentLeft signedIn={signedIn}/>
                                </div>
                                <div className="col-md-6 d-flex justify-content-center align-items-center ">
                                    <WelcomePageConetentRight />
                                </div>
                            </div>    
                            {!(constants._license & (1<<16)) && <PoweredByV1 />}
                        </div>
                    </div>}

                </div>}
                {constants.hideWelcomeBox === true && !(constants._license & (1<<16)) && <PoweredByV2 />}
        </div>
    );
    
}

const WelcomePageConetentRight = () => {
    return (
        <div>
            {/*constants.welcome_right_side_image !== "" &&
                <img src={constants.welcome_right_side_image || "/images/mock-up.png"} className="img-responsive" alt="" />
            */} 
        <div className="welcomePageRight"></div>
            {constants.welcome_right_side_text !== "" &&
           
                <p className="text_under_mc">{constants.welcome_right_side_text || strings.WELCOME_RIGHT_MESSAGE}</p>
            }
        </div>
    )
                                    
}


const PoweredByV1 =  () => {
    return (
        <div className="position-absolute bottom-0 end-0 pe-3 pb-2 text-end">
            <div title={constants.version_long}>{constants.version}</div>
            <div className="text_center_style">{strings.POWERED_BY}
                <img className="logo_orbiwise" src={OrbiwisePowerLogo} />
            </div>
        </div>
    )
 }

const WelcomePageContentLeft = ( props:any ) => {
    return (
        <div className="welcomePageContent">
            <h1 className="welcomePageTitle">{constants.welcome_title}</h1>
            <p className="welcomePageMessage">{constants.welcome_text}</p>
            {props.signedIn === false && (
                <div>
                    <p>
                        <a className="btn btn-lg welcomePageButtonLearn"
                            href={constants.welcome_link} target="_blank" rel="noopener noreferrer" role="button">{strings.WELCOME_LEARN_MORE}
                        </a>
                    </p>
                </div>
            )}
        </div>
    )
}

const PoweredByV2 = () => {
    return (
        <div className="welcomePowerBy">
            <small>
                {strings.POWERED_BY}
                <img className="logo" src={OrbiwisePowerLogo} />
        </small>
        </div>
    )
}