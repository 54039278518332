import React, { useEffect, useContext, useRef } from "react";
import { strings } from "./../../services/Localization";
import { Button, Modal, ProgressBar } from 'react-bootstrap';
import _ from 'lodash';
import { getAppBase } from "../../utils/consts";
import { DataTable, DataTableWrapper, PageHeader, SpinnerHide, SpinnerShow } from '../../components/Common';
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { BulkActionType } from '../../components/Common/DataTable/DataTypes';
import { DEFAULT_RECORD_LIMIT } from "../../components/Common/DataTable/DataTableConsts";
import {
    getDassDeviceProfilesService,
    getDassServiceProfilesService,
    // getDeviceProfilesService
} from "../../services/batch"
import { toast } from "../../utils/Toaster";

import AppContext from "../../context/AppContext";
import { faCloudArrowDown, faCloudArrowUp, faFileImport, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { createRoot } from "react-dom/client";
import { SchemaModal } from "../../components/SchemaModal/SchemaModal";
import { useNavigate } from "react-router";
import { DataTableContext } from "../../components/Common/DataTable/DataTableState";
import { DeviceImportHelper } from "./DeviceImportHelper";
import { FileDropZone } from "./FileUploadZone";

interface IPageState {
    user: any;
    showDeviceList: boolean;
    pageTitle: string;
    breadCrumbArr: BreadCrumbType[];
    nameIndex: number;
    allSelected: boolean;
    limit: number;
    page: number;
    totalPage: number;
}

const DeviceImport = () => {
    const navigate = useNavigate();
    const appContextObj = useContext(AppContext);
    const loggedInUserId = appContextObj?.user?.userid || "";

    const [showOrbiWANInstanceDropDown, setShowOrbiWANInstanceDropDown] = React.useState(false);

    const orbiwan_instance = React.useRef(null);
    const typeOfDevicesBeingImported = React.useRef(null); // dass, file-upload-provisioned, file-upload-dass, file-upload-normal
    const deviceProfiles = React.useRef([]);
    const connections = React.useRef([]);
    const dataTableOptions = React.useRef(null);
    const allDevices = React.useRef([]);
    const allDevicesBroughtFromDASS = React.useRef(false);

    // const [orbiwanInstances, setOrbiwanInstances] = React.useState([]);

    const [showDataTable, setShowDataTable] = React.useState(false);

    const [showDeviceProfileDropDown, setShowDeviceProfileDropDown] = React.useState(false);
    const [showConnectionsDropDown, setShowConnectionsDropDown] = React.useState(false);

    const [selectedDevices, setSelectedDevices] = React.useState([]);
    const [showFileImportBox, setShowFileImportBox] = React.useState(false);

    const [dassServiceProfiles, setDassServiceProfiles] = React.useState([]);
    const [dassDeviceProfiles, setDassDeviceProfiles] = React.useState([]);

    const [deviceProfilesFetched, setDeviceProfilesFetched] = React.useState(false);
    const [serviceProfilesFetched, setServiceProfilesFetched] = React.useState(false);

    // current columns
    const columnsFromUploadedFile = React.useRef([]);

    const orbiwanInstances = appContextObj?.user ? appContextObj.user['orbiwan_creds'] ? appContextObj.user['orbiwan_creds'] : [] : [];

    const isLogin = appContextObj?.user && appContextObj.user.loginid ? true : false;

    const PageStateInit: IPageState = {
        user: {},
        pageTitle: '',
        showDeviceList: false,
        allSelected: true,
        limit: 50,
        page: 0,
        totalPage: 0,
        breadCrumbArr: [
            { label: strings.NAV_DEVICES_ITEM, url: `${getAppBase()}/dmp-devices` },
            { label: strings.DEVICE_IMPORT, url: '' }
        ],
        nameIndex: 0,
    }


    const selectOrbiWANInstanceHandler = (instanceName, overwriteDevicetypeBeingImported = true) => {
        orbiwan_instance.current = instanceName;
        if (overwriteDevicetypeBeingImported) {
            typeOfDevicesBeingImported.current = 'dass';
            allDevices.current = [];
        }

        setShowOrbiWANInstanceDropDown(false);
    }

    const getDassBulkActions = () => {

        let dassBulkActions: BulkActionType[] = [
            // set Device Profile
            {
                type: "action",
                text: strings.SET_DEVICE_PROFILE,
                icon: faFileImport,
                action: (data) => {
                    setShowDeviceProfileDropDown(true);
                    setSelectedDevices(data);
                },
            },
            {
                type: "action",
                text: strings.SET_CONNECTION,
                icon: faFileImport,
                action: (data) => {
                    setShowConnectionsDropDown(true);
                    setSelectedDevices(data);
                },
                visible: () => typeOfDevicesBeingImported.current === 'file-upload-provisioned' || typeOfDevicesBeingImported.current === 'file-upload-normal'
            }
        ];

        return dassBulkActions;
    }

    const registerDevices = async () => {
        const tableData = DataTableContextObj.searchState.selectedRowIds;

        if (tableData.length === 0) {
            toast.error(strings.NO_DEVICE_SELECTED);
            return;
        }

        // get devices which have a dmp_device_profile_uuid set
        const devicesToRegister = [];
        // const tableData = dataTableOptions.current.data;
        let devicesAlreadyInDMP = 0;
        for (let i = 0; i < tableData.length; i++) {
            const row = tableData[i];
            if (typeOfDevicesBeingImported.current === 'file-upload-provisioned') {
                if (row.dmp_device_profile_uuid && !row.already_in_dmp && row.deviceUuid && row.provisionToken) {
                    devicesToRegister.push(row);
                }
            } else if (typeOfDevicesBeingImported.current === 'file-upload-normal') {
                if (row.dmp_device_profile_uuid && !row.already_in_dmp && row.name) {
                    devicesToRegister.push(row);
                }
            } else if (row.dmp_device_profile_uuid && !row.already_in_dmp && row.device_profile_uuid && row.service_profile_uuid) {
                devicesToRegister.push(row);
            }
            if (row.already_in_dmp) {
                devicesAlreadyInDMP++;
            }
        }

        if (devicesAlreadyInDMP > 0 && tableData.length === devicesAlreadyInDMP) {
            toast.success(strings.ALL_DEVICES_REGISTERED_ALREADY);
            return;
        }

        let counts = {
            total: devicesToRegister.length,
            registered: 0,
            failed: 0
        }
        let paramToCheck = typeOfDevicesBeingImported.current === 'file-upload-provisioned' ? 'deviceUuid' :
            (typeOfDevicesBeingImported.current === 'file-upload-normal' ? 'name' : 'deveui');
        if (devicesToRegister.length > 0) {
            // call the service to register the devices
            // /rest/devices
            for (let i = 0; i < devicesToRegister.length; i++) {
                const device = devicesToRegister[i];
                const profile = deviceProfiles.current.find((item) => item.profile_uuid === device.dmp_device_profile_uuid);
                const deviceObj: any = {
                    "description": "",
                    "device_profile_uuid": device.dmp_device_profile_uuid,
                    "notes": "",
                    "parameters": {},
                }

                if (typeOfDevicesBeingImported.current === 'file-upload-provisioned' || typeOfDevicesBeingImported.current === 'file-upload-normal') {
                    if (typeOfDevicesBeingImported.current !== 'file-upload-normal') {
                        deviceObj['provisionToken'] = device.provisionToken;
                        deviceObj['deviceUuid'] = device.deviceUuid;
                    }
                    deviceObj['deviceId'] = device.deviceId || '';
                    deviceObj['name'] = device.name || 'Imported';
                    if (device.dmp_connection_uuid) {
                        deviceObj['connection_uuid'] = device.dmp_connection_uuid;
                    }
                    // if bearaerToken is present, add it to the deviceObj otherwise check for username and password
                    if (device.bearerToken) {
                        deviceObj['tokens'] = [device.bearerToken];
                    } else if (device.username && device.password) {
                        deviceObj['tokens'] = [device.username + device.password];
                    }

                    if (device.certificate) {
                        deviceObj['set_certificate'] = device.certificate;
                    }

                    deviceObj.description = device.description || '';
                    deviceObj.notes = device.notes || '';
                    deviceObj.latitude = +device.latitude || undefined;
                    deviceObj.longitude = +device.longitude || undefined;
                    deviceObj.groups = device.groups || undefined;
                    deviceObj.global_tags = device.global_tags || undefined;
                    deviceObj.suspended = device.suspended === 'true' ? true : false;
                    // deviceObj.parameters = device.parameters || {};


                } else {
                    // add service and device profiles, name
                    deviceObj['name'] = device.comment ? 'Imported - ' + device.comment : 'Imported - ' + device.deveui;
                    deviceObj['deviceId'] = device.deveui;
                    deviceObj['lns_device_profile_uuid'] = device.device_profile_uuid;
                    deviceObj['lns_service_profile_uuid'] = device.service_profile_uuid;

                    deviceObj.parameters = {};
                    for (let key in profile.parametersSchema?.properties || {}) {
                        if (key === 'device_class') {
                            deviceObj.parameters['device_class'] = device['lora_device_class'];
                        } else {
                            if (device[key]) {
                                deviceObj.parameters[key] = device[key];
                            }
                        }
                    }
                }

                deviceObj['imported_device'] = true;
                deviceObj['orbiwan_instance'] = orbiwan_instance.current ? orbiwan_instance.current : '';

                const res = await DeviceImportHelper.saveDevice(deviceObj);
                if (res.error) {
                    counts.failed++;
                } else {
                    counts.registered++;
                }
                let percent = ((i + 1) * 100 / devicesToRegister.length).toFixed(1);
                showProgress(percent, `Registering Device ${i + 1} / ${devicesToRegister.length} - ${device[paramToCheck]}`);
            }
            setTimeout(() => {
                // const msg = counts.registered > 0 ? counts.registered + ' ' + strings.DEVICES_REGISTERED : strings.;
                if (counts.failed === 0) {
                    toast.success(devicesToRegister.length + ' ' + strings.DEVICES_REGISTERED);
                } else {
                    let msg = counts.registered > 0 ? counts.registered + ' ' + strings.DEVICES_REGISTERED + '.' : '';
                    msg += counts.failed > 0 ? strings.DEVICE_REGISTRATION_FAILED + ' - ' + counts.failed : '';
                    toast.error(msg);
                }
                hideProgress();
                if (typeOfDevicesBeingImported.current !== 'dass') {
                    buildDataTable();
                } else {
                    allDevicesBroughtFromDASS.current = false;
                    bringDevicesFromDASS();
                }
            }, 1000);

        } else {
            // if(devicesAlreadyInDMP > 0) {
            //     toast.success(strings.ALL_DEVICES_REGISTERED_ALREADY);
            // } else{
            toast.error(strings.NO_DEVICE_TO_REGISTER);
            // }
        }

    }

    let DataTableContextObj = useContext(DataTableContext);

    const restartAgainHandler = () => {
        dataTableOptions.current = null;
        DataTableContextObj.setSearchState(prev => {
            prev.dataTableOption = {
                data: []
            };
            return prev;
        });
        // clear all the data
        orbiwan_instance.current = null;
        typeOfDevicesBeingImported.current = null;
        allDevices.current = [];
        // deviceProfiles.current = [];
        // setOrbiwanInstances([]);
        setShowFileImportBox(false);
        setShowOrbiWANInstanceDropDown(false);
        setDeviceProfilesFetched(false);
        setServiceProfilesFetched(false);
        setDassDeviceProfiles([]);
        setDassServiceProfiles([]);
        setShowDataTable(false);
        setSelectedDevices([]);
        setShowDeviceProfileDropDown(false);
        setShowFileImportBox(false);
    }

    const processDassDevices = async (devices) => {
        // the devices brought from OrbiWAN may already be in the system
        // extract all deveuis 
        const devicesMap = {};
        const deveuis = devices.map((item) => {
            devicesMap[item.deveui] = item;
            return item.deveui;
        });

        // lets get all of the devices from the system
        const res = await DeviceImportHelper.searchDevicesWithPagination();
        if (res && !res.error) {
            const devicesList = res.data;
            const deveuisInSystem = devicesList.map((item) => item.deviceId);
            for (let i = 0; i < deveuis.length; i++) {
                const deveui = deveuis[i];
                if (deveuisInSystem.includes(deveui)) {
                    devicesMap[deveui].already_in_dmp = true;
                    devicesMap[deveui].dmp_device_profile_uuid = devicesList[deveuisInSystem.indexOf(deveui)].device_profile_uuid;
                } else {
                    devicesMap[deveui].already_in_dmp = false;
                }
            }
        }
    }

    const processDevicesForProfiles = async (devices) => {
        // for each device, update the device profile and service profile
        const dassDeviceProfilesMap = {};
        const dassServiceProfilesMap = {};
        for (let i = 0; i < dassDeviceProfiles.length; i++) {
            const profile = dassDeviceProfiles[i];
            dassDeviceProfilesMap[profile.profile_uuid] = profile;
        }
        for (let i = 0; i < dassServiceProfiles.length; i++) {
            const profile = dassServiceProfiles[i];
            dassServiceProfilesMap[profile.profile_uuid] = profile;
        }
        const deviceProfileMap = {};
        for (let i = 0; i < deviceProfiles.current.length; i++) {
            const profile = deviceProfiles.current[i];
            deviceProfileMap[profile.profile_uuid] = profile;
        }
        const connectionsMap = {};
        for (let i = 0; i < connections.current.length; i++) {
            const connection = connections.current[i];
            connectionsMap[connection.connection_uuid] = connection;
        }

        for (let i = 0; i < devices.length; i++) {
            const device = devices[i];
            const device_profile_uuid = device.device_profile_uuid || '';
            const service_profile_uuid = device.service_profile_uuid || '';
            if (device_profile_uuid) {
                device.dass_device_profile_name = dassDeviceProfilesMap[device_profile_uuid]?.profile_name || '';
            } else {
                device.dass_device_profile_name = '';
            }
            if (service_profile_uuid) {
                device.dass_service_profile_name = dassServiceProfilesMap[service_profile_uuid]?.profile_name || '';
            } else {
                device.dass_service_profile_name = '';
            }
            if (device.dmp_device_profile_uuid) {
                device.dmp_device_profile_name = deviceProfileMap[device.dmp_device_profile_uuid]?.profile_name || '';
            } else {
                device.dmp_device_profile_name = 'Not Set';
            }
            if (device.dmp_connection_uuid) {
                device.dmp_connection_name = connectionsMap[device.dmp_connection_uuid]?.profile_name || '';
            } else {
                device.dmp_connection_name = 'Not Set';
            }
        }
    }

    const processProvisionedDevices = async (devices, areProvisionedDevices) => {
        const deviceProfileMap = {};
        for (let i = 0; i < deviceProfiles.current.length; i++) {
            const profile = deviceProfiles.current[i];
            deviceProfileMap[profile.profile_uuid] = profile;
        }
        const connectionsMap = {};
        for (let i = 0; i < connections.current.length; i++) {
            const connection = connections.current[i];
            connectionsMap[connection.connection_uuid] = connection;
        }
        if (typeOfDevicesBeingImported.current === 'file-upload-normal') {
            devices.map((item) => {
                item.already_in_dmp = false;
            });
        } else {
            // the devices brought from OrbiWAN may already be in the system
            const parameterToCheck = areProvisionedDevices ? 'deviceUuid' : 'deveui';
            // extract all deveuis 
            const devicesMap = {};
            const deviceUuids = devices.map((item) => {
                devicesMap[item[parameterToCheck]] = item;
                return item[parameterToCheck];
            });

            // lets get all of the devices from the system
            const res = await DeviceImportHelper.searchDevicesWithPagination();
            if (res && !res.error) {
                const devicesList = res.data;
                const devicesInSystem = devicesList.map((item) => item.deviceUuid);
                for (let i = 0; i < deviceUuids.length; i++) {
                    const deviceUuid = deviceUuids[i];
                    devicesMap[deviceUuid].already_in_dmp = false;
                    if (devicesInSystem.includes(deviceUuid)) {
                        devicesMap[deviceUuid].already_in_dmp = true;
                        devicesMap[deviceUuid].dmp_device_profile_uuid = devicesList[devicesInSystem.indexOf(deviceUuid)].device_profile_uuid;
                        devicesMap[deviceUuid].dmp_device_profile_name = deviceProfileMap[devicesList[devicesInSystem.indexOf(deviceUuid)].device_profile_uuid]?.profile_name || '';

                        // connections
                        devicesMap[deviceUuid].dmp_connection_uuid = devicesList[devicesInSystem.indexOf(deviceUuid)].connection_uuid;
                        devicesMap[deviceUuid].dmp_connection_name = connectionsMap[devicesList[devicesInSystem.indexOf(deviceUuid)].connection_uuid]?.profile_name || '';
                    }
                }
            }
        }
    }

    const getTableColumns = () => {
        if (typeOfDevicesBeingImported.current === 'file-upload-provisioned') {
            return DeviceImportHelper.provisionedDevicesColumns;
        } else if (typeOfDevicesBeingImported.current === 'file-upload-dass' || typeOfDevicesBeingImported.current === 'dass') {
            return DeviceImportHelper.dassDeviceColumns;
        } else if (typeOfDevicesBeingImported.current === 'file-upload-normal') {
            return DeviceImportHelper.normalDevicesColumns;
        } else {
            return DeviceImportHelper.provisionedDevicesColumns;
        }
    }


    const buildDataTable = async () => {
        let areProvisionedDevices = typeOfDevicesBeingImported.current === 'file-upload-provisioned' || typeOfDevicesBeingImported.current === 'file-upload-normal';
        if (!areProvisionedDevices) {
            // check if the devices are already in the system
            await processDassDevices(allDevices.current);
            await processDevicesForProfiles(allDevices.current);
        } else {
            await processProvisionedDevices(allDevices.current, areProvisionedDevices);
            await processDevicesForProfiles(allDevices.current);
        }

        dataTableOptions.current = {
            columns: getTableColumns(),
            data: allDevices.current,
            actions: [],
            bulkActions: getDassBulkActions(),
            available_key: areProvisionedDevices ? 'deviceUuid' : 'deveui',
            id_field: areProvisionedDevices ? 'deviceUuid' : 'deveui',
            enableDebug: true,
            dataType: 'memory',
            allCheckboxSelected: true,
            allowBulkActions: true,
            serial_number: false,
            url: 'default-url',
            modal: false,
            emptyDataMsg: `<b>${strings.SORRY}!</b> ${strings.NO_DEVICE_AVAILABLE}`,
            query_param: { all: true, get_pages: true, limit: DEFAULT_RECORD_LIMIT, stream: 'memory' } as any,
            selectedDevices: selectedDevices,
        }
        setShowDataTable(true);
        setPageState(prevState => {
            const nameIndex = prevState.nameIndex + 1;
            return { ...prevState, nameIndex: nameIndex }
        });
    };


    const updateDevicesData = ({
        device_profile_uuid = undefined,
        connection_uuid = undefined
    }) => {

        if (device_profile_uuid) {
            if (typeOfDevicesBeingImported.current === 'file-upload-dass') {
                // extract the parameters from device profile
                const profile = deviceProfiles.current.find((item) => item.profile_uuid === device_profile_uuid);
                const parameters = Object.keys(profile.parametersSchema?.properties || {});
                // check if columns from csv has all the parameters
                const missingColumns = [];
                for (let i = 0; i < parameters.length; i++) {
                    if (!columnsFromUploadedFile.current.includes(parameters[i])) {
                        if (parameters[i] === 'device_class' && columnsFromUploadedFile.current.includes('lora_device_class')) {
                            continue;
                        }
                        // check if this is a mandatory parameter
                        missingColumns.push(parameters[i]);
                        // return;
                    }
                }

                if (missingColumns.length > 0) {
                    const requiredParameters = profile.parametersSchema?.required || [];
                    const missingRequiredColumns = [];
                    for (let i = 0; i < requiredParameters.length; i++) {
                        if (!columnsFromUploadedFile.current.includes(requiredParameters[i])) {
                            missingRequiredColumns.push(requiredParameters[i]);
                        }
                    }
                    toast.error(`${strings.MISSING_COLUMNS_CSV} - ${missingColumns.join(', ')}`);
                    return;
                }
            }
        }

        const paramToCheck = typeOfDevicesBeingImported.current === 'dass' || typeOfDevicesBeingImported.current === 'file-upload-dass' ? 'deveui' : 'deviceUuid';
        const selectedDevicesForBulk = selectedDevices.map((item) => item[paramToCheck]);
        for (let i = 0; i < allDevices.current.length; i++) {
            if (selectedDevicesForBulk.includes(allDevices.current[i][paramToCheck])) {
                if (device_profile_uuid) {
                    allDevices.current[i].dmp_device_profile_uuid = device_profile_uuid;
                }
                if (connection_uuid) {
                    allDevices.current[i].dmp_connection_uuid = connection_uuid;
                }
            }
        }
        buildDataTable();
    }

    const getDeviceProfiles = async () => {
        // call the service to get the device profiles
        // /rest/device-profiles
        // const res = await getDeviceProfilesService();
        // const validProfiles = [];
        // for (let i = 0; i < res.length; i++) {
        //     const profileContent = typeof res[i].profileContent === 'string' ? JSON.parse(res[i].profileContent) : res[i].profileContent;
        //     res[i].profileContent = profileContent;
        //     validProfiles.push(res[i]);
        // }
        // deviceProfiles.current = validProfiles;

        const res = await DeviceImportHelper.getDeviceProfilesWithPagination();
        if (!res.error) {
            deviceProfiles.current = res.data;
        }
    }

    const getConnections = async () => {
        // call the service to get the device profiles
        // /rest/device-profiles
        const res = await DeviceImportHelper.getConnectionsWithPagination();
        if (!res.error) {
            connections.current = res.data;
        }
    }

    const bringDevicesFromDASS = async () => {
        if (!allDevicesBroughtFromDASS.current) {
            SpinnerShow();
            try {
                const data = await DeviceImportHelper.getDevicesFromDass(orbiwan_instance.current);
                allDevices.current = data.rows;
                allDevicesBroughtFromDASS.current = true;
                await buildDataTable();
            } catch (error) {
                console.log(`Error getting devices from DASS`, error);
                toast.error(`${error.message}`);
                orbiwan_instance.current = null;
                setDassDeviceProfiles([]);
                setDassServiceProfiles([]);
                setDeviceProfilesFetched(false);
                setServiceProfilesFetched(false);
            }
            SpinnerHide();
        }
    };

    const bringProfilesFromDASS = async () => {
        // get the device profiles and service profiles from OrbiWAN
        try {
            const res = await getDassDeviceProfilesService(orbiwan_instance.current);
            if (res.status !== 200) {
                toast.error(`${res['message']}`);
                return;
            }
            setDassDeviceProfiles(res.data);
            setDeviceProfilesFetched(true);
        } catch (error) {
            console.log(`Error getting device profiles`, error);
            toast.error(`${error.message}`);
        }

        try {
            const res = await getDassServiceProfilesService(orbiwan_instance.current);
            if (res.status !== 200) {
                toast.error(`${res['message']}`);
                return;
            }
            const serviceProfiles = res.data
            setDassServiceProfiles(serviceProfiles);
            setServiceProfilesFetched(true);
        } catch (error) {
            console.log(`Error getting service profiles`, error);
            toast.error(`${error.message}`);
        }
    }

    const showOrbiWANInstance = (overwriteDevicetypeBeingImported = true) => {
        // show a dropdown with the available OrbiWAN instances
        allDevicesBroughtFromDASS.current = false;
        if (orbiwanInstances) {
            if (orbiwanInstances.length > 1) {
                // show modal with dropdown with list of orbiwan instances
                setShowOrbiWANInstanceDropDown(true);
                if (overwriteDevicetypeBeingImported) {
                    typeOfDevicesBeingImported.current = 'dass';
                }
            } else if (orbiwanInstances.length === 1) {
                // bring devices from dass
                orbiwan_instance.current = orbiwanInstances[0].instance_name;
                if (overwriteDevicetypeBeingImported) {
                    typeOfDevicesBeingImported.current = 'dass';
                    allDevices.current = [];
                    if (dassDeviceProfiles.length === 0 && dassServiceProfiles.length === 0) {
                        bringProfilesFromDASS();
                    }
                } else if (typeOfDevicesBeingImported.current === 'file-upload-dass') {
                    bringProfilesFromDASS();
                }
            } else {
                toast.error(isLogin ? strings.NO_ORBIWAN_INSTANCE_FOR_LOGIN : strings.NO_ORBIWAN_INSTANCE);
                orbiwan_instance.current = null;
            }
        } else {
            toast.error(isLogin ? strings.NO_ORBIWAN_INSTANCE_FOR_LOGIN : strings.NO_ORBIWAN_INSTANCE);
            orbiwan_instance.current = null;
        }
    }

    useEffect(() => {
        if (orbiwan_instance.current) {
            if (dassDeviceProfiles.length === 0 && dassServiceProfiles.length === 0 && !deviceProfilesFetched && !serviceProfilesFetched) {
                bringProfilesFromDASS();
            } else {
                if (typeOfDevicesBeingImported.current === 'dass') {
                    bringDevicesFromDASS();
                } else {
                    buildDataTable();
                }
            }
        }
    }, [orbiwan_instance.current, dassDeviceProfiles, dassServiceProfiles])

    // get the current user from the appContext and trigger the reload of the page.
    useEffect(() => {

        setPageState(prevState => {
            return { ...prevState, user: appContextObj?.user }
        });

        if (!deviceProfiles.current || deviceProfiles.current.length === 0) {
            getDeviceProfiles();
        }

        if (!connections.current || connections.current.length === 0) {
            getConnections();
        }

    }, []);
    // ------------------------------------------------------------------------------------------------
    // ------------------------------------------------------------------------------------------------
    // ------------------------------------------------------------------------------------------------



    const [pageState, setPageState] = React.useState<IPageState>(PageStateInit);

    const getPageButtons = () => {

        const pageButtons: PageButtonType[] = [
            // refresh table
            {
                title: strings.RESTART,
                action: () => { restartAgainHandler() },
                type: 'button_with_icon',
                icon: faRefresh,
                visible: () => true
            },

            // import from dass
            {
                title: strings.IMPORT_FROM_DASS,
                action: () => { showOrbiWANInstance() },
                type: 'button_with_icon',
                icon: faCloudArrowDown,
                visible: () => !showDataTable
            },

            // Upload file
            {
                title: strings.UPLOAD_FILE,
                action: () => {
                    dataTableOptions.current = null;
                    orbiwan_instance.current = null;
                    allDevices.current = [];
                    dataTableOptions.current = null;

                    DataTableContextObj.setSearchState(prev => {
                        prev.dataTableOption = {};
                        return prev;
                    });
                    setDeviceProfilesFetched(false);
                    setServiceProfilesFetched(false);
                    setDassDeviceProfiles([]);
                    setDassServiceProfiles([]);
                    setShowFileImportBox(true);
                },
                type: 'button_with_icon',
                icon: faCloudArrowUp,
                visible: () => !showDataTable
            },
            // register devices
            {
                title: strings.REGISTER,
                action: () => { registerDevices() },
                type: 'button_with_icon',
                icon: faFileImport,
                visible: () => showDataTable
            },
        ]

        return pageButtons;
    };

    // Global variable to hold the root instance
    let rootInstance = useRef(null);

    const showProgress = (complete, caption = 'uploading') => {
        // Only create the root instance once
        if (!rootInstance.current) {
            const progressBarElement = document.getElementById('progress-bar');
            if (progressBarElement) {
                rootInstance.current = createRoot(progressBarElement);
            }
        }

        // Render the progress bar component
        if (rootInstance.current) {
            rootInstance.current.render(<ProgressBarComponent value={complete} caption={caption} />);
        }
    };

    const hideProgress = () => {
        if (rootInstance.current) {
            rootInstance.current.render(null); // Render null to hide the component
        }
    };

    const uploadFile = (file) => {
        // set orbitwan_instance to null
        orbiwan_instance.current = null;
        allDevices.current = [];
        // setOrbiwanInstances([]);

        const reader = new FileReader();
        reader.onload = (event) => {
            try {
                const allowedFormats = ['csv', 'txt', 'json', 'jsonl']; // Allowed file formats
                const fileExtension = file.name.split('.').pop().toLowerCase();

                if (!allowedFormats.includes(fileExtension)) {
                    toast.error('Only CSV file format (.csv) are allowed.');
                    return;
                }

                // process the file
                const data = processUploadedFile(event.target.result, fileExtension);
                if (!data.error) {
                    if (data.fileTypeBeingImported === 'file-upload-dass') {
                        // show the orbiwan instance dropdown
                        showOrbiWANInstance(false);
                    } else {
                        // build the data table
                        buildDataTable();
                    }
                } else {
                    toast.error(data.message);
                }

            } catch (error) {
                console.log(`Error processing file`, error);
                toast.error(strings.ERROR_PROCESSING_FILE);
            }
        };
        reader.readAsText(file);
    };



    const processUploadedFile = (fileContent, fileExtension) => {
        const data = {
            rows: [],
            columnsHeader: [],
            fileTypeBeingImported: null,
            error: false,
            message: ''
        };

        // Split by newline, but handle multiline fields
        const rows = [];
        let currentRow = '';
        let insideQuotes = false;

        if (fileExtension === 'json') {
            try {
                const devicesJson = JSON.parse(fileContent);
                for (const devJson of devicesJson) {
                    rows.push(devJson);
                }
            } catch (error) {
                console.log(error);
                toast.error(strings.ERROR_PROCESSING_FILE);
            }
        } else {
            // Split fileContent into lines but handle quotes correctly
            fileContent.split('\n').forEach((line) => {

                if (fileExtension === 'jsonl') {
                    try {
                        if (line.trim().length > 0) {
                            const devJson = JSON.parse(line);
                            rows.push(devJson);
                        }
                    } catch (error) {
                        console.log(error);
                        toast.error(strings.ERROR_PROCESSING_FILE);
                    }
                } else {
                    // Check if we are inside a quoted field
                    const quoteCount = (line.match(/"/g) || []).length;
                    if (insideQuotes) {
                        currentRow += '\n' + line; // Append line to current row if inside a quote
                        if (quoteCount % 2 !== 0) {
                            // We are exiting the quoted field, so push the row and reset
                            rows.push(currentRow);
                            currentRow = '';
                            insideQuotes = false;
                        }
                    } else {
                        if (quoteCount % 2 !== 0) {
                            // Starting a multiline field
                            currentRow = line;
                            insideQuotes = true;
                        } else {
                            // Normal row, push to rows
                            rows.push(line);
                        }
                    }
                }
            });

            if (insideQuotes) {
                throw new Error('Malformed CSV: Unclosed quotes detected');
            }

        }

        // Extract headers from the first row
        const columnsHeader = [];
        if (fileExtension === 'csv' || fileExtension === 'txt') {
            const tempCols = rows[0].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/); // Split by commas, but ignore those inside quotes
            for (let i = 0; i < tempCols.length; i++) {
                const col = tempCols[i].trim().replace(/^"|"$/g, ''); // Remove surrounding quotes if present
                columnsHeader.push(DeviceImportHelper.sanitizeValue(col));
            }
        } else {
            const columnsSet = new Set();
            for (let i = 0; i < rows.length; i++) {
                const jsonProps = Object.keys(rows[i]);
                for (let j = 0; j < jsonProps.length; j++) {
                    columnsSet.add(jsonProps[j]);
                }
            }
            for (const col of Array.from(columnsSet)) {
                columnsHeader.push(DeviceImportHelper.sanitizeValue(col));
            }
        }

        columnsFromUploadedFile.current = columnsHeader;

        let areDassDevices = columnsHeader.includes('deveui');

        if (areDassDevices) {
            typeOfDevicesBeingImported.current = 'file-upload-dass';
        } else {
            typeOfDevicesBeingImported.current = 'file-upload-provisioned';
        }

        let devicesBeingImported = areDassDevices ? 'file-upload-dass' : 'file-upload-provisioned';

        if (areDassDevices) {
            // Check if all mandatory columns are present
            for (let i = 0; i < DeviceImportHelper.mandatoryColumns.dassDevices.length; i++) {
                if (!columnsHeader.includes(DeviceImportHelper.mandatoryColumns.dassDevices[i])) {
                    data.error = true;
                    // data.message = strings.MISSING_MANDATORY_COLUMNS + ` - ${DeviceImportHelper.mandatoryColumns.dassDevices[i]}`;
                    // return data;
                }
            }
        } else {

            // there can be two valid combinations of columns
            // 1. deviceUuid, provisionToken
            // 2. name but deviceUuid and provisionToken are both missing
            let validColumns = false;
            // Check if all mandatory columns are present
            if (columnsHeader.includes('deviceUuid') && columnsHeader.includes('provisionToken')) {
                validColumns = true;
            } else if (columnsHeader.includes('name') && !columnsHeader.includes('deviceUuid') && !columnsHeader.includes('provisionToken')) {
                validColumns = true;
                devicesBeingImported = 'file-upload-normal';
            }
            if (!validColumns) {
                data.error = true;
                // data.message = strings.MISSING_MANDATORY_COLUMNS + ` - deviceUuid, provisionToken or name`;
                // return data;
            }
        }

        const dassColumnsStr = ['deveui', 'device_profile_uuid', 'service_profile_uuid', 'lora_device_class'].join(', ');
        
        if(data.error === true) {
            data.message = strings.MISSING_MANDATORY_COLUMNS + `For OrbiWAN devices - ${dassColumnsStr}. For other devices - deviceUuid, provisionToken or name.`;
            return data;
        }

        const start = fileExtension === 'csv' || fileExtension === 'txt' ? 1 : 0;
        for (let i = start; i < rows.length; i++) {
            if ((fileExtension === 'csv' || fileExtension === 'txt') && rows[i].length === 0) {
                continue;
            }
            // Split the row by commas, but ignore commas inside quoted fields
            const row = (fileExtension === 'csv' || fileExtension === 'txt') ? rows[i].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/) : rows[i];
            const rowObj = {};

            for (let j = 0; j < columnsHeader.length; j++) {
                // Remove any surrounding quotes from the value and sanitize it
                let value = (fileExtension === 'csv' || fileExtension === 'txt') ? row[j].trim().replace(/^"|"$/g, '') : row[columnsHeader[j]];
                value = value ? DeviceImportHelper.sanitizeValue(value) : undefined;

                if (!areDassDevices && DeviceImportHelper.normalDevicesColumnNames.includes(columnsHeader[j])) {
                    rowObj[columnsHeader[j]] = value || '';
                } else if (areDassDevices && DeviceImportHelper.dassDevicesColumnNames.includes(columnsHeader[j])) {
                    rowObj[columnsHeader[j]] = value || '';
                }

                // Special case for lora_device_class
                if (columnsHeader[j] === 'lora_device_class') {
                    rowObj[columnsHeader[j]] = DeviceImportHelper.lora_classes[value] || '';
                }
            }

            // if no appkey is present, set it to empty string
            // rowObj['appkey'] = rowObj['appkey'] || undefined;
            data.rows.push(rowObj);
        }

        // Set columns and device type information
        data.columnsHeader = columnsHeader;
        data.fileTypeBeingImported = devicesBeingImported;
        allDevices.current = data.rows;
        typeOfDevicesBeingImported.current = devicesBeingImported;

        return data;
    };


    const filteredDeviceProfiles = deviceProfiles.current.filter((item) => {

        if (typeOfDevicesBeingImported.current === 'file-upload-provisioned' || typeOfDevicesBeingImported.current === 'file-upload-normal') {
            if (item.profileContent?.deviceType !== 'orbiwan') {
                return true;
            }
            return false;
        } else {
            if (item.profileContent?.deviceType === 'orbiwan') { // && item.profileContent?.orbiwanInstance === orbiwan_instance.current) {
                return true;
            }
            return false;
        }
    });

    const deviceProfileDropdownOptions = filteredDeviceProfiles.map((item) => (
        <option key={item.profile_uuid} value={item.profile_uuid}>{item.profile_name}</option>
    ));

    const connectionsDropdownOptions = connections.current.map((item) => (
        <option key={item.profile_uuid} value={item.profile_uuid}>{item.profile_name}</option>
    ));

    return (
        !loggedInUserId ? <div /> :
            <>

                <DataTableWrapper>
                    <PageHeader
                        name={`device-import-${pageState.nameIndex}`}
                        breadCrumbArr={pageState.breadCrumbArr}
                        pageButtons={getPageButtons()}
                        countLabel={`Devices`} >

                    </PageHeader>
                    {
                        showDataTable && <div id="progress-bar" className="px-2" style={{ height: '1rem' }}></div>
                    }
                    {
                        showDataTable && dataTableOptions.current &&
                        <div
                            // style={{
                            //     height: 'calc(100% - 1rem)',
                            // }}
                        >
                            <DataTable
                                key={`device-import-${pageState.nameIndex}`}
                                name={`Device Import From OrbiWAN`}
                                dataTableOption={dataTableOptions.current}
                                display={showDataTable ? 'block' : 'none'}
                                countLabel={`Devices`}
                                refresh={true}
                            />
                        </div>
                    }
                    {
                        showFileImportBox && (
                            <div
                                // style={{
                                //     height: 'calc(100% - 1rem)',
                                // }}
                            >
                                <div className="mx-0 d-lg-flex border-bottom border-2 mb-2 " >
                                    <div className="d-flex w-100 align-items-center justify-content-center p-2" >
                                        <FileDropZone uploadFile={uploadFile} />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        (!showDataTable && !showFileImportBox && !dataTableOptions.current) && (
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <SchemaModal
                                    SchemaUrl="/rest/devices/get_device_import_help?get_schema=dassui,get"
                                    type="page-fluid"
                                    OnClose={() => { navigate(getAppBase() + '/dmp-devices') }}
                                />
                            </div>
                        )
                    }
                </DataTableWrapper>


                {
                    showOrbiWANInstanceDropDown && (
                        // Modal
                        <Modal show={showOrbiWANInstanceDropDown} onHide={() => setShowOrbiWANInstanceDropDown(false)} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Select OrbiWAN Instance</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    <select
                                        onChange={(e) => { selectOrbiWANInstanceHandler(e.target.value, typeOfDevicesBeingImported.current !== 'file-upload-dass') }}
                                    >
                                        <option value="">Select OrbiWAN Instance</option>
                                        {orbiwanInstances.map((item) => {
                                            return <option key={item.instance_name} value={item.instance_name}>{item.instance_name}</option>
                                        })}
                                    </select>
                                </div >
                            </Modal.Body >
                            <Modal.Footer>
                                <Button
                                    variant="primary"
                                    onClick={() => setShowOrbiWANInstanceDropDown(false)}>{strings.CLOSE}</Button>
                            </Modal.Footer>
                        </Modal >
                    )
                }
                {
                    showDeviceProfileDropDown && (
                        // Modal
                        <Modal show={showDeviceProfileDropDown} onHide={() => setShowDeviceProfileDropDown(false)} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>{strings.SELECT_DEVICE_PROFILE}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    <p>
                                        {
                                            typeOfDevicesBeingImported.current === 'file-upload-provisioned' || typeOfDevicesBeingImported.current === 'file-upload-normal' ? strings.SELECT_DEVICE_PROFILE_NOTE : strings.SELECT_DEVICE_PROFILE_NOTE_LNS
                                        }
                                    </p>
                                    <select
                                        onChange={(e) => {
                                            // setSelectedOrbiWANInstance(e.target.value);
                                            if (e.target.value !== "") {
                                                setShowDeviceProfileDropDown(false)
                                            }
                                            updateDevicesData({ device_profile_uuid: e.target.value });
                                        }}
                                    >
                                        <option value="">{strings.SELECT_DEVICE_PROFILE}</option>
                                        {
                                            deviceProfileDropdownOptions
                                        }
                                    </select>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="primary"
                                    onClick={() => setShowDeviceProfileDropDown(false)}>{strings.CLOSE}</Button>
                            </Modal.Footer>
                        </Modal>
                    )
                }

                {
                    showConnectionsDropDown && (
                        // Modal
                        <Modal show={showConnectionsDropDown} onHide={() => setShowConnectionsDropDown(false)} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>{strings.SELECT_CONNECTION}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    <p>
                                        {strings.SELECT_CONNECTION_NOTE}
                                    </p>
                                    <select
                                        onChange={(e) => {
                                            // setSelectedOrbiWANInstance(e.target.value);
                                            if (e.target.value !== "") {
                                                setShowConnectionsDropDown(false)
                                            }
                                            updateDevicesData({ connection_uuid: e.target.value });
                                        }}
                                    >
                                        <option value="">{strings.SELECT_CONNECTION}</option>
                                        {
                                            connectionsDropdownOptions
                                        }
                                    </select>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="primary"
                                    onClick={() => setShowConnectionsDropDown(false)}>{strings.CLOSE}</Button>
                            </Modal.Footer>
                        </Modal>
                    )
                }
            </>
    )
}

export default DeviceImport;


const ProgressBarComponent = (props) => {
    return (<div className="w-100 float-left" >
        <ProgressBar now={props.value} label={`${props.value}% ${props.caption}`} />
    </div>);
}