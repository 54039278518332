
    import { GenericDassQuery } from "./BasicDassQueries";

    //BatchRegServiceFn.$inject = ['$http', "$q"];

//const BatchRegDataService = () => {
        // Get individual gateway data
        // function getUserDataImpl() {
        //     var defer = $q.defer();
        //     $http.get('/whoami/').then(function (response) {
        //         defer.resolve(response.data);
        //     }, function (response) {
        //         defer.reject(response);
        //     });
        //     return defer.promise;
        // }

        // function getUserImpl() {
        //     var defer = $q.defer();

        //     getUserDataImpl().then(function (data) {
        //         defer.resolve(data.user)
        //     }).catch(function (err) {
        //         defer.reject(err);
        //     });

        //     return defer.promise;
        // }


const loadRegDataImpl =  () => {
    //var defer = $q.defer();

    const response = GenericDassQuery('registration_file.json', {prefix:'/'});
    return response;

    // $http.get('/registration_file.json').then(function (response) {
    //     defer.resolve(response.data);
    // }, function (response) {
    //     defer.reject(response);
    // });
    // return defer.promise;
}

const refreshDevicesImpl = async ( data ) => {
    const response = await GenericDassQuery('getstatus_devices', {prefix: '/', method: "PUT"});
    return response
    
}

const registerDevicesImpl = async  ( data ) => {
    const response = await GenericDassQuery('register_devices', {prefix:'/', data:data, method:"PUT"});
    return response
    
    
}

const getDevicesFromDASSImpl = async (data) => {
    // const response = await GenericDassQuery('get_devices_from_dass', {prefix:'/',method:"GET", data:data});
    // return response

    const url = `/rest/nodes/get_devices_from_dass?orbiwan_instance=${data.orbiwan_instance}`;
    return await GenericDassQuery(url, { method: "GET" });
    // const responseJSON = response.data;
    // return responseJSON;
}

const getDassDeviceProfilesImpl = async (orbiwan_instance) => {
    // url to call
    // /rest/lns/device-profiles?orbiwan_instance=
    const url = `/rest/lns/device-profiles?orbiwan_instance=${orbiwan_instance}`;
    return await GenericDassQuery(url, { method: "GET" });
    // const responseJSON = response.data;
    // return responseJSON;
}

const getDassServiceProfilesImpl = async (orbiwan_instance) => {
    // url to call
    // /rest/lns/device-profiles?orbiwan_instance=
    const url = `/rest/lns/service-profiles?orbiwan_instance=${orbiwan_instance}`;
    return await GenericDassQuery(url, { method: "GET" });
    // const responseJSON = response.data;
    // return responseJSON;
}

const getDeviceProfilesImpl = async () => {
    const response = await GenericDassQuery('/rest/device-profiles', {method:"GET"});
    return response.data;
}

const updateDevicesImpl = async (data) => {
    const response = await GenericDassQuery('update_devices', {prefix:'/', data:data,method:"PUT"});
    return response
    //return $http.put('/update_devices', data);
}

const deleteDevicesImpl = async (data) => {
    const response = await GenericDassQuery('delete_devices', {prefix:'/', data:data,method:"PUT"});
    return response
}

const clearDevicesImpl = async  () => {
    const response = await GenericDassQuery('clear_devices', {prefix:'/',method:"PUT"});
    return response
}

export {
    loadRegDataImpl as loadRegDataService,
    refreshDevicesImpl as refreshDevicesService,
    registerDevicesImpl as registerDevicesService,
    updateDevicesImpl as updateDevicesService,
    deleteDevicesImpl as deleteDevicesService,
    clearDevicesImpl as clearDevicesService,
    getDevicesFromDASSImpl as getDevicesFromDASSService,
    getDassDeviceProfilesImpl as getDassDeviceProfilesService,
    getDassServiceProfilesImpl as getDassServiceProfilesService,
    getDeviceProfilesImpl as getDeviceProfilesService
}



//export default BatchRegDataService;