
/**
 * Parse a date/time string and return a normal Date object.
 * 
 * This function can handle the relative now+1h etc. format from Grafana.
 * 
 * @param str - the string to parse
 * @param end - for intervals, indicate is modulo value is for start or end of range.
 * @param now - current epoc in sec, e.g. Math.floor(Date.now() / 1000)
 * @param tzOffsetMin - new Date().getTimezoneOffset()
 * @returns 
 */
export function parseRelativeDateTimeString(str: string, end: boolean, now?: number, tzOffsetMin?: number) {

    if (now == null)         { now = Date.now(); }
    if (tzOffsetMin == null) { tzOffsetMin = new Date().getTimezoneOffset(); }


    const trim = str.replace(/\s/g, "");
    if (trim.startsWith("now")) {
        let rest = trim.substring(3);
        now -= tzOffsetMin * 60 * 1000;

        while (rest != "") {

            // Check for +/- number and unit
            // e.g.: +1h or -2d etc.
            const m = rest.match(/^([+-])([0-9]+)([smhdwMQy])/);
            if (m) {

                const offset = parseInt(m[1] + m[2], 10);
                const unit = m[3];
                if (unit === "s") {
                    now += offset * 1000;
                } else if (unit === "m") {
                    now += offset * 60 * 1000;
                } else if (unit === "h") {
                    now += offset * 60 * 60 * 1000;
                } else if (unit === "d") {
                    now += offset * 24 * 60 * 60 * 1000;
                } else if (unit === "w") {
                    now += offset * 7 * 24 * 60 * 60 * 1000;
                } else if (unit === "M") {

                    const d = new Date(now);
                    const month = d.getUTCMonth() + offset;
                    const year = d.getUTCFullYear();

                    d.setFullYear(year + Math.floor((Math.abs(month) / 12) + (month < 0 ? 1 : 0)) * Math.sign(month));
                    d.setMonth((12 + (month % 12)) % 12);

                    now = d.getTime();

                } else if (unit === "y") {

                    const d = new Date(now);
                    const year = d.getUTCFullYear();
                    d.setFullYear(year + offset);
                    now = d.getTime();

                }

                rest = rest.substring(m[0].length);
                continue;
            }


            // Check for period modulo
            // e.g.: /m for start of month, etc.

            const m2 = rest.match(/^[/]([smhdwMQy])/);
            if (m2) {
                const unit  = m2[1];
                let   d     = new Date(now);
                const milli = end ? 999 : 0;
                const secs  = end ? 59  : 0;
                const mins  = end ? 59  : 0;
                const hours = end ? 23  : 0;


                if (unit === "s") {
                    d.setUTCMilliseconds(milli);
                } else if (unit === "m") {
                    d.setUTCSeconds(secs, milli);
                } else if (unit === "h") {
                    d.setUTCMinutes(mins, secs, milli);
                } else if (unit === "d" || unit === "w" || unit === "M" || unit === "Q" || unit === "y") {
                    d.setUTCHours(hours, mins, secs, milli);
                }
                if (unit === "w") {
                    d = new Date(d.getTime() - d.getUTCDay() * 24 * 60 * 60 * 1000  +  (end ? 6 * 24 * 60 * 60 * 1000 : 0));
                }
                if (unit === "Q") {
                    d.setUTCMonth(Math.floor(d.getUTCMonth() / 3) * 3 + (end ? 2 : 0));
                }
                if (unit === "y") {
                    d.setUTCMonth(end ? 11 : 0);
                }
                if (unit === "M" || unit === "Q" || unit === "y") {                   
                    if (end) {
                        d.setUTCFullYear(d.getUTCFullYear() + (d.getUTCMonth() >= 11 ? 1 : 0), (d.getUTCMonth() + 1) % 12, 1);
                        d = new Date(d.getTime() - 24 * 60 * 60 * 1000);
                    } else {
                        d.setUTCDate(1);
                    }
                }

                now  = d.getTime();
                rest = rest.substring(m2[0].length);
                continue;
            }


            throw new Error("Invalid now expression");
        }

        return new Date(now + tzOffsetMin * 60 * 1000);

    } else {

        // When using Date.parse(), if we provide a time, the timezone offset is applied. If there is NO time (only date),
        // then javascript assume UTC time, so in this case we set the offset to 0.
        // Date handling in javascript is a MESS!!
        const tzo = (str?.indexOf(":") > 0) ? (new Date().getTimezoneOffset() * 60 * 1000) : 0

        // Here we parse in local timezone 
        return new Date(Date.parse(str) - tzo  + tzOffsetMin * 60 * 1000);

    }

}


export function toDateTimeString(date: Date) {

    if (!date) { return ""; }

    return date.getFullYear() + "-" + String(date.getMonth() + 1).padStart(2, "0") + "-" + String(date.getDate()).padStart(2, "0") + " " + date.toLocaleTimeString();

}



/*

const tzOffset = new Date().getTimezoneOffset();

const now = Math.floor(Date.now() / 1000);


console.log("now = ", new Date(now * 1000), tzOffset);
console.log(parseRelativeDateTimeString(now, tzOffset, " now + 1h/w", false).toLocaleString());

*/