import React, {useContext, useState, useEffect } from "react";
import { strings } from "./../../services/Localization";

import { checkLogin } from "./../../services/Login";

import { nstBaseUrl } from '../../utils/consts';

import { SignInForward } from "../../services/Login";

import AppContext from '../../context/AppContext'

import { /*Outlet,*/ useParams, useLocation, useNavigate  } from 'react-router-dom';
declare const constants;

import{ ID_INPUT_VALIDATION, DEFAULT_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";


import { 
    faArrowRight,
    faTrashAlt
} from '@fortawesome/pro-regular-svg-icons'


import { IUser } from "src/dassTypes";

import { appBaseUrl } from '../../utils/consts';


import { BreadCrumbType } from '../../datatypes/datatypes';

import { faRefresh }  from '@fortawesome/free-solid-svg-icons';


import PageContent from "../PageContent";
import { ActionType, ColumnType, DataTableOption } from "../../components/Common/DataTable/DataTypes";
import { dialog } from "../../components/Common";
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { toast } from "../../utils/Toaster";
import { actionIcon } from '../../utils/filters';

interface IAdminUsersState {
    loggedUser:IUser;
    showAlertModal:boolean;
    editUserId:string;
    pageTitle: string;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;

}

const AdminUsers: React.FC<{}> = () => {

    const location = useLocation();
    const isNst = String(location.pathname).indexOf('/nst') >= 0;
    const baseUrl = isNst ? nstBaseUrl : appBaseUrl;

    const navigate = useNavigate();

    const AppContextObj = useContext(AppContext);

    let { id, tabname } = useParams();
   
    const stateInit = {
        loggedUser: AppContextObj.user,
        showAlertModal: false,
        editUserId: '',
        pageTitle: strings.FIND_USERS_TITLE,
        breadCrumbArr: [{label: strings.FIND, url:''}, {label:strings.NAV_ADMIN_USERS,url:''}],
        refresh: false
    };

    const [state, setState] = useState<IAdminUsersState>(stateInit);
    
    
    useEffect(() => {
        if (constants.enable_profiles !== true) {
            window.location.href = appBaseUrl;
        } else {

            //LoggedIn();
            if (checkLogin(AppContextObj.user)) {
                setState(prevState => { return {...prevState, loggedUser: AppContextObj.user}})
            } 
        }
    }, [AppContextObj.user?.userid])

    
    const signInForward = async (userid: string) => {
        try {
            
            const user = await SignInForward(userid, baseUrl)
            AppContextObj.updateUser(user);
            
        } catch (e)  {
            console.log(e.message);
        }
	};
    

    const detailPageNav = (row: IUser) => {

        const type = row.is_organisation ? "organisations" :
                        row.is_customer ? "customers" : "users";
        const tab =  row.is_organisation ? "edit-organisation" :
                        row.is_customer ? "edit-customer" : "edit-user";

        // FIXME: when we come back the table should keep its old settigs
        const prevPageUrl =  String(location.pathname);

        navigate(`/app/accounts/${type}/${row.userid}/${tab}`, {state: {row: row, prevPageUrl}})
    }


	const deleteAccount = async (account: IUser) => {
        const confirmDialogeSettings = {
                title:  "You are about to delete an account",
                description: "Delete '" + account.userid + "'?",
                actionLabel: 'Delete',
        };
        if (await dialog(confirmDialogeSettings) === true) {
            try {

                const type = account.is_organisation ? "organisations" :
                                account.is_customer ? "customers" : "users";

                await GenericDassQuery(`/rest/${type}/${account.userid}`, { method: "DELETE" });
                refreshTable();
                toast.success("Account successfully deleted");
               
            } catch (e) {
                toast.error(e.message);
            }
        }
	}



    const getActions = () => {
        const user = AppContextObj.user;

        const actions: ActionType<IUser>[] = [
            {
                type: "action",
                text: "Delete account",
                visible: (row) => {
                    if (user._readonly)       { return false; }
                    if (user.is_customer)     { return user.customer_admin === true; }
                    if (user.is_organisation) { return user.org_admin === true; }
                    return user.administrator === true;
                },
                icon: faTrashAlt,
                render: (row, title) => actionIcon(row.accountid, title, faTrashAlt.iconName),
                action: (row) => deleteAccount(row)
            },
            {
                type: "action",
                text: "Impersonate account",
                icon: faArrowRight ,
                visible: (row) => (user.userid !== row.userid),
                render: (row, title) => actionIcon(row.accountid, title, faArrowRight.iconName),
                action: (row) => signInForward(row.userid)
            }
        ];

        return actions;
    }

    const  getTableOptions = () => {
		
        const actions = getActions();

		let columns: ColumnType<IUser>[] = [
            {
                key: "userid",
                type: "text",   
                title: strings.USER_ID,
                filterable: true,
                inputValidation: ID_INPUT_VALIDATION,
                filterField: "search_id",
                filterType: "text",
                dataAlign:'left',
                detailLink: true,
                detailPageNav: (row) =>  detailPageNav(row),
                newCellWidth: "180px",
            }, {
                key: "type",
                dbKey: "userid",
                type: "text",   
                title: strings.FIND_USERS_TABLE_ACCOUNT_TYPE,
                render: (row) => <div className="badge">{row.is_organisation ? "organisation" : row.is_customer ? "customer" : "user"}</div>,
                cellWidth: 20,
                dataAlign:'center',
                newCellWidth: "150px",
            }, {
                key: "administrator",
                title: strings.FIND_USERS_TABLE_ADMINISTRATOR,
                type: "text",
                render: (user) => user.administrator ? strings.YES :strings.NO,
                filterable: true,
                inputValidation: DEFAULT_INPUT_VALIDATION,
                filterField: "search_administrator",
                filterType: 'select',
                filterParams: {
                    options:[
                        {label:strings.YES, value: "true"}
                        ,{label:strings.NO, value: "false"}
                    ]
                },
                dataAlign:'center',
                newCellWidth: "180px",
            }, {
                key: "can_register",
                title: strings.FIND_USERS_TABLE_REGISTRATION_RIGHTS,
                type: "text",
                filterable: true,
                filterField: "search_can_register",
                render: (user) => user.can_register ? strings.YES : strings.NO,
                filterType: 'select',
                filterParams: {
                    options:[
                        {label:strings.YES, value: "true"}
                        ,{label:strings.NO, value: "false"}
                    ]
                },
                dataAlign:'center',
                newCellWidth: "180px",
            },
            {
                key: "parentid",
                title: strings.FIND_USERS_TABLE_PARENT,
                type: "text",
                newCellWidth: "180px",
            }
        ];
        ///uiapi/rest/users?get_pages=true&limit=50&search_all_customers=true&search_all_tenants=true&search_all_users=true&search_id=peter&stream=progress
        
        const options: DataTableOption<IUser> = {
            url:'/uiapi/rest/users',
            query_param:{
                get_pages: true, limit:DEFAULT_RECORD_LIMIT, stream:'progress', search_all_customers: true,
                search_all_tenants: true, search_all_users: true, all: false},
            serial_number:false,
            id_field:'deveui',
            oboe_path:'pages.*',
            available_key: 'deveui',
            columns,
            actions,
            isDisplayMobile:true
        }

        return options;
	}

        
    const  refreshTable = () => {

        setState(prevState => {
            return {...prevState, refresh:!prevState.refresh}
        })
        
    }

    const  getPageButtons = () => {
        
        var  pageButtons = [
            {
                title: strings.REFRESH_LIST,
                action: () => { refreshTable() },
                type: 'button',
                icon:faRefresh
            },
        ]

        return pageButtons;
    }

    
    if (constants.enable_profiles !== true) {
        return null;
    } else {

        return (<PageContent
            name={`admin-users`} 
            id={id} 
            tabname={tabname} 
            actions={getActions()} 
            breadCrumbArr={state.breadCrumbArr}
            emptyDataMsg={strings.NO_DEVICE_AVAILABLE}
            pageButtons={getPageButtons()} 
            countLabel={`Accounts`} 
            dataTableOption={getTableOptions()} 
            refresh={state.refresh}>
        </PageContent>)
    }
 
}


export default AdminUsers;