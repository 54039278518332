import { strings } from "./../../services/Localization";
import {  DetailPageActionType, DetailPagePropsType, BreadCrumbType } from '../../datatypes/datatypes';
import { faAdd } from '@fortawesome/free-solid-svg-icons'
import { getAppBase } from '../../utils/consts';
import { TAB_ADD_TAG, TAB_EDIT_TAG } from '../../datatypes/tabsconstants'



const detailPageNav = (detailPageNavArgs) => {

    const { navigate, tab, id, row } = detailPageNavArgs;
    let prevPageUrl = detailPageNavArgs.hasOwnProperty('prevPageUrl') ? detailPageNavArgs['prevPageUrl'] : null;
    if (id) {
        navigate(`${getAppBase()}/tags/${id}/${tab}`, { state: { row: row, prevPageUrl: prevPageUrl } })
    } else {
        navigate(`${getAppBase()}/tags/${tab}`, { state: { tab: tab, prevPageUrl: prevPageUrl } })
    }
}

const TagProps: DetailPagePropsType = {
    getBreadCrumbArr: (breadCrumbArgs) => {
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : '-1';

        const breadCrumb:BreadCrumbType[] = [
            { label: strings.NAV_TAG_ITEM, url: prevPageUrl },
            { label: strings.ADD_TAG, url: '' }
        ]
        return breadCrumb;
    },
    getActions: (actionArgs, navigate) => {

        // FIXME Here we need to define prevPageUrl from action args
        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : '-1';

        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.ADD_TAG,
                icon: faAdd,
                key: TAB_ADD_TAG,
                action: (id) => detailPageNav({ navigate: navigate, tab: TAB_ADD_TAG, id: null, row: null, prevPageUrl: prevPageUrl }),
                content: {
                    type: 'SchemaModal',
                    url:  `/rest/tags?get_schema=post`,
                    backUrl: prevPageUrl
                },
                EditObject: null
            },
        ]
        return actions;
    }
}


export { TagProps };


const TagRowProps: DetailPagePropsType = {
    getBreadCrumbArr: (breadCrumbArgs: any) => {
        const { id, row } = breadCrumbArgs;
         let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : '-1';
        const breadCrumb:BreadCrumbType[] = [
            { label: strings.NAV_TAG_ITEM, url: prevPageUrl },
            { label: `${id}`, url: '', comment: row.name }
        ]
        return breadCrumb;
    },
    getActions: (actionArgs, navigate) => {
        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : '-1';
        let row = actionArgs.hasOwnProperty('row') ? actionArgs['row'] : {}; 
        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.EDIT_TAG,
                icon: faAdd,
                key: TAB_EDIT_TAG,
                action: (row: any) => detailPageNav({ navigate: navigate, tab: TAB_EDIT_TAG, id: row.tagid, row: row, prevPageUrl: prevPageUrl }),
                content: {
                    type: 'SchemaModal',
                    url:  `/rest/tags?get_schema=dassui,put`,
                    backUrl: prevPageUrl
                },
                EditObject: row.tagid ? row : null
            }
        ]
        return actions;
    }
}

export { TagRowProps }