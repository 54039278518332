import React, { Suspense, lazy } from "react";
import { IUiSchemaElemArgs } from "./SchemaController";
import { registerComponentHandler } from "./SchemaExtensions";


const AgGrid = lazy(() => import("./AgGrid"));


function extAgGrid(args: IUiSchemaElemArgs) {

    const { value, title, description, uiElem } = args;

    const header = title && (uiElem?.titleLayout == null || uiElem.titleLayout === "top") 
                    ? args.stringToComponent(title)
                    : description && uiElem.descriptionLayout === "top"
                        ? args.stringToComponent(description) : undefined;

    const footer = title && uiElem?.titleLayout === "left"
                    ? args.stringToComponent(title)
                    : description && (uiElem?.descriptionLayout == null || uiElem?.descriptionLayout === "bottom")
                        ? args.stringToComponent(description) : undefined;
  
    const tableJsx = (
        <Suspense key={args.fullkey} fallback={<div><i className="fa-regular fa-spinner fa-spin"></i></div>}>
            <AgGrid data={value} footer={footer} header={header}/>
        </Suspense>
    );

    return tableJsx; //args.embedObject(tableJsx, { useFlex: true});
};


registerComponentHandler("ag-grid", extAgGrid);