
import React, { ReactNode } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { NavLink  } from "react-router-dom";

import { IUiSchemaCardArgs, IUiSchemaElemArgs} from "./SchemaController";
import { registerComponentHandler, registerExtensionCardHandler } from "./SchemaExtensions";



const ExtNav = (args: IUiSchemaElemArgs) => {

    const { key, fullkey, uiElem } = args;
    const href = uiElem?.href || "#";

    const click = () => {
        args.update({ value: undefined });
    }

    let path = window.location.href;
    path = path.indexOf("?") >= 0 ? path.substring(0, path.indexOf("?")) : path;
    path = path.indexOf("#") >= 0 ? path.substring(0, path.indexOf("#")) : path;
    const active = typeof href === "string" && path.endsWith(href);

    const titleObj = args.embedObject(<></>, { noMargin: true, noWrapper: true });

// className=" text-light text-decoration-none"
    if (args.type === "navitem") {
        const cls = active ? "schema-engine-active-menu-dropdown-item active" : "";
        return (
            <NavDropdown.Item as="div" className={cls} key={fullkey} eventKey={key} href={"#"} onClick={click}>
                <NavLink to={href} className="text-decoration-none" >
                    {titleObj}
                </NavLink>
            </NavDropdown.Item>
        );
    } else {//active_menu_button
        const cls = active ? "schema-engine-active-menu active_menu_button active" : "";

        return (
            <Nav.Link as="div" className={cls} key={fullkey} eventKey={key} href={"#"} onClick={click}>
                <NavLink to={href} className="text-light text-decoration-none" >
                    {titleObj}
                </NavLink>
            </Nav.Link>
        );
    }

};


const ExtNavDropdownCard = (args: IUiSchemaCardArgs, children: ReactNode) => {

	//const titleText   = card.title && evalString(card.title, lib, objects, { readOnly }) + "";
    //const titleObj    = titleText && this.formatText(titleText, null, cardkey);
    let active = false;
    if (Array.isArray(children)) {
        const elems: JSX.Element[] = children;
        for (const elem of elems) {
            if (elem.props.className?.includes("schema-engine-active-menu-dropdown-item")) {
                active = true;
            }
        }
    }

    const titleObj = args.stringToComponent(args.title);
    const cls = active ? "active_menu_dropdown active" : "";


	return(<NavDropdown key={args.key} title={titleObj} className={cls}>
        {children}
    </NavDropdown>);

}


registerComponentHandler("navlink", ExtNav);
registerComponentHandler("navitem", ExtNav);

registerExtensionCardHandler("navdropdown", ExtNavDropdownCard);