import React, { lazy, Suspense } from "react";
import { IUiSchemaElemArgs } from "./SchemaController";
import { registerComponentHandler } from "./SchemaExtensions";



const VegaChart = lazy(() => import("./VegaChart"));


const vegaChartExtension = (args: IUiSchemaElemArgs) => {

	const { value, fullkey } = args;
	const options = args.uiElem?.componentOptions || {};

	return (
		<Suspense key={fullkey} fallback={<div><i className="fa-regular fa-spinner fa-spin"></i></div>}>
			<VegaChart vegaSpec={value} width={options.vegaLiteWidth} height={options.vegaLiteHeight} theme={options.vegaLiteTheme}/>
		</Suspense>
	);


	/*
	return args.embedObject(
		<Suspense key={fullkey} fallback={<div>Loading...</div>}>
			<div className="here we are"></div>
			<VegaChart vegaSpec={value}/>
		</Suspense>,
		{ isContainer: true }
	);
*/
};

registerComponentHandler("vegachart", vegaChartExtension);
