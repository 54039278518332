import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { IUiSchemaElemArgs } from "./SchemaController";


export interface ISchemaFormProps {
	args: IUiSchemaElemArgs;

	value: string;
	onFocus?: (event: any) => void;
	onBlur?: (event: any) => void;
	onTextChange: (text: string) => string | null;
	context: number;
	hidden: boolean;
}

const SchemaForm : React.FC<ISchemaFormProps> = (props, props2) =>  {

	const [value, setvalue] = useState(props.value);
	const [context, setcontext] = useState(0);
	const [showpass, setShowpass] = useState(false);
	const [focus, setFocus] = useState(false);
	const { args, hidden } = props;
	const { fullkey, error, type } = args;
	
	const types: any = {
		password: "password",
		"old-password": "password",
		"new-password": "password",
		number: "text",
		integer: "text",
	};

	if (props.context !== context) {
		setvalue(props.value);
		setcontext(props.context);
	}

	const onChange = (event: any) => {
		if (!focus) { return; }
		const text = props.onTextChange(event.target.value);
		if (text != null) setvalue(text);
	}

	if (typeof args.uiElem?.multiLine === "number" && args.uiElem.multiLine > 1) {
		return (
			<Form.Control
				as="textarea"
				key={"form-" + fullkey}
				disabled={args.readOnly}
				isInvalid={error}
				onFocus={(e) => { setFocus(true); props.onFocus?.(e); }}
				onBlur={(e) => { setFocus(false); props.onBlur?.(e); }}
				rows={args.uiElem?.multiLine}
				value={hidden && !focus ? (value ? "●●●" : "") : value}
				className="new_style_textarea"
				onChange={onChange}
				placeholder={args.uiElem?.placeholder}
			/>
		);
	}

	const ctrl = (
		<Form.Control
		 	key={"form-" + fullkey}
			type={(types[type] === "password" && showpass ? "text" : types[type]) || "text"}
			disabled={args.readOnly}
			isInvalid={error}
			onFocus={(e) => { setFocus(true); props.onFocus?.(e); }}
			onBlur={(e) => { setFocus(false); props.onBlur?.(e); }}
			value={hidden && !focus ? (value ? "●●●" : "") : value}
			className="new_style_focus"
			autoComplete={type === "new-password" || type === "old-password" ? type : "off"}
			onChange={onChange}
			placeholder={args.uiElem?.placeholder}
		/>
	);

	if (types[type] === "password") {
		return (
			<InputGroup key={"form-" + fullkey}	>
				{ctrl}
				<Button disabled={args.readOnly} variant="outline-secondary" className={error ? "schema-engine-error-frame" : ""}
						tabIndex={-1}
						onClick={(ev) => setShowpass(!showpass)}
						onMouseDown={(ev) => ev.preventDefault()}
				>
					<i className={"fa-regular " + (showpass ? "fa-eye" : "fa-eye-slash")} />
				</Button>
			</InputGroup>
		);
	}

	return ctrl;

};

export default SchemaForm;
