import {  DetailPageActionType, DetailPagePropsType, BreadCrumbType } from '../../datatypes/datatypes';
import { strings } from "../../services/Localization";
import { faList } from '@fortawesome/pro-regular-svg-icons'
import { getAppBase } from '../../utils/consts';
import {  TAB_ADD_RULE, TAB_RULE_INFO  } from '../../datatypes/tabsconstants'



const addPageNav = ( navigate, tab) => {
    
    navigate( `${getAppBase()}/rules/${tab}`, {state: {tab: tab, prevPageUrl: `${getAppBase()}/rules`}})
}


const detailPageNav = ( actionArgs ) => {

    const { navigate, tab, id, row } = actionArgs;
    let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : null;
    navigate(`${getAppBase()}/rules/${id}/${tab}`, {state: { row: row, prevPageUrl:prevPageUrl}})
}


export const RuleAddProps:DetailPagePropsType =  {
    getBreadCrumbArr: ( breadCrumbArgs ) => {
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : '-1';

        const breadCrumb:BreadCrumbType[] = [
            {label: strings.NAV_MANAGE_RULES,  url: prevPageUrl},
            {label: strings.ADD_RULE,  url:''}
        ]
        return breadCrumb;
    },    
    getActions : (actionArgs, navigate) => {
        
        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : '-1';

        const actions: DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.NAV_MANAGE_RULES,
                icon: faList,
                key: TAB_ADD_RULE,
                action: (id) => addPageNav(navigate, TAB_ADD_RULE),
                content: {
                    type: 'SchemaModal',
                    url:'/rest/rules?get_schema=dassui,post',
                    backUrl: prevPageUrl
                }
            }                 
            
        ]

        return actions;
    }
}

export const RuleEditProps:DetailPagePropsType =  {

    getBreadCrumbArr: ( breadCrumbArgs ) => {
        
        let id = breadCrumbArgs.hasOwnProperty('id') ? breadCrumbArgs['id'] : 0;
        let ruleName = breadCrumbArgs?.row?.name || "";
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : '';
        return [
            {label: strings.NAV_MANAGE_RULES, url: prevPageUrl}, 
            { label: ruleName, url:'', comment: id}
        ]
    },

    getActions : (actionArgs, navigate) => {

        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : '-1';
        const { row } = actionArgs;
        const ruleUuid = row.hasOwnProperty('ruleUuid') ? row.ruleUuid : (row.hasOwnProperty('ruleUuid') ? row.ruleUuid : null);
                
        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.EDIT_RULE,
                icon: faList,
                key: TAB_RULE_INFO,
                action: (row) => detailPageNav({ navigate: navigate, tab:TAB_RULE_INFO, id:ruleUuid, row:row, prevPageUrl:prevPageUrl}),
                content: {
                    type: 'SchemaModal',
                    url:'/rest/rules?get_schema=dassui,put',
                    backUrl: prevPageUrl
                },
                EditObject: ruleUuid ? { ruleUuid } : null
            }
        ]

        return actions;
    }
}





