import React from "react";
import App from "./App";
import MainState from "./context/MainState";
import { createRoot } from 'react-dom/client';

import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
//import "./style/orbiwise/style-old.css";
//import "./style/orbiwise/dassui.css";
import "./style/orbiwise/style.css";

import "@fortawesome/fontawesome-pro/css/fontawesome.min.css"
import "@fortawesome/fontawesome-pro/css/regular.min.css"
import "@fortawesome/fontawesome-pro/css/solid.min.css"
import { IConstants } from "./types";

declare const headerContentTypeJson: any;
declare const headerNoContentType: any;
declare const constants: IConstants;

function customCss() {
    if (constants && (constants._license & (1<<16)) && constants._css) {
        const head = document.getElementsByTagName('head')[0];
        const customStyles = document.createElement("style"); 
        customStyles.appendChild(document.createTextNode(constants._css));
        head.appendChild(customStyles); 
    }
}


/**
 * This function is called to decode and transform the constants object into the 'actual' constants object.
 * In previous versions, the constants object was provided directly in clear text. However now it is 
 * provided in an encrypted (using fixed key) form using AES-GSM encryption. This prevents basic
 * "sniffing" for information in the network log.
 */
async function getConst() {

    return constants.wait = new Promise<void>(async (resolve) => {

        try {
            const { data, edata } = constants;
            delete constants.data;
            delete constants.edata;

            if (data) {

                Object.assign(constants, JSON.parse(atob(data)));

            } else if (edata) {

                const key   = new Uint8Array([45, 45, 79, 114, 98, 105, 119, 105, 115, 101, 50,  48, 50,  51,  45,  45]);
                const dec   = Uint8Array.from(atob(edata), c => c.charCodeAt(0))
                const dkey  = await crypto.subtle.importKey("raw", key, "AES-GCM", false, ["decrypt"]);
                const ddata = await crypto.subtle.decrypt({ name: "AES-GCM", iv: key }, dkey, dec);
                Object.assign(constants, JSON.parse(new TextDecoder().decode(ddata)));
            }

            Object.assign(headerNoContentType,   { "csrf-token": (constants as any)._csrf })
            Object.assign(headerContentTypeJson, { ...headerNoContentType, "Content-Type": "application/json" })

        } catch (e) {
            console.error("Can't get constants", e.message);
        }

        delete constants.wait;
        resolve();
    });
}


// Update the title
getConst().then(() => {

    document.title = constants.instance_title;
    customCss();
    
    const container = document.getElementById("root");
    const root = createRoot(container); // createRoot(container!) if you use TypeScript

    root.render(
        <React.StrictMode>
            <MainState>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </MainState>
        </React.StrictMode>
    );  

});

