import { IUser } from "src/dassTypes";
import { IConstants } from "src/types";

declare const constants: IConstants;declare const headerContentTypeJson;


export const SignInForward = async (userid: string, baseUrl: string): Promise<IUser> => {
    await constants.wait;
    const response = await fetch("/signinforward" + (userid ? "?userid=" + userid : ""), {
        credentials: "same-origin",
        headers: headerContentTypeJson,
        method: "PUT",
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 401) { // the status should be changed!!!
        window.location.href = baseUrl + '/signout?resignin';
        return null as IUser;
    } else {
        throw response;
    }
};

export const SelectAccount = async (userid: string, baseUrl: string): Promise<IUser>  => {
    await constants.wait;
    const response = await fetch("/selectaccount" + (userid ? "?selectuserid=" + userid : ""), {
        credentials: "same-origin",
        headers: headerContentTypeJson,
        method: "PUT",
    });
    if (response.status === 200) {
        return await response.json();
    } else if (response.status == 401) { // the status should be changed!!!
        window.location.href = baseUrl + '/signout?resignin';
        return null as IUser;
    } else {
        throw response;
    }
};


export const checkLogin = ( user ) => {

    return user?.userid ? true : false;
}