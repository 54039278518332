import React from "react";
import { IUiSchemaElemArgs } from "./SchemaController";
import { registeredExtensionHandlers, registerComponentHandler } from "./SchemaExtensions";
import { Alert } from "react-bootstrap";




export const dashboardSelectExtension = (args: IUiSchemaElemArgs) => {

    let type = "";

    if (args.value && typeof args.value === "object" && args.value.error) {

        return (
                <Alert key={args.key} variant="danger">
                    <Alert.Heading><i className="fa-regular fa-circle-exclamation"></i> Something went wrong</Alert.Heading>
                    <p>{args.value.error}</p>                  
                </Alert>
        )

    } else if (args.value && typeof args.value === "object" && args.value.$schema) {
        const schema = args.value.$schema;

        if (schema.includes("vega")) {
            type = "vegachart";
        } else if (schema.includes("status-panel")) {
            type = "dashboard-status";
        } else if (schema.includes("markdown")) {
            type = "markdown";
        } else if (schema.includes("table")) {
            type = "ag-grid";
        } else if (schema.includes("gauge")) {
            type = "amchart-schema";
        }

        return registeredExtensionHandlers[type]?.(args) || (<div key={args.key}><i className="fa-regular fa-spinner fa-spin"></i></div>)

    } else {

        return (<div key={args.key}><i className="fa-regular fa-spinner fa-spin"></i></div>);

    }

    
};

registerComponentHandler("dashboard-select", dashboardSelectExtension);
