import React, { ReactNode, useRef, useState } from "react";

import { HoverOverlay } from "./HoverOverlay";
import { Form, Popover } from "react-bootstrap";



export const translationMap: {
    [id: string]: string;
} = {};

export const translationVerifiedMap: {
    [id: string]: boolean;
} = {};


let updateTranslationsTimer: NodeJS.Timeout;
let updateTranslationsHandler: (translations: { [id: string]: string }, verified: { [id: string]: boolean } ) => void;

export function setTranslationHandler(handler: typeof updateTranslationsHandler) {
    updateTranslationsHandler = handler;
}

function resetUpdateTimer() {
    clearTimeout(updateTranslationsTimer);
    updateTranslationsTimer = setTimeout(() => {
        updateTranslationsHandler(translationMap, translationVerifiedMap);
    }, 1000);
}


export const AuditTranslation : React.FC<{ id: string, status: string, text: string, children: ReactNode }> = (props) =>  {

    let { status, id } = props;
    const [verified, setVerified] = useState<boolean>(status === "o" || status === "v");
    const [text, setText] = useState<string>(props.text);

    const ref = useRef<HTMLSpanElement>();
    let icon = "";
    let color = "";
    let textState = "";


    if (translationVerifiedMap[id] != null) {
        status = translationVerifiedMap[id] ? "v" : "c";
    }

    switch (status) {
        case "m": icon = "fa-bomb"; color = "red"; textState = "Translation missing"; break;
        case "a": icon = "fa-robot"; color = "orange"; textState = "Auto generated translation"; break;
        case "c": icon = "fa-message-exclamation"; color = "orange"; textState = "Please review translation"; break;
        case "v": icon = "fa-check-circle"; color = "green"; textState = "Translation has been verified"; break;
        case "o": icon = "fa-check-circle"; color = "green"; textState = "Original text";  break;
    }


//    const click = (ev) => { ev.stopPropagation(); return true; }

    const helpTip = (
        <>
            <Popover.Header dir="ltr">ID: {id} <small>{textState}</small></Popover.Header>
            <Popover.Body dir="ltr">
                <Form.Check 
                    type="checkbox" 
                    checked={verified}
                    onClick={(ev) => ev.stopPropagation() }
                    onChange={(ev) => {
                        ev.stopPropagation();
                        setVerified(!verified);
                        translationVerifiedMap[id] = !verified;
                        resetUpdateTimer();
                    }}
                    label="Translation has been verified"
                />

                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Translation</Form.Label>
                    <Form.Control 
                        as="textarea" rows={3} value={text}
                        onClick={(ev) => ev.stopPropagation() }
                        onChange={(ev) => { 
                            ev.stopPropagation(); 
                            setText(ev.target.value); 
                            setVerified(false);
                            translationVerifiedMap[id] = false;
                            translationMap[id] = ev.target.value;
                            resetUpdateTimer();
                        } }
                    />
                </Form.Group>

            </Popover.Body>
        </>
    );

//onClick={click}
    return <span ref={ref}>
        <HoverOverlay big={false} overlay={helpTip}>
            <i  style={{color}} className={"mx-1 fa-regular " + icon + " fa-fw"} />
        </HoverOverlay>
        {props.children}
    </span>;
}

