import React, {useState, useEffect, useContext } from "react";
import { useParams, useNavigate  } from 'react-router-dom';
//import { Outlet  } from 'react-router-dom';

import { faRefresh, faAdd }  from '@fortawesome/free-solid-svg-icons';

import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

import { strings } from "./../../services/Localization";
import { checkLogin } from "./../../services/Login";
import AppContext from '../../context/AppContext'
import { /*DataTable, PageHeader, DetailPageContainer, DataTableWrapper ,*/ dialog } from '../../components/Common';

import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { toast } from "./../../utils/Toaster";
import PageContent from "../PageContent";
//import { AlertModal } from "./../../components/Modals/AlertModal";

import { appBaseUrl } from '../../utils/consts';

import { TAB_EDIT_PARAM, TAB_ADD_PARAM  } from '../../datatypes/tabsconstants'
import{ DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";

import { IUser } from "../../../src/dassTypes";
import { ActionType, BulkActionType, ColumnType, OptionType } from "../../../src/components/Common/DataTable/DataTypes";

import LocStr$ from "../../localization/LocaleString";

import { getAppBase } from '../../utils/consts';

declare const constants;


export const pageTitle = LocStr$("Gateway Parameters");
export const pageUrlPart = "gateway-parameters";


export interface IRowType {
    id: number;
    gatewayType: string;
    key: string;
    value: string;
}




type FilterOptionType = {
    label: string;
    value: string;
}

interface IGatewayParametersStates {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    pageTitle: string;
    editEntityId: string | null;            // The entity in this view is a gateway
    schemaMethod: "post" |"put" | "" | string;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;
    categoryEnum: FilterOptionType[];
    isRowDeleted?:boolean;
}

interface IGatewayParametersProps {
}




const GatewayParameters: React.FC<IGatewayParametersProps> = (props) =>  {

    const { id, tabname } = useParams();
    const navigate = useNavigate();
    const AppContextObj = useContext(AppContext);



    const stateInit: IGatewayParametersStates = {
        loggedUser: null,
        showAlertModal: false,
        pageTitle,
        editEntityId: '',
        schemaMethod: "",
        breadCrumbArr: [{label:strings.NAV_GATEWAYS_ITEM, url:`${getAppBase()}/omc-gateways`},{label: pageTitle, url:''}],
        refresh:false,
        categoryEnum: [],
        isRowDeleted:false
    };

    const [state, setState] = useState<IGatewayParametersStates>(stateInit)

    //const can_list_profile = "can_list_profile";

    useEffect(() => {
        if (constants.enable_profiles !== true) {
            window.location.href = appBaseUrl;
        } else {
            if(checkLogin(AppContextObj.user)) {

                setState(prevState => { return {...prevState, loggedUser: AppContextObj.user}})
                // user = AppContextObj.user;

            }else {
                window.location.href = appBaseUrl + "/signin";
            }
            //LoggedIn();
        }
    },[])


    useEffect(() => {

        async function getCategories() {

            try {
                const catreq = await GenericDassQuery("/rest/omc/gatewaytypes");
                const enumfilter: FilterOptionType[] = [];
                for (const type of catreq.data) {
                    enumfilter.push({ label: type, value: type });
                }
                setState(prevState => { return {...prevState, categoryEnum: enumfilter }})
            } catch (e) {
                console.log(e.message);
            }

        }
        getCategories();

    }, []);





    const detailPageNav = ( navigate, tab, id, row = null) => {
        if(id) {
            
            navigate(`${getAppBase()}/${pageUrlPart}/${id}/${tab}`, {state: {row: row, prevPageUrl: `${getAppBase()}/${pageUrlPart}`}})
        }
    }

    const detailPageAdd = ( navigate, tab) => {

        
        navigate(`${getAppBase()}/${pageUrlPart}/${tab}`, {state: {tab: tab, prevPageUrl: `${getAppBase()}/${pageUrlPart}`}})
    }




    const deleteRow = async ( param: IRowType ) => {

        const confirmDialogeSettings = {
            title:  "You are about to delete a gateway Parameter",
            description: "Delete '" + param.key + "'?",
            actionLabel: 'Delete',
        };

        if (await dialog(confirmDialogeSettings) === true) {
            try {
                await GenericDassQuery("/rest/omc/defaultgatewayparameters/" + param.id, {
                    method: "DELETE",
                });
                toast.success("Gateway parameter deleted");
            } catch (e) {
                toast.error(e.message);
            }
            refreshTable(true);
        }
    }


    const deleteParameters = async (selectedRows: IRowType[]) => {
        
        const parameterids = selectedRows.map((row) => {
            return row['id'];
        })

        if(parameterids && parameterids.length > 0) {
            const confirmDialogeSettings = {
                title:  `You are about to delete ${parameterids.length} parameters`,
                description:'',
                actionLabel: 'Delete',
            };
    
            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(parameterids.map((parameterId) => {
                        return GenericDassQuery("/rest/omc/defaultgatewayparameters/" + parameterId, { method: "DELETE" })
                    })).then((values) => {
                        refreshTable(true);
                        toast.success("Parameters successfully deleted");
                    });
                } catch (e) {
                    toast.error(e.message);
                }                
            }
        }
    }

    const  getActions = () => {

        const actions: ActionType[] = [
            {
                type: "action",
                text: "Delete Parameter",
                icon: faTrashAlt,
                action: (tag) => deleteRow(tag),
                visible: (tag) => true,
            }
        ];
        const bulkActions: BulkActionType[] = [
            {
                type: "action",
                text: "Delete Parameters",
                icon: faTrashAlt,
                action: ( selectedRows ) => { deleteParameters(selectedRows) }
            }
        ];

        return { actions, bulkActions };
    }


    const  initDataTable = () => {

        let actions;
        var pageButtons;

        actions = getActions();

        const columns: ColumnType[] = [
            {
                key: "gatewayType",
                title: "Gateway Type",
                type: "text",

                sortKey: "sort_by_gateway_type",
                sortable: true,

                filterable: true,
                filterField: 'search_gateway_type',
                filterType: 'select',
                filterParams: {
                    mapper: (x) => x || undefined,
                    options: state.categoryEnum,
                },
                detailLink: true,
                detailPageNav: (row: IRowType) => detailPageNav(navigate, TAB_EDIT_PARAM, row.id, row),
                newCellWidth: "180px"
            },

            {
                key: "key",
                title: "Parameter",
                type: "text",

                sortKey: "sort_by_key",
                sortable: true,

                filterable: true,
                filterField: 'search_key',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined,
                },
                detailPageNav: (row: IRowType) => detailPageNav(navigate, TAB_EDIT_PARAM, row.id, row),
                newCellWidth: "180px"
            },

            {
                key: "value",
                title: "Value",
                type: "text",

                filterable: true,
                filterField: 'search_value',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined,
                },
                detailPageNav: (row: IRowType) => detailPageNav(navigate, TAB_EDIT_PARAM, row.id, row),
                newCellWidth: "180px"
            },
        ];


        let options: OptionType = {
            url:'/uiapi/rest/omc/defaultgatewayparameters',
            query_param: {
                get_pages: true,
                limit: DEFAULT_RECORD_LIMIT,
                stream: 'progress',
            } as any,                       // TODO: if optional parameters are not provided here, then where?
            serial_number: false,
            id_field: 'id',
            oboe_path: 'pages.*',
            available_key: 'id'
        }

        return {...actions, columns:columns, ...options, ...pageButtons };
	}

    const refreshTable = (isRowDeleted=false) => {
        setState(prevState => {
            return {...prevState, refresh:!prevState.refresh, isRowDeleted:isRowDeleted}
        })
    }


    const  getPageButtons = () => {

        var  pageButtons: PageButtonType[] = [
            {
                title: "Add Parameter",
                action: () => detailPageAdd(navigate, TAB_ADD_PARAM),
                type: 'button_with_icon',
                icon: faAdd
            },
            {
                title: strings.REFRESH,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh,
            },
        ]

        return pageButtons;
    }
    
    return (<PageContent
        name={`gateway-parameters`} 
        id={id} 
        tabname={tabname} 
        actions={getActions()} 
        breadCrumbArr={state.breadCrumbArr} 
        pageButtons={getPageButtons()} 
        countLabel={`Parameters`} 
        isRowDeleted={state.isRowDeleted}
        dataTableOption={initDataTable()} 
        refresh={state.refresh}>
     </PageContent>)

}

export default GatewayParameters;