
import { formatText } from "../schemaengine/client/SchemaTextParser";
import {  IconName } from '@fortawesome/fontawesome-common-types';

const sanitizeText = ( text ) => {
    text = String(text).toLowerCase().replace(/ /g,'-');
    return text;
}

export { sanitizeText }

const filter = ( v ) => {
    return v;
}

export { filter }

const elipsis  = (value, max, tail='...') => {
    if (!value) return '';

    max = parseInt(max, 10);
    if (!max) return value;
    if (value.length <= max) return value;

    value = value.substr(0, max);
    
    return value + (tail || ' …');
};

export { elipsis };


const renderTags  = ( input, color = "blue", link = null, tagDetailPage = null, navigate = null ) => {
    if (!input) return '';

    var inputArr = input.split(",");
    var tags = '';
    let url;
    
    for(let i = 0; i < inputArr.length; ++i) {
        if(link) {
            url =  link + inputArr[i];
            const func = () => { tagDetailPage(navigate, url, inputArr[i]);}
            tags += `<span class="badge ${color}" onClick="{${func}}">${inputArr[i]}</span>`;
        }else {
            tags += `<span class="badge ${color}">${inputArr[i]}</span>`;
        }
        
    }
    
    return tags;
};

// const renderTags  = ( input, color = "blue", link = null ) => {
//     if (!input) return '';

//     var inputArr = input.split(",");
//     var tags = '';
    
    
//     for(let i = 0; i < inputArr.length; ++i) {
//         if(link) {
//             tags += `<span class="badge ${color}">${inputArr[i]}</span>`;
//         }else {
//             tags += `<span class="badge ${color}">${inputArr[i]}</span>`;
//         }
        
//     }
    
//     return tags;
// };

export { renderTags };


const formatDeveui =  (input) =>  {
    if (!input) return "NULL"; // TODO: Maybe return "" if null/undefined
    var d = input.toUpperCase();
    return d.substring(0, 2) + "-" + d.substring(2, 4) + "-" + d.substring(4, 6) + "-" + d.substring(6, 8) + "-" +
        d.substring(8, 10) + "-" + d.substring(10, 12) + "-" + d.substring(12, 14) + "-" + d.substring(14, 16);
};

export { formatDeveui };


const dateString = (time: string | number) => {
    if (time == null || time == 0 || time == "") {
        return "";
    }
    //navigator.language
    const date = new Date(time);

    // Note, we specifically doesn't use the navigator.language to set the Locale time formats.
    // The reason is that the locale, like e.g. en-US impose a specific data format with AM/PM
    // order of date/month etc. that doesn't apply to all english speaking languages. Therefore
    // forcing this local on the date/time string will render the date/time wrongly for many users.
    // Using the browser defaults will keep it aligned with the users system defaults.

    return date.toLocaleDateString();
};

export const dateTimeString = (time: string | number) => {
    if (time == null || time == 0 || time == "") {
        return "";
    }
    //navigator.language
    const date = new Date(time);

    // Note, we specifically doesn't use the navigator.language to set the Locale time formats.
    // The reason is that the locale, like e.g. en-US impose a specific data format with AM/PM
    // order of date/month etc. that doesn't apply to all english speaking languages. Therefore
    // forcing this local on the date/time string will render the date/time wrongly for many users.
    // Using the browser defaults will keep it aligned with the users system defaults.

    return date.toLocaleDateString() + " " + date.toLocaleTimeString();
};





export { dateString };

import {
    faPause, faExclamationTriangle, faPlusCircle, faQuestion, faLink, faUpload, faTrash, faPlus
} from  '@fortawesome/pro-regular-svg-icons'
import { IDevice } from 'src/dassTypes';


export const deviceStatusSymbol = (obj: IDevice) => {
    if (obj.registration_status == 1) {
        if (obj.activated === false) { return faPause; }
        if (obj.suspended === true)  { return faExclamationTriangle; }

        const statussymbol = [faQuestion, faPlusCircle, faLink, faUpload, faExclamationTriangle];
        if (obj.device_status) {
            return statussymbol[obj.device_status];
        } else {
            return faQuestion;
        }
    } else if (obj.registration_status == 0) {
        return faPlus;
    } else if (obj.registration_status == 2) {
        return faTrash;
    } else {
        return faExclamationTriangle;
    }
}

export const deviceStatusTooltip = (obj: IDevice) => {

    if (obj.registration_status == 1) {
        if (obj.activated === false) { return "Disactivated"; }
        if (obj.suspended === true)  { return "Suspended"; }

        var statusTipArr = ["Never Seen", "Seen with MIC error", "Joined", "data received"];
        let statusTip =  statusTipArr[obj.device_status];
        return statusTip
    } else if (obj.registration_status == 0) {
        return "Never Seen";
    } else if (obj.registration_status == 2) {
        return "Deleting...";
    } else {
        return "Unknown status";
    }
}


    
const startFrom = (input, start) => {
    if (input) {
        start = +start; //parse to int
        return input.slice(start);
    }
    return [];
};

export { startFrom };


let hex = (c) => {
    const nh = (c >> 4),
        nl = (c & 0x0f);
    return String.fromCharCode(nh < 10 ? 48 + nh : 65 + nh - 10) +
        String.fromCharCode(nl < 10 ? 48 + nl : 65 + nl - 10);
};

const toHex32 = ( val ) => {    
    if (typeof val !== "number") {
        return null;
    } else {
        return "00000000" . substring(val.toString(16).length) + val.toString(16).toUpperCase();
    }
}


export { toHex32 };





const formatPayload = (str) => {
    try {
        var b = atob(str);
        var s = "",
            i;
        for (i = 0; i < b.length; i++) {
            // s += (i % 16 == 0) ? hex(i) + ": " : "";
            s += hex(b.charCodeAt(i));
            s += (i % 16 != 15) ? " " : "\n";
        }
        return s;
    } catch (e) {
        console.warn("Invalid data ", e)
        return "Invalid Data";
    }
}


export { formatPayload };

const sortByColumn = (array, column, reverse) => {
    var dir = reverse == true ? -1 : 1;

    return array.sort(function (a, b) {
        a = a[column];
        b = b[column];
        a = a == null ? "" : a;
        b = b == null ? "" : b;
        return a == b ? 0 : a > b ? dir : -dir;
    });
};


export { sortByColumn };


const changeDisplayOnClassName = (className, display) => {
    let elem:any = [];
    elem = document.getElementsByClassName(className);
    
    if(elem) {
        for (var j = elem.length - 1; j >= 0; j--) {
            
            elem[j].style.display = display;
        };
    }
}


export { changeDisplayOnClassName };


const getDataTableDisplayAttr = ( tabArr, tabname, id ) => {
    let display;
    if(tabArr.includes(tabname)) {
        display = 'none';
    }else if(id) {
        display = 'none';
    }else {
        display = 'block';
    }
    return display;
}

export { getDataTableDisplayAttr };


const getVisibleActions = (actions) => {
    const newActions = actions.filter((action) => (action.hasOwnProperty('visible') &&  action.visible() === true ) || action.hasOwnProperty('visible') === false )
    if(newActions && newActions.length) {
        return newActions
    }
    return null
}
export { getVisibleActions };

const actionIcon = (rowid: string, title?: string, buttonText?: IconName) => {
    if(title) {
        return formatText(`[[fa-${buttonText}]] ${title}`, rowid, { log: console.log }, null);    
    }
    return formatText(`[[fa-${buttonText}]] `, rowid, { log: console.log }, null);
  } 

  export { actionIcon }
