import React, { useEffect , useContext } from 'react';
import AppContext from "../context/AppContext";
import { useIdleTimer } from 'react-idle-timer'
import { IConstants } from "../types";
import { localSessionIdleTimeout, whoAmICheckTime} from '../utils/consts';
import { GenericDassQuery } from "../services/BasicDassQueries";
import { IWhoAmI } from 'src/dassTypes';

declare const constants: IConstants;

const Timer = () => {
    const appContextObj = useContext(AppContext);

    const handleOnIdle = () => {

        appContextObj.signOut();
    }

    const handleOnActive = event => {
//        console.log('user is active', event)
//        console.log('time remaining', getRemainingTime())
    }

    const handleOnAction = event => {
//        console.log('user did something', event)
    }

    const { getRemainingTime /*, getLastActiveTime*/ } = useIdleTimer({
        timeout: constants.sessionIdleTimeoutSec ? constants.sessionIdleTimeoutSec * 1000 : localSessionIdleTimeout,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 1000,
        crossTab: true,
        syncTimers: 1000,   // 1 seconds slack between the idle timer in separate tabs is ok
    })

    useEffect(() => {

        const debugTimer = setInterval(() => {
            appContextObj.setDebugMessage("IdleTimer", Math.round(getRemainingTime() / 1000) + "s");
        }, 10000);

        const timer = setInterval(() => {

            async function checkWhoami() {

                if (appContextObj.user?.userid) {
                    try {
                        const req = await GenericDassQuery("/whoami?caller=timer", { method: "GET", prefix: "", resigninOn401: false });
                        const whoAmI: IWhoAmI = req.data;
            
                        if (JSON.stringify(appContextObj.user) !== JSON.stringify(whoAmI.user)) {
                            console.log("Updated user on timer", {...appContextObj.user}, {...whoAmI.user})
                            appContextObj.updateUser(whoAmI.user);
                        }
                    } catch (e) {
                        if (e.status === 401) {
                            appContextObj.signOut();    
                        }
                    }
                }
            }

           checkWhoami();

        }, constants.whoAmICheckTime ?  constants.whoAmICheckTime : whoAmICheckTime);

        return () => {
            clearTimeout(debugTimer);
            clearTimeout(timer);
        };

    }, []);


    return(<></>)

}

export default Timer;