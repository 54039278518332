import React, { ReactNode } from "react";
import { Card, Collapse } from "react-bootstrap";

//ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
interface ICollapseCard {
	header: ReactNode;
	headerRight: ReactNode;
	body: ReactNode;
	id: string;
	className: string;
	state: boolean;	// true: visible/uncollapsed, false: collapsed
	collapsible: boolean;
	onFinishAnimation?: () => void,
	onChangeState?: () => void,
}

export const CollapseCard = (props: ICollapseCard) => {

	const click = () => {
		props.onChangeState();
	};

	if (props.collapsible) {
		return (
			<Card key={props.id} id={props.id} className={props.className} >
				<Card.Header className="d-flex justify-content-between" onClick={click}>
					{props.header}
					{props.headerRight && <div className="schema-engine-card-action-buttons">{props.headerRight}</div>}
					<a className="text-dark" onClick={click}>
						{props.state ? <i className="fa-regular fa-chevron-up fa-lg" /> : <i className="fa-regular fa-chevron-down fa-lg" />}
					</a>
				</Card.Header>
				<Collapse in={props.state} onEntered={props.onFinishAnimation} onExited={props.onFinishAnimation}>
					<Card.Body>
						{props.body}
					</Card.Body>
				</Collapse>
			</Card>
		);
	} else {
		return (
			<Card key={props.id} id={props.id} className={props.className} >
				<Card.Header className="d-flex justify-content-between">
					{props.header}
					{props.headerRight && <div className="schema-engine-card-action-buttons">{props.headerRight}</div>}
				</Card.Header>
				<Card.Body>{props.body}</Card.Body>
			</Card>
		);
	}
};

export default CollapseCard;
