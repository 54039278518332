import React, { useState } from 'react';
import MapContext from './MapContext'
import { IConstants } from "../types";
declare const constants: IConstants;
import { isMobile } from 'react-device-detect';

import { getAppBase } from "./../utils/consts";

interface IMarker {
    coordinates: [number, number];
    id: string;
}
    

interface IMapState {
    MarkersData: {
        DevicesData: any;
        GatewaysData: any;
        type:string;
    },
    Markers: {
        Gateways: any;
        Devices: any;
    };
    Draggable: boolean;
    ShowGateways: boolean;
    ShowDevices: boolean;
    GoToMarker: IMarker;
    map:any;
}

let globalMarkers = {};

const MapState = ( props ) => {
    const NetworkInitState = {
        Gateways: [],
        Devices: [],
        Draggable: false,
        ShowDevices: true,
        ShowGateways: true,
        FlyTo: null,
        OpenEditPositionModal: false,
        EditPositionModalData: null,
        TableFilterValue: "",
        operationalStatusFilter: "",
        TableData: [],
        LoggedUser: null,
        mapCenterLatitude: null,
        mapCenterLongitude: null,
        mapZoom: null,
        refresh:false,
        changed:false,
        apiResponseRecieved: false,
        map:null,
        currentTab:'devices',
        
    }

    const [searchValue, setSearchValue] = useState('');

    
    const [networkState, setNetworkState] = useState(NetworkInitState);
    
    const initState = {
        MarkersData: {
            DevicesData:[],
            GatewaysData:[],
            type:''
        },
        Markers: {
            Gateways: [],
            Devices: []
        },
        ShowDevices: false,
        ShowGateways: false,
        Draggable: false,
        GoToMarker: null,
        map:null,
        FlyTo: null,
        
    }

    const [mapState, setMapState] = useState<IMapState>(initState);

    const openMarkerPopup = ( coordinates, markerId ) => {

        try {

            let coordkey = getMarkerRefKey(coordinates, markerId);
                                    
            const marker = globalMarkers[coordkey];

                        if (marker) {
                    // if (!marker._icon) marker.__parent.spiderfy();
                    //document.getElementById(id).click()
                    marker.openPopup();

            }
            
        }catch(e) {
            console.log(e)
        }
    }

    const getMarkerRefKey = (position, markerId) => {
        
        let lat = String(position[0]).replace(/[\\.\\-\s]/g, "");
        let lng =  String(position[1]).replace(/[\\.\\-\s]/g, "");
        return lat + "-" + lng + "-" + markerId;
    }

    const updateGlobalMarkers = (position, markerRef, markerId) => {
        let key = getMarkerRefKey(position, markerId);
        
        let obj = {};
        
        obj[key] = markerRef;

            globalMarkers = {...globalMarkers, ...obj};
                }


    const updateGateWays = ( gateways ) => {
        
        setNetworkState(prevState => { return {...prevState, Gateways: gateways, TableData:gateways, changed: !networkState.changed, refresh:!networkState.refresh, apiResponseRecieved: true}});

    }

    
    const resetGlobalMarkers = () => {
        let obj = {};
        globalMarkers = {...obj};

    }

    const FlyToTheMarker = ( obj, type, navigate ) => {
        try {
            if(isMobile) {
                document.getElementById("slide-button").click();
            }
            
            
            if (obj && obj.latitude && obj.longitude) {


                const type = obj.hasOwnProperty("deveui") ? "Devices" : (obj.hasOwnProperty("deviceUuid")) ? "DmpDevices" : "Gateways";
                
                const { ShowDevices, ShowGateways } = networkState;
                let markerId =  obj.hasOwnProperty("deveui") ? obj.deveui : obj.hasOwnProperty("uuid") ? obj.uuid : (obj.hasOwnProperty("deviceUuid")) ? obj.deviceUuid : obj.id;
                

                if (((type === "Devices" || type === "DmpDevices") && ShowDevices) || ((type === "Gateways") && ShowGateways)) {
                    
                    setMapState(prevState => {
                        return {...prevState, FlyTo: { coordinates: [obj.latitude, obj.longitude], id: obj.hasOwnProperty("deveui") ? obj.deveui : obj.hasOwnProperty("uuid") ? obj.uuid : (obj.hasOwnProperty("deviceUuid")) ? obj.deviceUuid : obj.id},changed: !networkState.changed}
                    })
                        const setViewZoomLevel = 14
                        mapState.map.setView([obj.latitude, obj.longitude], setViewZoomLevel, {});
                        openMarkerPopup([obj.latitude, obj.longitude], markerId);    
                                        
                }
            } else {
                let id = obj.hasOwnProperty("deveui") ? obj.deveui : obj.hasOwnProperty("uuid") ? obj.uuid : (obj.hasOwnProperty("deviceUuid")) ? obj.deviceUuid : obj.id;
                let updateLink;
                let backUrl;
                if(type === "nst") {
                    updateLink =  `/nst/omc-gateways/${id}/gateway-info`;
                    backUrl = `${getAppBase()}/network_map`;
                } else if(type === "Device") { 
                    updateLink =  `/app/my-devices/${id}/device-detail`;
                    backUrl = `${getAppBase()}/network_map`;
                }  else if(type === "DmpDevices") { 
                    updateLink =  `/app/dmp-devices/${id}/device-detail`;
                    backUrl = `${getAppBase()}/network_map`;
                } else if(type === "Gateway") { 
                    updateLink =  `/app/my-gateways/${id}/gateway-info`;
                    backUrl = `${getAppBase()}/network_map`;
                }

                navigate(updateLink,{state: {prevPageUrl: backUrl, row: obj}});
            }
        }catch(e) {
            //console.log(e)
        }
    }


    let values = {
        networkState, 
        setNetworkState,
        mapState, 
        setMapState,
        FlyToTheMarker,
        updateGlobalMarkers,
        resetGlobalMarkers,
        globalMarkers,
        updateGateWays,
        searchValue, 
        setSearchValue,
        openMarkerPopup
    }
    

    return  (
        <MapContext.Provider value={values}>
            {props.children}
        </MapContext.Provider>
    )
}


export { MapState };

