
import React, { ReactNode, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { OverlayTriggerRenderProps } from "react-bootstrap/esm/OverlayTrigger";


//ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
interface IHoverOverlay {
	overlay: ReactNode;
    big: boolean;
    children:  React.ReactElement | ((props: OverlayTriggerRenderProps) => React.ReactNode);
    className?: string;
}

export const HoverOverlay = (props: IHoverOverlay) => {

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);

    return <OverlayTrigger
        placement="auto"
        delay={{ show: 250, hide: 400 }}
        trigger={["hover", "focus"]}
        overlay={
            <Popover 
                onClick={(ev) => ev.stopPropagation() }
                className={"schema-engine-help-popover" + (props.big ? " schema-engine-help-popover-big" : "") + (props.className ? ` ${props.className}` : "")} 
                onMouseEnter={() => setShow2(true)}
                onMouseLeave={() => setShow2(false)}
            >
                {props.overlay}
            </Popover>
        }
        show={show || show2}
        onToggle={(nextShow) => setShow(nextShow)}
    >
        {props.children}
    </OverlayTrigger>;

}
