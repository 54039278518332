import React, { ReactNode, useRef } from "react";
import { IUiSchemaElemArgs, IUiSchemaSetValueResult } from "./SchemaController";
import { IExprObjects, IExprScope, ISchemaLib, evalExpr, evalString } from "./SchemaTools";
import { registeredExtensionComponents, registeredExtensionTextMarkerHandlers } from "./SchemaExtensions";
import { AuditTranslation, translationMap } from "./SchemaTranslationAudit";




export interface ITextContext {

    lib: ISchemaLib;
    objects: IExprObjects;
    scope: IExprScope;
    args: IUiSchemaElemArgs;
    updateValues: (targetObj: IUiSchemaSetValueResult, keyPath: string, key: string) => void;

}

export interface ITextOptions {

    log: (...args: any[]) => void;
    debug?: boolean;

}




//     args: IUiSchemaElemArgs | null, key?: string) {


/**
 * formatText take a string of "rich" text and convert it into a react object
 * 
 * @param text 
 * @param key 
 * @param options 
 * @param context 
 * @returns 
 */
export function formatText(text: string, key: string, options: ITextOptions, context: ITextContext) {

    const open = "[[";
    const close = "]]";
    
    function findNext(pos: number) {
        const openIdx = text.indexOf(open, pos);
        const closeIdx = text.indexOf(close, pos);

        if (openIdx >= 0 && (closeIdx < 0 || closeIdx > openIdx)) { return { pos: openIdx, tok: open }; }
        if (closeIdx >= 0) { return { pos: closeIdx, tok: close }; }
        return null;
    }

    try {

        if (typeof text !== "string") { return null; }

        const idMatch = text.startsWith("[[@id") && text.match(/^\[\[@id([0-9]+[a-zA-Z0-9_.]*),([a-z])\]\]/);
        let orgText = text;
        
        if (idMatch) {        
            const id = idMatch[1];
            if (translationMap[id] != null) {
                text = orgText = translationMap[id];
            } else {
                text = orgText = text.substring(idMatch[0].length);
            }
        }


        if (text.startsWith("[[@md]]")) {
            const Markdown = registeredExtensionComponents["Markdown"];
            return Markdown ? <Markdown key={key} markdown={text.substring(7)}/> : text.substring(7);
        }

        const res: ReactNode[] = [];
        let idx = 0;



        for (;;) {

            let nest = 1;
            let pos = 0;
            const startTok = findNext(pos);
            if (startTok == null) {
                if (text) { res.push(text); }
                break;
            }

            if (startTok.tok === close) { throw new Error("unexpected closing " + close)}
            pos = startTok.pos + 2;
            let nextTok: { pos: number, tok: string } | null;
            do {
                nextTok = findNext(pos);;
                if (nextTok == null) { throw new Error("no closing " + close)}
                if (nextTok.tok === close) { nest--; }
                if (nextTok.tok === open) { nest++; }
                pos = nextTok.pos + 2;

            } while (nest > 0);

            if (text.substring(0, startTok.pos)) { res.push(text.substring(0, startTok.pos)); }
            for (const elem of convertTextMarkers(text.substring(startTok.pos + 2, nextTok.pos), 
                                                (key || context?.args?.key) + "mrk" + idx++,
                                                options, context)) {
                res.push(elem);
            }

            text = text.substring(nextTok.pos + 2);
        }

        if (idMatch) {
            const id = idMatch[1];
            const status = idMatch[2];
            return <AuditTranslation id={id} status={status} text={orgText}>{res}</AuditTranslation>;
        } else if (options?.debug) {
            return <InfoWrapper>{res}</InfoWrapper>;
        } else {
            return res;
        }
    } catch (e) {

        return <span style={{color: "red"}}><i className="fa-regular fa-bomb fa-fw" />Error in text: {e.message}</span>;

    }
}






/**
 * convertTextMarkers receive the content of a [[]] block. It parse the content, which is a 
 * comma separated list.
 * 
 * @param marker 
 * @param key 
 * @param options 
 * @param context 
 * @returns 
 */

export function convertTextMarkers(marker: string, key: string, options: ITextOptions, context: ITextContext): ReactNode[] {

    const attr = safeSplit(marker);
    let type: string = attr[0];
    const log = options?.log || (() => {});


    if (type === "@cond") {
        if (attr.length < 3) {
            log("@cond length too short ", attr);
            return [];
        }

        try {
            if (evalExpr(attr[1], context.lib, context.objects, context.scope, Error)) {
                attr.splice(0, 2);
                type = attr[0];
            } else {
                return [];
            }
        } catch (e: any) {
            log("@cond fail", e.message);
            return [];
        }
    }

    if (type === "@br") { return [<br key={key}/>]; }
    if (type === "@nbsp") { return [<span key={key}>&nbsp;</span>]; }


    function getStyle(arg: string) {
        const style: any = {};
        if (arg && arg.trim()) {
            const styleList = arg.trim().split(" ");
            for (const styleElem of styleList) {
                if (styleElem.trim()) {
                    const styleComp = styleElem.trim().split(":");
                    if (styleComp.length > 1) {
                        style[styleComp[0].trim()] = styleComp[1].trim();
                    } else {
                        style["color"] = styleElem.trim();
                    }
                }
            }
        }
        return style;
    }


    // extract 
    const tag = type.endsWith("-expr") ? type.substring(0, type.length - 5) : type;

    // Here we process block like this:
    // [[@md, text to show including potential commas]]
    if (["@md"].includes(tag)) {

        const rawbody = attr[attr.length - 1];
        const body = !type.endsWith("-expr")
                    ? evalString(rawbody, context.lib, context.objects, context.scope)
                    : evalExpr(rawbody, context.lib, context.objects, context.scope, Error);

        switch (tag) {
        case "@md": {
                const Markdown = registeredExtensionComponents["Markdown"];
                return Markdown ? [<Markdown key={key} markdown={body+""}/>] : [<p key={key}>{body+""}</p>];
            }
        }
    }

    // Here we process block like this:
    // [[@div, classnames, styles, 'text to show']]
    //
    //   The class names are directly the space separated class name from e.g. bootstrap or any custom classes that may be defined, e.g.:
    //		badge fw-light
    //
    //   The styles are optional. The format for the styles is like this:
    // 		color:red width:10px
    //   note, if the style is not a key/value pair separated by colon it is assumed the style is a color.
    //
    // Examples:
    //    [[@div, badge, color:red, this will be in a box]]
    //    [[@div-expr, badge, color:red, objects.values.showme]]
    //    [[@div, badge, color:red, '[[bell]']]
    //    [[@div, badge, color:red, '{{objects.values.showme}}']]
    //    [[@img, badge, color:red, 'https://image.com/image.png']]
    //    [[@img, badge, color:red, '({ src: https://image.com/image.png', width: "10px"})' ]]
    //

    const tags = ["@div", "@p", "@small", "@span", "@img", "@pre"];
    if (tags.includes(tag)) {

        if (attr.length < 3 || attr.length > 4) {
            log(type + " length wrong ", attr);
            return [];
        }
        const className = attr[1];
        const rawbody = attr[attr.length - 1];
        const style = getStyle(attr.length === 4 ? attr[2] : "");

        const body = !type.endsWith("-expr")
                    ? evalString(rawbody,  context.lib, context.objects, context.scope)
                    : evalExpr(rawbody,  context.lib, context.objects, context.scope, Error);

        switch (tag) {
        case "@div":   return [<div   key={key} style={style} className={className}>{formatText(body+"", key + ".sub", options, context)}</div>];
        case "@pre":   return [<pre   key={key} style={style} className={className}>{formatText(body+"", key + ".sub", options, context)}</pre>];
        case "@p":     return [<p     key={key} style={style} className={className}>{formatText(body+"", key + ".sub", options, context)}</p>];
        case "@small": return [<small key={key} style={style} className={className}>{formatText(body+"", key + ".sub", options, context)}</small>];
        case "@span":  return [<span  key={key} style={style} className={className}>{formatText(body+"", key + ".sub", options, context)}</span>];
        case "@img": {
                const imgargs = Object.assign({}, typeof body === "object" ? body : { src: body });
                return [<img key={key} style={style} className={className} {...imgargs} />]
            }
        }
    }


    // [[@action, text, tooltip, action in javascript ]]
    // The @action block is used to insert a link style action button anywhere in the text.
    //   The first argument is the text shown. This can contain sub block for symbols, etc.
    //   The second argument is the tooltip shown when hovering over the link. Can contain subblocks
    //   Finally the last argument is the javscript expression to evaluate when clocking on the link. The returned
    //   values are used similarely to any setValue functions.

    if (type === "@action") {

        if (attr.length !== 4) {
            log("@action length wrong ", attr);
            return [];
        }

        const text = evalString(attr[1], context.lib, context.objects, context.scope) + ""
        const textElems = formatText(text, key + ".sub", options, context);
        const tooltip = evalString(attr[2], context.lib, context.objects, context.scope) + ""
        

        return [
            <a href="#" key={key} title={tooltip} onClick={(ev) => {
                log("ok click action");
                ev.stopPropagation();
                try {
                    const expr = attr[3];
                    const targetObj: IUiSchemaSetValueResult = evalExpr(expr, context.lib, context.objects, context.scope, Error);
                    context.updateValues(targetObj, "", context?.args?.fullkey || key)
                } catch (e) {
                    log("@action failed", e);
                }
                ev.preventDefault();		// Avoid that href propagation
            }}>{textElems}</a>
        ];
    }

    // Handle standard font-awesome icons. fa- for regular, and fas- for solid.
    // e.g. 
    //  [fa-bell]
    //  [fa-triangle-exclamation,red]
    //
    if (type.startsWith("fa-")) {
        return [<i key={key} className={"fa-regular " + type + " fa-fw"} style={getStyle(attr[1])}/>];
    }
    if (type.startsWith("fas-")) {
        return [<i key={key} className={"fa-solid fa-" + type.substring(4) + " fa-fw"} style={getStyle(attr[1])}/>];
    }


    // Default to predefined array
    return (registeredExtensionTextMarkerHandlers[type] && 
            registeredExtensionTextMarkerHandlers[type](key, getStyle(attr[1])) as ReactNode[]) || [];
}





// safeSplit() breaks down a comma separated string, where each element can be a string with ', " or `.
function safeSplit(orgStr: string) {

	const args = [];
	let str = orgStr.trim();

	while (str) {
		const firstLetter = str.substring(0, 1);

		if (firstLetter === "'" || firstLetter === '"' || firstLetter === "`") {
			const end = str.indexOf(firstLetter, 1);
			if (end >= 0) {
				args.push(str.substring(1, end));
			} else {
				throw new Error("Expected closing " + firstLetter);
			}
			str = str.substring(end + 1).trim();
			if (str.startsWith(",")) {
				str = str.substring(1).trim();
			} else if (str !== "") {
				throw new Error("Expected , or end of string");
			}
		} else {
			const next = str.indexOf(",");
			args.push(next >= 0 ? str.substring(0, next) : str);
			str = next >= 0 ? str.substring(next + 1).trim() : "";
		}
	}
	return args;
}





const InfoWrapper : React.FC<{children: ReactNode}> = (props) =>  {

    const ref = useRef<HTMLSpanElement>();

    const click = () => {

        const elem = ref.current;
        while (elem?.firstChild !== elem?.lastChild) {
            elem.removeChild(elem.lastChild);
        }
        
        const style = window.getComputedStyle(elem);

        const path: HTMLElement[] = [];
        let pElem = elem;
        while (pElem) {
            path.push(pElem);
            pElem = pElem.parentElement;
        }

        console.log(elem.innerHTML, path.map(p => ({ type: p.tagName, id: p.id, class: p.className, p }) ));


        const textnode = document.createElement("span");
        textnode.innerHTML = style.fontSize;
        textnode.style.color = "red"
        textnode.style.fontSize = "8px";
        elem.appendChild(textnode);
    }


    return <span onClick={click} ref={ref}>{props.children}</span>;
}

