import React, { useState, useEffect, useRef } from 'react';

import AppContext, { IDassUiAppContext, INavbarState } from './AppContext'

import { appBaseUrl, } from '../utils/consts';
import { toast } from "../utils/Toaster";
import { IUser, IWhoAmI } from "../dassTypes"
import { toast as toastlib } from "react-toastify";
import { GenericDassQuery } from "../services/BasicDassQueries";
import { SignInForward, SelectAccount } from "../services/Login";

import { IConstants } from "../types";
import { PageStateType } from "../datatypes/datatypes";

import Cookies from "universal-cookie";
import { ISchemaModalHooks } from 'src/components/SchemaModal/SchemaModal';

declare const constants: IConstants;


const AppState = ( props ) => {

    const cookies = new Cookies();
    const pageInit = {
        totalRecords: 0,
        isLoading: false
    }
    const [user, setUser] = useState<IUser>(null as IUser);
    const userRef = useRef(null as IUser);
    const storageCtrl = useRef({ date: 0 });

    const debugObj = useRef({});
    const hooksRef = useRef<ISchemaModalHooks>({});

    const [recordCount, setRecordCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [pageState, setPageState] = useState<PageStateType>(pageInit);
    const [countLabel, setCountLabel] = useState<string>("");
    const [debugMessage, setDebugMessageRaw] = useState<string>("");

    const setDebugMessage = (key: string, message: string) => {

        if (constants.debugLevel > 0) {
            debugObj.current[key] = message;
            let str = "";
            let sep = "";
            for (const k of Object.keys(debugObj.current).sort()) {
                if (debugObj.current[k]) {
                    str += sep + k + ": " + debugObj.current[k];
                    sep = " | ";
                }
            }
            setDebugMessageRaw(str);
        }
    }
    


    const isForwardedSignIn = () => {
        return user?.forwarding_userid ? true : false;
    }

    const navStateInit = {
        forwardedSignIn: isForwardedSignIn(),
        language: cookies.get("language") ? cookies.get("language") : (constants.languages ? constants.languages[0] : ""),
        numMenusVisible: 2,
        originalUser: isForwardedSignIn() ? user.forwarding_userid : '',
        profiles: false,
        signedIn: false,
        user: null,
        userid_name: "",
        helpdeskMailto: "",
        menu: "",
        click: "",
        smallScreen:false,
    };


    const [navBarState, setNavBarState] = useState<INavbarState>(navStateInit);

    const signOut = () => {
        updateUser({} as IUser);
        localStorage.removeItem("currentGroup");
        localStorage.removeItem("websocket_status");
        deleteIndexedDB("Log")
        window.location.href = appBaseUrl + '/signout';
    }

    const retrnToOldUser = async () => {
        const isNst = (String(location.pathname).indexOf('/nst'));
        const user = await SignInForward("", appBaseUrl);
        window.location.href = isNst === -1 ? '/' : '/nst/network_map';
        updateUser(user);
    }

    const UserSettings = async () => {
        setNavBarState(prevState => {return {...prevState,
            ShowUserSettingsModal: true,
        }});
    }

    const isSignedIn = (user: IUser) => {
        if(user && user.userid) {
            return true;
        }else {
            return false;
        }
    }


    const signInForward = async () => {
        try {
            await SignInForward(undefined, appBaseUrl);
            localStorage.removeItem("currentGroup");
            window.location.href = constants.landing_page_after_signin || "/";
        } catch (error) {
            console.log(error);
            //toast.error(strings.CAN_NOT_RETURN_TO_PARENT);
        }
    }


    const selectAccount = async (accountId: string) => {
        try {
            const user = await SelectAccount(accountId, appBaseUrl);
            localStorage.removeItem("currentGroup");
            updateUser(user);


//            window.location.href = constants.landing_page_after_signin || "/";
        } catch (error) {
            console.log(error);
            //toast.error(strings.CAN_NOT_RETURN_TO_PARENT);
        }
    }


    const changeLanguage = (event) => {

        cookies.set("language", event.target.id, { path: "/" });
        location.reload();
    }


    const updateUser = (usr: IUser, signal = false) => {

        if (JSON.stringify(userRef.current) !== JSON.stringify(usr)) {

//            console.log(new Error().stack);
//            console.log("updating User old=", JSON.stringify(userRef.current), "new=", JSON.stringify(usr))


            setUser(usr);
            userRef.current = usr;

            if (signal) {
                storageCtrl.current.date = Date.now();
                localStorage.setItem("signal", String(storageCtrl.current.date + "," + (usr?.userid ? "si" : "so")));
                // console.log("Updated signal", String(storageCtrl.current.date + "," + (usr?.userid ? "si" : "so")))
            }

            
            hooksRef.current?.updateValues?.({ whoami: usr });
            setNavBarState(prevState => {
                return {
                    ...prevState,
                    forwardedSignIn: usr?.forwarding_userid ?  true : false,
                    originalUser: usr?.forwarding_userid || "",
                }
            }) 
        }
    }


    // Function to Delete IndexedDB Log when signout
    const deleteIndexedDB = (databaseName: string ) => {
        var request = indexedDB.deleteDatabase(databaseName);
        
        request.onerror = function (event) {
            console.error("Error deleting database");
        };
        
        request.onsuccess = function (event) {
            // console.log("Database deleted successfully");
        };
        
        request.onblocked = function (event) {
            console.log("Couldn't delete database due to the operation being blocked");
        };
    };


    // FIXME: this logic is wrong. The clearTimeout statements are clearly
    // not doing what they are supposed to do.

    // checking the session by the getting session info
    const SessionCheck = (time = 1000) => setTimeout(GetSessionInfo, time);

    // get session info from the server, that will return state and expiration time of the session.
    // check the state and time, As per condition clear the session timeout or logout the user
    const GetSessionInfo = async () => {
        try {
            const req = await GenericDassQuery("/session_info", { method: "GET", prefix: "" });
            const { isActive, maxAge } = await req.data;
            if (!isActive || (maxAge < 10000)) {
                signOut();
            } else {
                clearTimeout(SessionCheck());
                SessionCheck(maxAge - 5000);
            }
        } catch (error) {
            console.log(error);
            SessionCheck();
        }
    }

    const GetCurrentSessionInfo = async () => {
        try {
            const req = await GenericDassQuery("/session_info", { method: "GET", prefix: "" });
            const { isActive, maxAge } = req.data;
            return { maxAge: maxAge, isActive: isActive }
        } catch (error) {
            return null
        }
    }

    let systemErrorToast: any;

    const CheckSystemErrorPeriod = 30000;

    let CheckSystemErrorTimer: NodeJS.Timeout;

    let lastToastMessage = "";

    const CheckSystemError = async () => {
        let systemErrorText = "";
        try {
            const req = await GenericDassQuery("/dass_info", { method: "GET", prefix: "" });
            const dassinfo = req.data;
            systemErrorText = dassinfo.systemErrorTxt;
        } catch (e) {
            systemErrorText = "System unreachable";
        }

        if ((systemErrorToast && toastlib.isActive(systemErrorToast)) &&
            (lastToastMessage !== systemErrorText || !systemErrorText)) {
            // Toast is open but message is not the same or the alarm is over, we close the toast
            toastlib.dismiss(systemErrorToast);
            systemErrorToast = null;
        }

        if (systemErrorText) {
            if (systemErrorToast == null || !toastlib.isActive(systemErrorToast)) {
                lastToastMessage = systemErrorText;
                systemErrorToast = toast.error(systemErrorText, { position: "top-right" });
            }
        }

        if (systemErrorText != null) {
            // we only continue to check with the timer if the systemErrorText is non null.
            // if it is null it means the field is not pressent and we can stop checking.
            CheckSystemErrorTimer = setTimeout(() => CheckSystemError(), CheckSystemErrorPeriod);
        }
    }

    const forwardLoginCheck = async (userData: IWhoAmI) => {
        const user = navBarState.user;
        try {
            if (isSignedIn(user)) {
                const users = userData.loginuserid.split("/");
                if (users.length > 1) {
                    setNavBarState(prevState => { return {...prevState,  forwardedSignIn: true }});
                } else {
                    setNavBarState(prevState => {return {...prevState, forwardedSignIn: false }});
                }
            }
        } catch (error) {
            toast.error(error);
        }
    }



    const emailHelpdesk = async () => {
        try {

            const res = await GenericDassQuery("/rest/users/$?_get_helpdesk_email=true");
            const set = res.data;
            setNavBarState(prevState => {return {...prevState, helpdeskMailto: set.mailto }});
        } catch (e) {
            console.log("Can't get helpdesk email", e.message);
        }
    }

    const accountType = (user: IUser) => {
        if (user?.loginid) { return "login"; }
        if (user?.is_customer) { return "customer"; }
        if (user?.is_organisation ) { return "organisation"; }
        if (user?.accountid ) { return "application"; }
        return "user";
    }

    const formatName = (userid: string) => {
        const IsEmail = /@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (IsEmail.test(userid)) {
            return userid.substring(0, userid.lastIndexOf("@"));
        } else {
            return userid;
        }
    }

    const loadUser = async (signal = true) => {

        try {
            const req = await GenericDassQuery("/whoami?caller=load", {
                method: "GET", prefix: "", resigninOn401: false,
            });
            const whoAmI: IWhoAmI = req.data;
            const user = whoAmI.user;
            const loginUserId = whoAmI.loginuserid;

            if (user?.userid) {

                if (user._environment?.helpdesk_email_template_id) {
                    emailHelpdesk();
                }
                SessionCheck();
                setNavBarState(prevState => {return {...prevState,
                    menu: window.location.pathname,
                    userid_name: formatName(user.userid),
                    originalUser: formatName(loginUserId.split("/")[0]),
                    profiles: user.can_list_device_profile  || user.can_list_service_profile ||
                            user.can_list_channel_profile || user.can_list_connectivity_profile ||
                            user.can_list_roaming_profile || user.can_list_qos_profile,
                    signedIn: true,
                    user,
                }});

                updateUser(user, signal);
                forwardLoginCheck(whoAmI);

                return user;
            }

        } catch (e) {

            if (e.status === 401) {
                setNavBarState(prevState => {return {...prevState,
                    menu: window.location.pathname,
                    userid_name: null,
                    originalUser: null,
                    profiles: null,
                    signedIn: false,
                    user: null,
                }});
                updateUser({} as IUser, signal);
            }
        }

        return {} as IUser;
    }


    // const updateGState = ( key, value ) => {
    //     setGState(prevState => { 
    //         return {...prevState, [key]: value} 
    //         }
    //     );
    // }

    const onStorageUpdate = () => {
        try {
            const [time, status] = (localStorage.getItem("signal") || "").split(",");

            if (parseInt(time || "0") > storageCtrl.current.date) {
                console.log("Got signal, new date, ", time, status)

                if (status === "so" && userRef.current?.userid) {
                    console.log("Got SO, currently signed in");
                    setTimeout(() => loadUser(false), 2000);
                } else if (status === "si" && !userRef.current?.userid) {
                    console.log("Got Si, currently signed out");
                    setTimeout(() => loadUser(false), 2000);
                }
            } else {
                console.log("Got signal, old date - ignoring", time, status)
            }

        } catch (e) {
            console.log(e.message);
        }
    };


    useEffect(() => {

        window.addEventListener("storage", onStorageUpdate);
        return () => {
          window.removeEventListener("storage", onStorageUpdate);
        };

    }, []);

    const values: IDassUiAppContext = {
        navbarSchemaHook: hooksRef.current,
        user: null, 
        updateUser,
        recordCount,
        setRecordCount,
        isLoading,
        setIsLoading,
        pageState,
        setPageState,
        navBarState,
        signOut,
        UserSettings,
        signInForward,
        changeLanguage,
        isSignedIn,
        loadUser,
        setNavBarState,
        accountType,
        CheckSystemErrorTimer,
        SessionCheck,
        GetCurrentSessionInfo,
        CheckSystemError,
        countLabel,
        setCountLabel,
        selectAccount,
        isForwardedSignIn,
        retrnToOldUser,
        debugMessage,
        setDebugMessage,
    }

    Object.defineProperty(values, 'user', {
        get: () => userRef.current,
        enumerable: true,
    });


    return  (
        <AppContext.Provider value={values}>
            {props.children}
        </AppContext.Provider>
    )
}


export default AppState;