import { strings } from "./../../services/Localization";

import { BreadCrumbType, DetailPageActionType, DetailPagePropsType } from '../../datatypes/datatypes';

import { faAdd } from '@fortawesome/free-solid-svg-icons'

import { getAppBase } from '../../utils/consts';

import {
        TAB_ADD_LOGIN, 
        TAB_EDIT_LOGIN, 
        TAB_ADD_USER, 
        TAB_EDIT_USER, 
        TAB_ADD_CUSTOMER, 
        TAB_EDIT_CUSTOMER, 
        TAB_ORGANIZATION_LIST_ADD_ORGANIZATION,
        TAB_EDIT_ORGANIZATION } from '../../datatypes/tabsconstants';

const detailPageNav = ( navigate, tab, id, row, prevPageUrl) => {

    if(id) {
       
        navigate(`${getAppBase()}/accounts/users/${id}/${tab}`, {state: {row: row, prevPageUrl:prevPageUrl}})
    }else {
        
        navigate(`${getAppBase()}/accounts/users/${tab}`, {state: {tab: tab, prevPageUrl:prevPageUrl}})
    }

}




const AccountsLoginProps:DetailPagePropsType =  {
    getBreadCrumbArr: ( breadCrumbArgs ) => {
        const { tabname } = breadCrumbArgs
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : ``;
        const breadCrumb:BreadCrumbType[] = [
            {label: strings.ACCOUNTS, url: prevPageUrl}, {label: strings.NAV_LOGINS_LIST_USERS, url:prevPageUrl}
        ]
        if ([TAB_ADD_LOGIN].includes(tabname)) {
            breadCrumb.push({ label: strings.ADD_LOGIN, url: '' })
        }
        
        return breadCrumb;

    },
    getActions : (actionArgs, navigate) => {
        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : ``;
        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.ADD_LOGIN,
                icon: faAdd,
                key: TAB_ADD_LOGIN,
                action: (row) => detailPageNav(navigate, TAB_ADD_LOGIN, row.id, row, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/logins?get_schema=dassui,post`,
                    backUrl: '/app/accounts/logins'
                },
            },
        ]
        return actions;
    }
}


export { AccountsLoginProps };





const AccountsRowLoginProps: DetailPagePropsType =  {
    getBreadCrumbArr: ( breadCrumbArgs ) => {
        const row = breadCrumbArgs.row;
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : ``;
        const breadCrumb:BreadCrumbType[] = [
            {label: strings.ACCOUNTS, url: prevPageUrl}, {label: strings.NAV_LOGINS_LIST_USERS, url:prevPageUrl}
        ]
        if(row && row.userid) {
            breadCrumb.push({ label: `${row.userid}`, url: '' })
        }
        return breadCrumb;
    },
    getActions : (actionArgs, navigate) => {
        const { row } = actionArgs;
        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : `${getAppBase()}/accounts/users`;
        const actions:DetailPageActionType[] =  [
            {
                type: "tab",
                text: strings.EDIT_LOGIN,
                icon: faAdd,
                key: TAB_EDIT_LOGIN,
                action: () => detailPageNav(navigate, TAB_EDIT_LOGIN, row.id, row, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/logins?get_schema=dassui,put`,
                    backUrl: prevPageUrl
                },
                EditObject: row.userid ? { userid: row.userid } : {userid: row.id}
            },
        ]

        return actions;
    }
}


export { AccountsRowLoginProps };





const AccountsUserProps:DetailPagePropsType =  {
    getBreadCrumbArr: (breadCrumbArgs) => {
        const { tabname } = breadCrumbArgs;
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : ``;
        const breadCrumb:BreadCrumbType[] = [
            {label: strings.ACCOUNTS, url: `${getAppBase()}/accounts/users`}, {label: strings.NAV_USERS_LIST_USERS, url:prevPageUrl}
        ]

        if ([TAB_ADD_USER].includes(tabname)) {
            breadCrumb.push({ label: strings.ADD_ACCOUNT, url: '' })
        }
        return breadCrumb;
    },
    getActions : (actionArgs, navigate) => {
        
        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : `${getAppBase()}/accounts/users`;

        const actions:DetailPageActionType[] =  [
            {
                type: "tab",
                text: strings.ADD_USER,
                icon: faAdd,
                key: TAB_ADD_USER,
                action: (row) => detailPageNav(navigate, TAB_ADD_USER, row.id, row, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/users?get_schema=dassui,post`,
                    backUrl: prevPageUrl
                },
            },
        ]

        return actions;
    }
}


export { AccountsUserProps };



const AccountsRowUserProps: DetailPagePropsType =  {
    getBreadCrumbArr: ( breadCrumbArgs ) => {
        const row = breadCrumbArgs.row;
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : ``;
        const breadCrumb:BreadCrumbType[] = [
            {label: strings.ACCOUNTS, url: prevPageUrl}, {label: strings.NAV_USERS_LIST_USERS, url:prevPageUrl}
        ]

        if(row && row.userid) {
            breadCrumb.push({ label: `${row.userid}`, url: '' })
        }
        return breadCrumb;
    },
    getActions : (actionArgs, navigate) => {
        const { row } = actionArgs;
        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : `${getAppBase()}/accounts/users`;
        const actions:DetailPageActionType[] =  [
            {
                type: "tab",
                text: strings.EDIT_USER,
                icon: faAdd,
                key: TAB_EDIT_USER,
                action: () => detailPageNav(navigate, TAB_EDIT_USER, row.id, row, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/users?get_schema=dassui,put`,
                    backUrl: prevPageUrl
                },
                EditObject: row.userid ? { userid: row.userid } : {userid: row.id}
            },
        ]

        return actions;
    }
}


export { AccountsRowUserProps };



const AccountsCustProps:DetailPagePropsType =  {
    getBreadCrumbArr: ( breadCrumbArgs ) => {
        const { tabname } = breadCrumbArgs;
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : ``;
        const breadCrumb:BreadCrumbType[] = [
            {label: strings.ACCOUNTS, url: prevPageUrl}, {label: strings.NAV_CUSTOMERS_LIST_CUSTOMERS, url:prevPageUrl}
        ]
        if ([TAB_ADD_CUSTOMER].includes(tabname)) {
            breadCrumb.push({ label: strings.ADD_ACCOUNT, url: '' })
        }
        return breadCrumb;

    },
    getActions : (actionArgs, navigate) => {
        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : ``;
        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.ADD_CUSTOMER,
                icon: faAdd,
                key: TAB_ADD_CUSTOMER,
                action: (row) => detailPageNav(navigate, TAB_ADD_CUSTOMER, row.id, row, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/customers?get_schema=dassui,post`,
                    backUrl: '/app/accounts/customers'
                },
            },
        ]
        return actions;
    }
}


export { AccountsCustProps };



const AccountsRowCustProps:DetailPagePropsType =  {
    getBreadCrumbArr: (breadCrumbArgs) => {
        const row = breadCrumbArgs.row;
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : `${getAppBase()}/accounts/customers`;

        const breadCrumb:BreadCrumbType[] = [
            {label: strings.ACCOUNTS, url: prevPageUrl}, {label: strings.NAV_CUSTOMERS_LIST_CUSTOMERS, url:prevPageUrl}
        ]
        if(row && row.userid) {
            breadCrumb.push({ label: `${row.userid}`, url: '' })
        }
        return breadCrumb;
    },
    getActions : (actionArgs, navigate) => {
        const { row } =  actionArgs;
        
        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : `${getAppBase()}/accounts/customers`;

        const actions:DetailPageActionType[] = [
            {
                type: "tab",
                text: strings.EDIT_CUSTOMER,
                icon: faAdd,
                key: TAB_EDIT_CUSTOMER,
                action: (row) => detailPageNav(navigate, TAB_EDIT_CUSTOMER, row.id, row, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/customers?get_schema=dassui,put`,
                    backUrl: prevPageUrl
                },
                EditObject: row.userid ? { userid: row.userid } : null
            },
        ]

        return actions;
    }
}


export { AccountsRowCustProps };




const AccountsOrgProps:DetailPagePropsType =  {
    getBreadCrumbArr: (breadCrumbArgs) => {
        const { tabname } = breadCrumbArgs;
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : `${getAppBase()}/accounts/organisations`;

        const breadCrumb:BreadCrumbType[] = [
            {label: strings.ACCOUNTS, url:prevPageUrl}, {label: strings.NAV_ORGANIZATION_LIST_USERS, url:prevPageUrl}
        ]

        if ([TAB_ORGANIZATION_LIST_ADD_ORGANIZATION].includes(tabname)) {
            breadCrumb.push({ label: strings.ADD_ACCOUNT, url: '' })
        }

        return breadCrumb;
    },
    getActions : (actionArgs, navigate) => {
        
        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : `${getAppBase()}/accounts/organisations`;

        const actions:DetailPageActionType[] =  [        
            {
                type: "tab",
                text: strings.ORGANIZATION_LIST_ADD_ORGANIZATION,
                icon: faAdd,
                key: TAB_ORGANIZATION_LIST_ADD_ORGANIZATION,
                action: (row) => detailPageNav(navigate, TAB_ORGANIZATION_LIST_ADD_ORGANIZATION, '', row, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/organisations?get_schema=dassui,post`,
                    backUrl: prevPageUrl
                },
            },
        ]
        return actions;
    }
}


export { AccountsOrgProps };



const AccountsRowOrgProps:DetailPagePropsType =  {
    getBreadCrumbArr: (breadCrumbArgs) => {
        const row = breadCrumbArgs.row;
        let prevPageUrl = breadCrumbArgs.hasOwnProperty('prevPageUrl') ? breadCrumbArgs['prevPageUrl'] : `${getAppBase()}/accounts/organisations`;

        const breadCrumb:BreadCrumbType[] = [
            {label: strings.ACCOUNTS, url:prevPageUrl}, {label: strings.NAV_ORGANIZATION_LIST_USERS, url:prevPageUrl}
        ]
        if(row && row.userid) {
            breadCrumb.push({ label: `${row.userid}`, url: '' })
        }
        return breadCrumb;
    },
    getActions : (actionArgs, navigate) => {
        const { row } = actionArgs;

        let prevPageUrl = actionArgs.hasOwnProperty('prevPageUrl') ? actionArgs['prevPageUrl'] : `${getAppBase()}/accounts/organisations`;

        const actions:DetailPageActionType[] =  [    
            {
                type: "tab",
                text: strings.EDIT_ORGANIZATION,
                icon: faAdd,
                key: TAB_EDIT_ORGANIZATION,
                action: (row) => detailPageNav(navigate, TAB_EDIT_ORGANIZATION, row.id, row, prevPageUrl),
                content: {
                    type: 'SchemaModal',
                    url: `/rest/organisations?get_schema=dassui,put`,
                    backUrl: prevPageUrl
                },
                EditObject: row.userid ? { userid: row.userid } : null
            },
        ]
        return actions;
    }
}


export { AccountsRowOrgProps };