// Utility functions for session storage

/**
 * Retrieves data from session storage by key.
 * @param key - The key to retrieve the data from session storage.
 * @returns The parsed data from session storage or null if not found or error occurs.
 */
export const getDataFromSessionStorage = (key: string): any | null => {
  try {
      const item = sessionStorage.getItem(key);
      if (item) {
          return JSON.parse(item);
      }
  } catch (error) {
      console.error(`Error parsing session storage item for key "${key}":`, error);
  }
  return null;
};

/**
* Sets data to session storage.
* @param key - The key to set the data in session storage.
* @param value - The value to be stored.
*/
export const setDataToSessionStorage = (key: string, value: any): void => {
  try {
      sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
      console.error(`Error setting session storage item for key "${key}":`, error);
  }
};

/**
* Removes data from session storage by key.
* @param key - The key to remove from session storage.
*/
export const removeDataFromSessionStorage = (key: string): void => {
  try {
      sessionStorage.removeItem(key);
  } catch (error) {
      console.error(`Error removing session storage item for key "${key}":`, error);
  }
};

/**
* Clears all data from session storage.
*/
export const clearSessionStorage = (): void => {
  try {
      sessionStorage.clear();
  } catch (error) {
      console.error("Error clearing session storage:", error);
  }
};