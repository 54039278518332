

import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Button, Container, Dropdown, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { registerComponentHandler, registerExtensionLibFunction } from "./SchemaExtensions";
import { IUiSchemaElemArgs } from "./SchemaController";

import DatePicker, {  } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./ExtDateRangePicker.css"


import { parseRelativeDateTimeString, toDateTimeString } from "./ParseRelativeDateTimeString";

interface Range {
    title: string;
    start: string;
    end: string;

    startDate?: Date;
    endDate?: Date;
}

const ranges: Range[] = [
    { title: "Last 5 min",           start: "now-5m",   end: "now" },
    { title: "Last 15 min",          start: "now-15m",  end: "now" },
    { title: "Last 30 min",          start: "now-30m",  end: "now" },
    { title: "Last 1 hour",          start: "now-1h",   end: "now" },
    { title: "Last 3 hours",         start: "now-3h",   end: "now" },
    { title: "Last 6 hours",         start: "now-6h",   end: "now" },
    { title: "Last 12 hours",        start: "now-12h",  end: "now" },
    { title: "Last 24 hours",        start: "now-1d",   end: "now" },
    { title: "Last 2 days",          start: "now-2d",   end: "now" },
    { title: "Last 7 days",          start: "now-7d",   end: "now" },
    { title: "Last 30 days",         start: "now-30d",  end: "now" },
    { title: "Last 90 days",         start: "now-90d",  end: "now" },
    { title: "Last 6 months",        start: "now-6M",   end: "now" },
    { title: "Last 1 year",          start: "now-1y",   end: "now" },
    { title: "Last 2 years",         start: "now-2y",   end: "now" },
    { title: "Last 5 years",         start: "now-5y",   end: "now" },
    { title: "Yesterday",            start: "now-1d/d", end: "now-1d/d" },
    { title: "Day before yesterday", start: "now-2d/d", end: "now-2d/d" },
    { title: "This day last week",   start: "now-7d/d", end: "now-7d/d" },
    { title: "Previous week",        start: "now-1w/w", end: "now-1w/w" },
    { title: "Previous month",       start: "now-1M/M", end: "now-1M/M" },
    { title: "Previous year",        start: "now-1y/y", end: "now-1y/y" },
    { title: "Today",                start: "now/d",    end: "now/d" },
    { title: "Today so far",         start: "now/d",    end: "now"   },
    { title: "This week",            start: "now/w",    end: "now/w" },
    { title: "This week so far",     start: "now/w",    end: "now"   },
    { title: "This month",           start: "now/M",    end: "now/M" },
    { title: "This month so far",    start: "now/M",    end: "now"   },
    { title: "This year",            start: "now/y",    end: "now/y" },
    { title: "This year so far",     start: "now/y",    end: "now"   },
]




interface IDateRangePicker {
    range: Range;
    onChange: (range: Range) => void;
    title: string | ReactNode;
    className?: string;
}



export const DateRangePicker = (props: IDateRangePicker) => {

    const [startText,   setStartText] = useState("");
    const [endText,     setEndText]   = useState("");
    const [startTextErr,  setStartTextErr] = useState("");
    const [endTextErr,    setEndTextErr]   = useState("");

    const [showMenu,    setShowMenu]    = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    const hover    = useRef(false);
    const propsRef = useRef(props);
    const dateRef  = useRef({
        startDate: new Date(),
        endDate: new Date(),
    })

    propsRef.current = props;


    const setRange = (range: Range) => {

        const { start, end } = range;
        const ref = dateRef.current;

        setStartText(start);
        setEndText(end);

        const dateObj = new Date();
        const tzo = dateObj.getTimezoneOffset();
        const now = dateObj.getTime();

        try {
            const startDate = parseRelativeDateTimeString(start, false, now, tzo);
            if (Number.isNaN(startDate.getTime())) { throw new Error("Invalid start date"); }

            ref.startDate = startDate;
            setStartTextErr("");
        } catch (e) {
            setStartTextErr(e.message);
            ref.startDate = new Date(NaN);
        }

        try {
            const endDate = parseRelativeDateTimeString(end, true, now, tzo);
            if (Number.isNaN(endDate.getTime())) { throw new Error("Invalid end date"); }
            ref.endDate = endDate;
            setEndTextErr("");
        } catch (e) {
            setEndTextErr(e.message);
            ref.endDate = new Date(NaN);
        }

        if (ref.startDate instanceof Date && !isNaN(ref.startDate.getTime()) && ref.endDate instanceof Date && !isNaN(ref.endDate.getTime())) {
            if (ref.startDate > ref.endDate) {
                setEndTextErr("End time earlier then start time");
            }
        }

    }

    useEffect(() => {
        setRange({ start: propsRef.current.range.start || "now-1h", end:  propsRef.current.range.end || "now", title: propsRef.current.range.title || "" });
    }, []);

    useEffect(() => {
        setRange({ start: propsRef.current.range.start || "now-1h", end:  propsRef.current.range.end || "now", title: propsRef.current.range.title || "" });
    }, [props]);


    const onChange = (dates: [Date, Date]) => {

        const start = dates[0] ? new Date(dates[0]) : null;
        const end   = dates[1] ? new Date(dates[1]) : null;
        start?.setHours(0, 0, 0, 0);
        end?.setHours(23, 59, 59, 999);

        // setRange({ start: toDateTimeString(start), end: toDateTimeString(end), title: null });
        const startText = toDateTimeString(start);
        const endText   = toDateTimeString(end);


        setStartText(startText);
        setEndText(endText);
        setStartTextErr("");
        setEndTextErr(endText ? "" : "Missing end time");

        dateRef.current.startDate = start;
        dateRef.current.endDate = end;

        if (startText && endText) {
            setRange({ start: startText, end: endText, title: null });
        }
    };


    const selectRange = (range: Range) => {

        try {
            setRange(range);

            let title = ranges.find(p => p.start === (range.start || "") && p.end === (range.end || ""))?.title;

            let m = title == null && range.start?.match(/^now-([0-9]+)([mhdMy])$/);
            if (range.end === "now"  &&  m) {
                title = "Last " + m[1] + " " + { m: "min", h: "hours", d: "days", M: "months", y: "years"}[m[2]];
            }

            props.onChange({ ...range, 
                startDate: dateRef.current.startDate,
                endDate: dateRef.current.endDate,
                title: title || range.title || (range.start || "") + " - " + (range.end || "")
             })
            setShowMenu(false);
            setShowTooltip(false);

        } catch (e) {
            console.log(e);
        }
    }

    return (

        <Dropdown className={props.className} show={showMenu} onToggle={(d) => {setShowMenu(d); setShowTooltip(false); }}>

            <OverlayTrigger
                onToggle={(d) => setShowTooltip(!showMenu && hover.current && d)}
                show={showTooltip}
                delay={{ show: 500, hide: 400 }}
                placement={"bottom"}
                overlay={<Tooltip >
                        {dateRef.current.startDate?.toLocaleString() || ""}<br/>to<br/>{dateRef.current.endDate?.toLocaleString() || ""}
                    </Tooltip>
                }
            >
                <Dropdown.Toggle variant="outline-dark" onMouseEnter={() => hover.current = true} onMouseLeave={() => hover.current = false} >
                    <i className="fa-regular fa-clock"></i> {props.title}
                </Dropdown.Toggle>
            </OverlayTrigger>

                <Dropdown.Menu show={showMenu}>
    
                    <Container className="schema-engine-date-range-picker-container">
                        <div className="schema-engine-date-range-picker-left-container">
                                <div className="m-2">
                                    Absolute Range:<br />
                                    <DatePicker className="m-2"
                                        onChange={onChange}
                                        startDate={dateRef.current.startDate}
                                        endDate={dateRef.current.endDate}
                                        disabledKeyboardNavigation
                                        selectsRange
                                        inline
                                    />

                                    <Form>
                                        <Form.Group className="mb-2">
                                            <Form.Label>From:</Form.Label>
                                            <Form.Control 
                                                value={startText}
                                                isInvalid={!!startTextErr}
                                                onChange={(evt) => setRange({ start: evt.target.value as string, end: endText, title: null })}
                                            />
                                            <Form.Text>{startTextErr}</Form.Text>
                                        </Form.Group>

                                        <Form.Group className="mb-2">
                                            <Form.Label>To:</Form.Label>
                                            <Form.Control 
                                                value={endText}
                                                isInvalid={!!endTextErr}
                                                onChange={(evt) => setRange({ start: startText, end: evt.target.value as string, title: null })}
                                            />
                                            <Form.Text>{endTextErr}</Form.Text>
                                        </Form.Group>

                                        <Button variant="outline-dark"
                                            disabled={!!(startTextErr || endTextErr)}
                                            onClick={() => selectRange({ title: "", start: startText, end: endText })}
                                        >
                                            Apply
                                        </Button>

                                    </Form>

                                </div>     
                        </div>

                        <div className="m-2">
                            <div className="schema-engine-date-range-picker-right-container">
                                {ranges.map(range => (
                                    <Dropdown.Item key={range.title} onClick={(a) => selectRange(range)}>
                                        {range.title}
                                    </Dropdown.Item>
                                ))}
                            </div>
                        </div>
                    </Container>
                </Dropdown.Menu>
        </Dropdown>
    );
};



const dateRangePickerExt = (args: IUiSchemaElemArgs) => {

	const { fullkey, value, update } = args;

    return args.embedObject(<DateRangePicker
        key={fullkey}
        range={value}
        title={value?.title || ""}
        onChange={(data: Range) => update({ value: data })}
    />);

};

registerComponentHandler("date-range-picker", dateRangePickerExt);
registerExtensionLibFunction("parseRelativeDateTimeString", parseRelativeDateTimeString);

export default DateRangePicker;






