import React from "react";

// import ProgressBar from "react-step-progress-bar";
import { IUiSchemaElemArgs } from './SchemaController';

import { ProgressBar } from "react-bootstrap";
import { registerComponentHandler } from "./SchemaExtensions";



const progressBarExtension = (args: IUiSchemaElemArgs) => {

	const { value } = args;
	const now      = (value == null ? 0 : typeof value === "number" ? value : value.percent) || 0;
	const labeltxt = (value && typeof value === "object" && value.label) || null;
	const label    = labeltxt ? args.stringToComponent(labeltxt) : null;

	return (args.embedObject(<ProgressBar
		key={args.fullkey}
		className="mb-2" now={now}
		label={label}
	/> ));
};


registerComponentHandler("progressbar", progressBarExtension);
