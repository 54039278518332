import React  from "react";

import { PageButtonsComponent} from './PageButtons';

import { BreadcrumbComponent } from './';

import { BreadCrumbType, PageButtonType, ServiceProgress } from '../../datatypes/datatypes';

import { ipadWidth  } from '../../utils/consts';

import { ColumnType } from './DataTable/DataTypes';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { strings } from "../../services/Localization";

//import AppContext from '../../context/AppContext'



interface PageHeaderProp {
    breadCrumbArr?: BreadCrumbType[];
    pageButtons: PageButtonType[];
    countLabel?: string;
    columns?:ColumnType[];
    name?:string;
    scope?:string;
    serviceProgress?: ServiceProgress;
    dataTableView?: string;
}

const PageHeader: React.FC<PageHeaderProp> = ( { breadCrumbArr, pageButtons , countLabel, columns, name, scope, serviceProgress, dataTableView } ) => {
    var extraClass = "";
    if(document.body.clientWidth > ipadWidth) {
         extraClass = "justify-content-between";
    }

    function getServiceProgressMessage(serviceProgress) {
        const progress = serviceProgress.passed + serviceProgress.failed + serviceProgress.warning;
        const totalDevices = serviceProgress.totalDevices;
        const batchMessage = serviceProgress.totalBatches > 1
            ? strings.BATCH_MESSAGE.replace('{currentBatch}', serviceProgress.currentBatch)
                           .replace('{totalBatches}', serviceProgress.totalBatches)
            : '';
    
        const formattedProgress = progress.toFixed(2);
        return strings.SERVICE_PROGRESS_MESSAGE.replace('{progress}', formattedProgress)
                                       .replace('{totalDevices}', totalDevices)
                                       .replace('{batchMessage}', batchMessage);
    }

    

    return ( 
        <>
            <div className={`row mb-2 mr-0-only d-lg-flex single-page-header ${extraClass} border-2 besides-data-table`} id="page-header" >
                <div className="col-lg-8 col-md-auto col-xs-12" >
                    {breadCrumbArr && <BreadcrumbComponent breadCrumbArr={breadCrumbArr} countLabel={countLabel} name={name} />}
                </div>
                <div  className="col-lg-4 col-md-12 col-xs-12 d-flex flex-sm-row  flex-md-nowrap flex-sm-wrap flex-wrap justify-content-end ">
                    <div style={{minWidth:'250px', marginRight:'10px', display: 'none'}}>
                        <div id="extra-search-box"></div>
                    </div>
                    <PageButtonsComponent pageButtons={ pageButtons } columns={columns}  scope={scope} dataTableView={dataTableView} />
                </div>
            </div> 
            {serviceProgress && serviceProgress.totalDevices !== undefined && 
            <div>
                 {serviceProgress.totalDevices === 0 ? (
                    <ProgressBar className="custom-progress mx-2" now={2} />
                ) : (
                    <>
                    <ProgressBar className="custom-progress mx-2">
                    <ProgressBar striped variant="success" now={serviceProgress.passed} key={1} />
                    <ProgressBar variant="warning" now={serviceProgress.warning} key={2} />
                    <ProgressBar striped variant="danger" now={serviceProgress.failed} key={3} />
                    </ProgressBar>
                    <div style={{fontSize : '11px'}} className="mx-2">
                        <div className="d-flex justify-content-between">
                            <div className="text-muted"> {getServiceProgressMessage(serviceProgress)}</div>
                        </div>
                    </div>
                    </>
                    
                )} 
            </div>
             }
            </>
    )    
}

export {  PageHeader };