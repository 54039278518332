// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.schema-engine-stock-chart-schema {
    width: 100%;
    height: 100%;
}


.schema-engine-stock-chart {
    width: 100%;
    aspect-ratio: 2 / 1;
}
`, "",{"version":3,"sources":["webpack://./app_react/src/schemaengine/client/ExtAmChart.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;;AAGA;IACI,WAAW;IACX,mBAAmB;AACvB","sourcesContent":[".schema-engine-stock-chart-schema {\n    width: 100%;\n    height: 100%;\n}\n\n\n.schema-engine-stock-chart {\n    width: 100%;\n    aspect-ratio: 2 / 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
