import React from "react";
import { IUiSchemaElemArgs,  } from "../../schemaengine/client/SchemaController";
import { registerComponentHandler } from "../../schemaengine/client/SchemaExtensions";
import { DataTable } from "../Common";
import { DataTableOption } from "../Common/DataTable/DataTypes";


const PageTable = (args: IUiSchemaElemArgs) => {

    const name = "test";
    const display = "display"
    const dataTableOptions: DataTableOption = {
        bulkActions: [],
        actions: [],
        columns: [
            {
                key: 'bulk_action_checkbox',
                type: "bulk_action_checkbox",
                title: 'Bulk Action',
                filterable: false,
                cellWidth: 3,
                newCellWidth: "2%"
            },
            {
                key: "deviceuuid",
                type: "text",
                title: "Device UUID",
                maxInputLength:40,
                filterable: true,
                filterField: "search_deveui",
                filterType: "text",
//                filterParams: {
//                    mapper: x => (x || "").replace(/[-:\s]/g, "")
//                },
                sortable: true,
                sortKey: "sort_by_deviceuuid",
                // render: (x) => formatDeveui(x.deveui),
                cellWidth: 50,
                detailLink: true,
                copyLink: true,
                customClass: 'font-monospace',
                newCellWidth: "250px"
            },
        ],

        url: '/uiapi/rest/nodes',
        query_param: {all:true, get_pages:true, limit: 50, stream:'progress'},
        serial_number: false,
        id_field: 'deveui',
        oboe_path: 'pages.*',
        available_key: 'deveui',
        allowBulkActions: true,
        defaultSortField: 'sort_by_deveui',
        defaultSortOrder: 'asc',




        // exportPath: 'uiapi/rest/export/devices?download_filename=devices.csv',
        emptyDataMsg: 'No Device Available',


        modal: false,
        addServiceActions: () => [],
        addBulkServicesActions: () => [],

    }

    const refresh = true;
    const exportInit = 0;
    const countLabel = "CountLabel";
    const isRowDeleted = false;

    return (<DataTable key={args.fullkey}
        name={name}
        display={display}
        dataTableOption={dataTableOptions}
        refresh={refresh}
        exportInit={exportInit}
        countLabel={countLabel}
        isRowDeleted={isRowDeleted} 
    />);
/*
	return (args.embedObject(
        <DataTable 
            name={name}
            display={display}
            dataTableOption={dataTableOptions}
            refresh={refresh}
            exportInit={exportInit}
            countLabel={countLabel}
            isRowDeleted={isRowDeleted} 
        />
    ));
*/
};


registerComponentHandler("backend-pagetable", PageTable);
