// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.livelog-div {
    font-size: 12px;

    border-bottom: 1px solid #e2e2e2
}

.livelog-icon-container {
    height: 20px;
    width: 20px;
    text-align: center;
    background-color: blue;
    border-radius: 50%;
    display: inline-block;
}

.livelog-icon-container-error {
    height: 20px;
    width: 20px;
    text-align: center;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./app_react/src/pages/LiveLog/LiveLog.css"],"names":[],"mappings":";AACA;IACI,eAAe;;IAEf;AACJ;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,qBAAqB;IACrB,kBAAkB;IAClB,qBAAqB;AACzB","sourcesContent":["\n.livelog-div {\n    font-size: 12px;\n\n    border-bottom: 1px solid #e2e2e2\n}\n\n.livelog-icon-container {\n    height: 20px;\n    width: 20px;\n    text-align: center;\n    background-color: blue;\n    border-radius: 50%;\n    display: inline-block;\n}\n\n.livelog-icon-container-error {\n    height: 20px;\n    width: 20px;\n    text-align: center;\n    background-color: red;\n    border-radius: 50%;\n    display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
