import React, { forwardRef, ReactNode, Ref, useImperativeHandle } from "react";


import { registerExtensionLayoutComponent } from "./SchemaExtensions";
import { CardListElem, CardListPropertyElem } from "../UiJsonSchemaTypes";
// import { Col, Row } from "react-bootstrap";
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import "./SchemaLayout.css";


export interface ISchemaLayoutComponentRef {
	reLayout: () => void;
}

export interface ISchemaLayoutComponentProps {
	children: ReactNode;
	cardList: CardListElem[];
	layoutData: any;
	onLayoutDataChange: (layoutData: any) => void;
}




const SchemaLayout = forwardRef((props: ISchemaLayoutComponentProps, ref: Ref<ISchemaLayoutComponentRef>) =>  {

	// Expose the layout function
	useImperativeHandle(ref, () => ({
		reLayout: () => { }
	}));

	const cardMap = (props.children as JSX.Element[]).reduce((prev, cur) => ({ ...prev, [cur.key]: cur}), {});
	let   key = 0;

	const parseCards = (cardList: CardListElem[]) => {
		let defaultSizes = null;
		let type: CardListPropertyElem["type"] = "rows";
		let style = {};
		const elems = [];

		const wrap = (elem) => {
			switch (type) {
			case "rows"          : elems.push(<div className="schema-engine-card-row-container" key={"layout-row-" + key++} style={{ display: "flex", flexDirection: "column", ...style}}>{elem}</div>); break;
			case "split-rows"    : elems.push(<Allotment.Pane key={"layout-row-" + key++}><div style={style}>{elem}</div></Allotment.Pane>); break;
			case "columns"       : elems.push(<div className="schema-engine-card-col-container" key={"layout-col-" + key++} style={{ display: "flex", flexDirection: "row", ...style}}>{elem}</div>); break;
			case "split-columns" : elems.push(<Allotment.Pane key={"layout-col-" + key++}><div style={style}>{elem}</div></Allotment.Pane>); break;
			}
		}

		for (const card of cardList) {
			if (typeof card === "string") {
				if (cardMap[card]) {
					wrap(cardMap[card]);
				}

			} else if (Array.isArray(card)) {
				wrap(parseCards(card));

			} else if (typeof card === "object") {
				if (card.style) { style = card.style; }
				if (card.type) { type = card.type; }
				if (card.defaultSizes) { defaultSizes = card.defaultSizes; }
			}
		}

		switch (type) {
		case "rows"          : return elems;
		case "split-rows"    : return <Allotment vertical={true}>{elems}</Allotment>;
		case "columns"       : return elems;
		case "split-columns" : return <Allotment vertical={false} defaultSizes={defaultSizes}>{elems}</Allotment>;
		default              : return elems;
		}
	}

	const layoutElems = parseCards(props.cardList);

	return <div className="schema-engine-stack-layout" style={{height: "100%"}}>
		{layoutElems}
	</div>;
});

export default SchemaLayout;

registerExtensionLayoutComponent("stack", SchemaLayout);
