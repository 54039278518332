import React, { lazy, Suspense } from "react";
import { IUiSchemaElemArgs } from "./SchemaController";
import { registerComponentHandler } from "./SchemaExtensions";


import "./ExtAmChart.css"

const AmStockChart = lazy(() => import("./AmChart"));


const amChartExtension = (args: IUiSchemaElemArgs) => {

	const { value, fullkey } = args;
	let   { x, y, title, dataset, options } = value;

	if (dataset == null && Array.isArray(x) && Array.isArray(y)) {
		dataset = [];
		for (let i = 0; i < (value.x || []).length; i++) {
			dataset.push({ x: new Date(value.x[i]), y: value.y[i] });
		}
		x = "x";
		y = "y";
	}

	return args.embedObject(
		<Suspense key={fullkey} fallback={<div><i className="fa-regular fa-spinner fa-spin"></i></div>}>
			<AmStockChart keyId={fullkey} dataSet={dataset} x={x} y={y} title={title} options={options}/>
		</Suspense>,
		{ isContainer: true }
	);
};

registerComponentHandler("amchart", amChartExtension);




const amChartExtensionSchema = (args: IUiSchemaElemArgs) => {

	const { value, fullkey } = args;
	return (
		<Suspense key={fullkey} fallback={<div><i className="fa-regular fa-spinner fa-spin"></i></div>}>
			<AmStockChart keyId={fullkey} schema={value}/>
		</Suspense>
	);

};

registerComponentHandler("amchart-schema", amChartExtensionSchema);
