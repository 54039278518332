import React, { forwardRef, Ref, useEffect, useImperativeHandle, useRef } from "react";
import { isMobile } from 'react-device-detect';


import { GridStack } from "gridstack";

import "gridstack/dist/gridstack.min.css";
import "./SchemaLayoutGridStack.css";


import { registerExtensionLayoutComponent } from "./SchemaExtensions";
import { ISchemaLayoutComponentProps, ISchemaLayoutComponentRef } from "./SchemaLayout";



interface ILayoutData {
	[id: string]: {
		x?: number;
		y?: number;
		w?: number;
		h?: number;
	};
}


const SchemaLayoutGridStack = forwardRef((props: ISchemaLayoutComponentProps, ref: Ref<ISchemaLayoutComponentRef>) =>  {

	const ref1 = useRef<HTMLDivElement>(null);
	const gridStackRef = useRef<GridStack>();
	const currentManagedChildren =  useRef<any[]>([]);

	// Expose the layout function
	useImperativeHandle(ref, () => ({
		reLayout: () => {  }
	}));

	useEffect(() => {

		// This effect effect is called on change to the children list. In this case if a new child is added we need to 
		// call the makeWidget() function on it.
		// And if a card has been removed, we need to call removeWidget() on it.

		const pFix = isMobile ? "2" : "";
		const newChildren = [];

		for (const elem of ref1.current.children as any) {

			const id = elem.getAttribute("id");
			const x  = elem.getAttribute("gs-x");

			if (x == null) {
				if (gridStackRef.current) {
					const pos = (props.layoutData || {})[id] || {};
					let x = pos["x" + pFix];
					let y = pos["y" + pFix];
					let w = pos["w" + pFix];
					let h = pos["h" + pFix];
		
					if (w == null) {
						w = pos.w != null ? (pos.w > 12 ? 2 : 1) : (isMobile ? 1 : 2);
					}
					if (h == null) {
						h = pos.h != null ? Math.min(Math.floor(pos.h / 12) + 1, 2) : (isMobile ? 1 : 2);
					}

					const pos1 = { x, y, w, h };
					//console.log("adding", elem);
					gridStackRef.current.makeWidget(elem, { ...pos1 });
				}
			}
			const idx = currentManagedChildren.current.findIndex(e => e === elem);
			if (idx >= 0) {
				currentManagedChildren.current.splice(idx, 1);
			}
			newChildren.push(elem);
		}

		for (const elem of currentManagedChildren.current) {
			if (gridStackRef.current) {
				gridStackRef.current.removeWidget(elem, false);
				//console.log("removing", elem);
			}
		}
		currentManagedChildren.current = newChildren;

	}, [props.children]); // Depend on `children` to re-run when they change


	useEffect(() => {

		// TODO: what kind of options do we need to support
		const pFix = isMobile ? "2" : "";

		const grid = GridStack.init({
			float: true,
	//		fitToContent: true,
	//		cellHeight: "200px",
			minRow: 1,
			column: isMobile ? 2 : 24,
			margin: 5,
			animate: false,

		}, ref1.current);


		grid.batchUpdate();
		grid.removeAll();
		for (const elem of ref1.current.children as any) {
			const id = elem.getAttribute("id");
			const pos = (props.layoutData || {})[id] || {};
			let x = pos["x" + pFix];
			let y = pos["y" + pFix];
			let w = pos["w" + pFix];
			let h = pos["h" + pFix];

			if (w == null) {
				w = pos.w != null ? (pos.w > 12 ? 2 : 1) : (isMobile ? 1 : 2);
			}
			if (h == null) {
				h = pos.h != null ? Math.min(Math.floor(pos.h / 12) + 1, 2) : (isMobile ? 1 : 2);
			}

			const pos1 = { x, y, w, h };
			grid.makeWidget(elem, { ...pos1 });
		}
		grid.batchUpdate(false);
		grid.setAnimation(true);


		grid.on("change", (event, items) => {

			const layoutData: ILayoutData = {};
			const pFix = isMobile ? "2" : "";

			for (const elem of ref1.current.children as any) {
				const id = elem.getAttribute("id");
				const x = elem.getAttribute("gs-x");
				const y = elem.getAttribute("gs-y");
				const w = elem.getAttribute("gs-w");
				const h = elem.getAttribute("gs-h");

				layoutData[id] = {
					...props.layoutData[id],
					...{ ["x" + pFix]: x !== null ? parseInt(x) : undefined },
					...{ ["y" + pFix]: y !== null ? parseInt(y) : undefined },
					...{ ["w" + pFix]: w !== null ? parseInt(w) : undefined },
					...{ ["h" + pFix]: h !== null ? parseInt(h) : undefined },
				}
			}

			// now check if the parameters have change compared to the props.
			const curLayoutData = props.layoutData || {};
			let dif = Object.keys(layoutData).length !== Object.keys(curLayoutData).length;
			if (!dif) {
				for (const key of Object.keys(layoutData)) {
					const o1 = layoutData[key];
					const o2 = curLayoutData[key];
					if (["x", "y", "w", "h"].filter(k => o1[k + pFix] != o2[k + pFix]).length > 0) {
						dif = true;
						break;
					}
				}
			}

			if (dif) {
				props.onLayoutDataChange(layoutData);
			}
		})

		gridStackRef.current = grid;
		
		return () => { gridStackRef.current.destroy(false); }

	}, []);

	return <div ref={ref1} className="my-grid-stack">
		{props.children}
	</div>;

});

export default SchemaLayoutGridStack;



registerExtensionLayoutComponent("gridstack", SchemaLayoutGridStack);
