import React, { useEffect, useState, useContext } from "react";

import { useParams, useNavigate, useLocation /*, Outlet*/ } from 'react-router-dom';

import { strings } from "./../../services/Localization";
import { checkLogin } from "./../../services/Login";
import AppContext from '../../context/AppContext'
import { dialog, dialogDescription } from '../../components/Common';
import { SignInForward } from "../../services/Login";

declare const constants;


import { faArrowRight, faCheckCircle, faTimesCircle, faExclamationTriangle, faAdd, faRefresh  }  from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { IUser } from "src/dassTypes";

import { appBaseUrl, nstBaseUrl, getAppBase } from '../../utils/consts';

import { GenericDassQuery } from "../../services/BasicDassQueries";

import { toast } from "./../../utils/Toaster";
import { actionIcon } from "./../../utils/filters";

import{ DEFAULT_RECORD_LIMIT, ID_INPUT_VALIDATION }  from "../../components/Common/DataTable/DataTableConsts";


import PageContent from "../PageContent";
import { ActionType, BulkActionType, ColumnType, DataTableOption } from "src/components/Common/DataTable/DataTypes";
import { PageButtonType } from "src/datatypes/datatypes";


type AccountType = "logins" | "users" | "customers" | "organisations";

interface IAccountProps {
    accountType: AccountType;
}

interface IAccountsStates {
    loggedUser: IUser | null;
    // accountType: string;
    // url: string;
    // available_key: string;
    // showAlertModal: boolean;
    // pageTitle: string;
    // editEntityId: string;            // The entity in this view is a account
    // schemaMethod: "put" | "post" | "";
    // breadCrumbArr: BreadCrumbType[];
    // countLabel: string;
    refresh:boolean;
    isRowDeleted?: boolean;
}


const Accounts:React.FC<IAccountProps> = ( props ) =>  {
    
    const have_license = true;
    const location = useLocation();
    const isNst = (String(location.pathname).indexOf('/nst'));
    
    let baseUrl = appBaseUrl;

    if(isNst) {
        
        baseUrl = nstBaseUrl;
    }

    //const can_list_profile = "can_list_profile";
        
    const AppContextObj = useContext(AppContext);

    let { id, tabname } = useParams();

    
    const navigate = useNavigate();
    const stateInit = { refresh: false, loggedUser: null, isRowDeleted: false };

    const [state, setState] = useState<IAccountsStates>(stateInit);

    const detailPageNav = ( navigate, tab, id, row) => {

        const prevPageUrl = `${getAppBase()}/accounts/${props.accountType}`;

        if(id) {

            navigate(`/app/accounts/${props.accountType}/${id}/${tab}`, {state: {row: row, prevPageUrl:prevPageUrl}})

        }else {

            navigate(`/app/accounts/${props.accountType}/${tab}`, {state: {tab: tab, prevPageUrl:prevPageUrl}})
        }
    }


    const getAccountTypeProps = (accountType: AccountType) => {

        if(accountType === 'logins') {
            return {
                accountType: 'logins',
                url: '/uiapi/rest/logins',
                available_key: 'userid',
                pageTitle: strings.NAV_LOGINS_ITEM,
                showAlertModal: false,
                editUserId: null,
                breadCrumbArr: [{label:strings.ACCOUNTS, url:''}, {label:  strings.NAV_LOGINS_LIST_USERS, url:''}],
                countLabel: 'Logins'
            }
        } else if(accountType === 'users') {
            return {
                accountType:'users',
                url:'/uiapi/rest/users',
                available_key:'userid',
                pageTitle: strings.NAV_USERS_ITEM,
                showAlertModal: false,
                editUserId: null,
                breadCrumbArr: [{label:strings.ACCOUNTS, url:''}, {label:  strings.NAV_USERS_LIST_USERS, url:''}]
                ,countLabel: 'Users'
            }

        }else if(accountType === 'customers') {
             return {
                accountType: 'customers',
                url: '/uiapi/rest/customers',
                available_key: 'is_customer',
                pageTitle: strings.NAV_CUSTOMERS_ITEM,
                showAlertModal: false,
                editUserId: null,
                breadCrumbArr: [{label:strings.ACCOUNTS, url:''}, {label:  strings.NAV_CUSTOMERS_LIST_CUSTOMERS, url:''}]
                ,countLabel: 'Customers'
            }

        }else {
            
            return {
                accountType: 'organisations',
                url: '/uiapi/rest/organisations',
                available_key: 'is_organisation',
                pageTitle: strings.ORGANIZATION_LIST_TITLE,
                showAlertModal: false,
                editUserId: null,
                breadCrumbArr: [{label:strings.ACCOUNTS, url:''}, {label:  strings.NAV_ORGANIZATION_LIST_USERS, url:''}]
                ,countLabel: 'Organisations'
            }
        }
    }


    useEffect(() => {
        if (constants.enable_profiles !== true) {
            window.location.href = appBaseUrl;
        } else {
            //LoggedIn();
            if(checkLogin(AppContextObj.user)) {
                setState(prevState => {return {...prevState, loggedUser: AppContextObj.user}} )
            }
        }

    },[AppContextObj.user?.userid])




    const addAccount = () => {
        
        
        if(props.accountType === 'logins') {
            tabname = 'add-login';
        } else if(props.accountType === 'users') {
            tabname = 'add-user';
        }else if(props.accountType === 'customers') {
            tabname = 'add-customer';
        }else if(props.accountType === 'organisations') {
            tabname = 'add-organisation';
        }

        detailPageNav(navigate, tabname, '', null);
	};


    const signInForward = async (userid: string) => {
        try {
            
            const user = await SignInForward(userid, baseUrl)
            AppContextObj.updateUser(user);
            
        } catch (e)  {
            console.log(e.message);
            // Show a toast here
        }
	};


	const deleteAccount = async (account: IUser) => {
        const confirmDialogeSettings = {
                title:  "You are about to delete an account",
                description: "Delete '" + account.userid + "'?",
                actionLabel: 'Delete',
        };
        if (await dialog(confirmDialogeSettings) === true) {
            try {

                await GenericDassQuery(`/rest/${props.accountType}/${account.userid}`, { method: "DELETE" });
                refreshTable(true);
                toast.success("Account successfully deleted");
               
            } catch (e) {
                toast.error(e.message);
            }
        }
	}

	const deleteAccounts = async (selectedRows: any[]) => {

        
         const accounts = selectedRows.map((row) => {
             return row['userid'];
         })


        if (accounts.length === 0) { return; }
        const confirmDialogeSettings = {
            title:  `You are about to delete ${accounts.length} account(s)`,
            description: await dialogDescription(accounts),
            actionLabel: 'Delete',
        };

        if (await dialog(confirmDialogeSettings) === true) {
            let successCnt = 0;
            for (const account of accounts) {

                try {
                    
                    await GenericDassQuery(`/rest/${props.accountType}/${account}`, { method: "DELETE" });
                    successCnt++;
                } catch (e) {
                    toast.error(e.message);
                }

                refreshTable(true);
                if (successCnt > 0) {
                    toast.success(`Successfully deleted ${successCnt} account(s)`);
                }
            }
        }

	}

	const updateAccountState = async (account: IUser, account_status: "active" | "suspended" | "disabled") => {

        const msgs = {
            active: {
                title: "You are about to activate an account",
                description: "Activate '" + account.userid + "'?",
                actionLabel: "Activate",
                success: "Account successfully activated"
            },
            suspended: {
                title: "You are about to suspend an account",
                description: "Suspend '" + account.userid + "'?",
                actionLabel: "Suspend",
                success: "Account successfully suspended"
            },
            disabled: {
                title: "You are about to disable an account",
                description: "Disable '" + account.userid + "'?",
                actionLabel: "Disable",
                success: "Account successfully disabled"
            },
        }
        const msg = msgs[account_status];
        const confirmDialogeSettings = {
                title:  msg.title,
                description: msg.description,
                actionLabel: msg.actionLabel,
        };

        if (await dialog(confirmDialogeSettings) === true) {
            try {

                await GenericDassQuery(`/rest/${props.accountType}/${account.userid}`, { method: "PUT", data: {
                    account_status
                }});
                refreshTable();
                toast.success(msg.success);
               
            } catch (e) {
                toast.error(e.message);
            }
        }
	}

    /**
    * Checks if the logged-in user is allowed for certain actions based on  specific conditions.
    * @param user - The user for whom the actions are being checked.
    * @returns `true` if the user is allowed for actions, otherwise `false`.
    */
    const isUserAllowedForActions = (user: IUser) => {
        return !(
            (AppContextObj.user?.org_admin_readonly && !AppContextObj.user?.org_admin  && user?.is_organisation) ||
            (AppContextObj.user?.customer_admin_readonly && !AppContextObj.user?.customer_admin && user?.is_customer) ||
            (AppContextObj.user?.administrator_readonly && !AppContextObj.user?.administrator)
        );
    }

    const getActions = () => {

        const actions: ActionType<IUser>[] = [
            {
                type: "action",
                text: "Delete Account",
                render: (row, title) => actionIcon(row.accountid, title, faTrashAlt.iconName),
                action: (user) => deleteAccount(user),
                visible: (user) => user.account_status == 'active' && isUserAllowedForActions(user)
            },
            {
                type: "action",
                text: "Impersonate Account",
                render: (row, title) => actionIcon(row.accountid, title, faArrowRight.iconName),
                action: (user) => signInForward(user.userid),
                visible: (user) => AppContextObj.user.userid != user.userid  && props.accountType !== 'logins' ? true : false
            },
            {
                type: "action",
                text: "Suspend Account",
                render: (row, title) => actionIcon(row.accountid, title, faExclamationTriangle.iconName),
                action: (user) => updateAccountState(user, "suspended"),
                visible: (user) => have_license && (user.account_status == 'active' || user.account_status == 'disabled') &&isUserAllowedForActions(user)
            },
            {
                type: "action",
                text: "Disable Account",
                render: (row, title) => actionIcon(row.accountid, title, faTimesCircle.iconName),
                action: (user) => updateAccountState(user, "disabled"),
                visible: (user) => have_license && (user.account_status == 'active' || user.account_status == 'suspended') && isUserAllowedForActions(user)
            },
            {
                type: "action",
                text: "Reactivate Account",
                render: (row, title) => actionIcon(row.accountid, title, faCheckCircle.iconName),
                action: (user) => updateAccountState(user, "active"),
                visible: (user) => have_license && (user.account_status == 'suspended' || user.account_status == 'disabled') && isUserAllowedForActions(user)
            },

        ];

        const bulkActions: BulkActionType[] = [{
            type: "action",
            text: "Delete Accounts",
            render: (row, title) => actionIcon(row.accountid, title, faTrashAlt.iconName),
            icon: faTrashAlt,
            action: deleteAccounts,
            visible: (row, index) => isUserAllowedForActions(row)
        }];

        return { actions, bulkActions };
    }





    const getTableOptions = () => {
	            
        let   tabname = 'edit-user';

        if(props.accountType === 'logins') {
            tabname = 'edit-login';
        } else if(props.accountType === 'users') {
            tabname = 'edit-user';
        }else if(props.accountType === 'customers') {
            tabname = 'edit-customer';
        }else if(props.accountType === 'organisations') {
            tabname = 'edit-organisation';
        }
        

		const columns: ColumnType<IUser>[] = [
            {
                key: "userid",
                type: "text",
                title: strings.USER_ID,
                filterable: true,
                filterField: 'search_id',
                filterType: 'text',
                newCellWidth: "180px",
                dataAlign: "left",
                inputValidation: ID_INPUT_VALIDATION,
                filterParams: {
                    mapper: (x) => x || undefined
                },
                detailLink: true,
                detailPageNav: (row) =>  detailPageNav(navigate, tabname, row.userid, row ),
                customClass: 'nowarp',          
            },
            {
                key: "account_status",
                title: strings.STATUS,
                type: "icon_with_tooltip",
                render_icon: (x) => (x.account_status == 'active' ? faCheckCircle : x.account_status == 'disabled' ? faTimesCircle : faExclamationTriangle), 
                render_tooltip: x => x.account_status.charAt(0).toUpperCase() + x.account_status.slice(1),
                filterable: false,
                filterField: '',
                newCellWidth: "70px",
                filterType: '',
                filterParams: {
                    mapper: (x) => x || undefined
                },
                dataAlign:'center',
            },
            {
                key: "administrator",
                title: strings.ADMINISTRATOR,
                type: "boolean",
                filterField: 'search_administrator',
                filterable: true,
                filterType: 'select',
                newCellWidth: "100px",
                filterWidth: "65px",
                filterParams: {
                    options:[
                        {label:strings.YES, value: "true"}
                    ,{label:strings.NO, value: "false"}
                    ]
                },
                render: (user) => user.administrator ? strings.YES : strings.NO,
            },
            {
                key: "can_register",
                title: strings.USER_LIST_REGISTRATION_RIGHTS,
                type: "boolean",
                filterField: 'search_can_register',
                filterable: true,
                newCellWidth: "140px",
                filterWidth: "65px",
                filterType: 'select',
                filterParams: {
                    options:[
                        {label:strings.YES, value: "true"}
                    ,{label:strings.NO, value: "false"}
                    ]
                },
                render: (user) => user.can_register ? strings.YES : strings.NO,
            },
        ];

        if (props.accountType !== "logins") {
            // No devices on logins.
            columns.push({
                key: "num_devices",
                title: strings.USER_LIST_DEVICE_COUNT,
                type: "text",
                newCellWidth: "100px",
                render: (user) => user.num_devices != null ? user.num_devices + "" : "",
                filterable: false,
                dataAlign: "center"
            })
        }



        const { actions, bulkActions } = getActions();


        let accountTypeProps = getAccountTypeProps(props.accountType)

        let options: DataTableOption<IUser> = {
            url: accountTypeProps.url,
            query_param:{all:true, get_pages:true, limit:DEFAULT_RECORD_LIMIT, stream:'progress'},
            serial_number:false,
            id_field:'userid',
            oboe_path:'pages.*',
            available_key:accountTypeProps.available_key,
            columns,
            actions,
            bulkActions,
        }


        return options;
	}

    const refreshTable = (isRowDeleted=false) => {

        setState(prevState => {
            return {...prevState, refresh:!prevState.refresh,isRowDeleted: isRowDeleted}
        })
        
    }

    const getPageButtons = () => {
        let pageButtons: PageButtonType[];
        
        if(props.accountType === 'logins') {
            pageButtons = [
                {
                    title: strings.LOGIN,
                    action: () => addAccount(),
                    type: 'button_with_icon',
                    icon: faAdd,
                    visible: () => AppContextObj.user?.login_admin,
                },
                {
                    title: strings.REFRESH_LIST,
                    action: () => { refreshTable() }
                    ,type: 'button'
                    ,icon: faRefresh
                }
            ]

        } else if (props.accountType === 'users') {
            pageButtons = [
                {
                    title: strings.USER,
                    action: () => addAccount(),
                    type: 'button_with_icon',
                    icon: faAdd,
                    visible: () => AppContextObj.user?.administrator,
                },
                {
                    title: strings.REFRESH_LIST,
                    action: () => { refreshTable() }
                    ,type: 'button'
                    ,icon: faRefresh
                }
            ]
		    
        }else if(props.accountType === 'customers') {
            pageButtons = [
                {
                    title: strings.CUSTOMER,
                    action: () => addAccount(),
                    type: 'button_with_icon',
                    icon: faAdd,
                    visible: () => AppContextObj.user?.customer_admin,
                },
                {
                    title: strings.REFRESH_LIST,
                    action: () => { refreshTable() }
                    ,type: 'button'
                    ,icon: faRefresh
                }
            ]
        }else if(props.accountType === 'organisations') {
            pageButtons = [
                {
                    title: strings.ORGANIZATION,
                    action: () => addAccount(),
                    type: 'button_with_icon',
                    icon: faAdd,
                    visible: () => AppContextObj.user?.org_admin,
                },
                {
                    title: strings.REFRESH_LIST,
                    action: () => { refreshTable() }
                    ,type: 'button'
                    ,icon: faRefresh
                }
            ]
        }
        return pageButtons;
    }

    const accountProps = getAccountTypeProps( props.accountType );

    return (<PageContent
        name={props.accountType} 
        id={id} 
        tabname={tabname} 
        breadCrumbArr={accountProps.breadCrumbArr} 
        pageButtons={getPageButtons()} 
        countLabel={`Accounts`} 
        dataTableOption={getTableOptions()} 
        refresh={state.refresh}
        isRowDeleted={state.isRowDeleted}
        >
    </PageContent>)

}



export default Accounts;
