// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.schema-engine-date-range-picker-container {
    display: flex;
    justify-content: space-between;
    width: 500px;
    height: 530px;
}


.schema-engine-date-range-picker-left-container {
}


.schema-engine-date-range-picker-right-container {
    height: 100%;
    margin-bottom: 15px;
    overflow-y: scroll;
}

`, "",{"version":3,"sources":["webpack://./app_react/src/schemaengine/client/ExtDateRangePicker.css"],"names":[],"mappings":";;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,YAAY;IACZ,aAAa;AACjB;;;AAGA;AACA;;;AAGA;IACI,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":["\n\n.schema-engine-date-range-picker-container {\n    display: flex;\n    justify-content: space-between;\n    width: 500px;\n    height: 530px;\n}\n\n\n.schema-engine-date-range-picker-left-container {\n}\n\n\n.schema-engine-date-range-picker-right-container {\n    height: 100%;\n    margin-bottom: 15px;\n    overflow-y: scroll;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
