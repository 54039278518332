// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaflet-container {
	height: 300px;
	width: 100%;
}

.schema-engine-overlay {
    background:rgba(0,0,0,0.5);
	display: none;
    width: 100%;
	height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99998;
}

.schema-engine-highlight-button {
	border-radius: 10px;
    display: inline-block;
}

.ace_editor, .ace_editor *{
    font-family: "Monaco", "Menlo", "Ubuntu Mono", "Droid Sans Mono", "Consolas", monospace !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    letter-spacing: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./app_react/src/components/SchemaModal/SchemaModal.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,WAAW;AACZ;;AAEA;IACI,0BAA0B;CAC7B,aAAa;IACV,WAAW;CACd,YAAY;IACT,eAAe;IACf,MAAM;IACN,OAAO;IACP,cAAc;AAClB;;AAEA;CACC,mBAAmB;IAChB,qBAAqB;AACzB;;AAEA;IACI,kGAAkG;IAClG,0BAA0B;IAC1B,2BAA2B;IAC3B,4BAA4B;AAChC","sourcesContent":[".leaflet-container {\n\theight: 300px;\n\twidth: 100%;\n}\n\n.schema-engine-overlay {\n    background:rgba(0,0,0,0.5);\n\tdisplay: none;\n    width: 100%;\n\theight: 100%;\n    position: fixed;\n    top: 0;\n    left: 0;\n    z-index: 99998;\n}\n\n.schema-engine-highlight-button {\n\tborder-radius: 10px;\n    display: inline-block;\n}\n\n.ace_editor, .ace_editor *{\n    font-family: \"Monaco\", \"Menlo\", \"Ubuntu Mono\", \"Droid Sans Mono\", \"Consolas\", monospace !important;\n    font-size: 12px !important;\n    font-weight: 400 !important;\n    letter-spacing: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
