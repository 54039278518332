
import React, { lazy, Suspense } from "react";
import { ReactDatePickerProps } from "react-datepicker";

const LocaleDatePicker = lazy(() => import("./LocaleDatePicker"));

export default function LazyLocaleDatePicker(props: ReactDatePickerProps) {
    return (
        <Suspense fallback={<div><i className="fa-regular fa-spinner fa-spin"></i></div>}>
            <LocaleDatePicker {...props}/>
        </Suspense>
    );
};
