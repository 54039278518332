import React, { lazy } from "react";
import { Suspense } from "react";


const SwaggerUi = lazy(() => import("./SwaggerPageComponent"));


export class SwaggerPage extends React.Component<any, any> {

    constructor(props) {
        super(props);
        //this.state = {};
    }

    public render() {
        return (
            <Suspense fallback={<div><div><i className="fa-regular fa-spinner fa-spin"></i></div></div>}>
                {<SwaggerUi url="/uiapi/rest/openapi-schema?dassui=true"></SwaggerUi>}
            </Suspense>
        );
    }
}
