/**
 * layout engine to layout the components inside a card, or of an object
 */
import React from "react";
import { Col, Row } from "react-bootstrap";
import { IElemNode, IColumnElem } from "./SchemaController";


function addRowWithColumns(numColumns: number, columnsElems: IColumnElem[]) {

    const cols: JSX.Element[] = [];
    const size = 12 / numColumns;
    let   key = "";

    for (const colElem of columnsElems) {
        key = key || (colElem.elem as JSX.Element).key + "_row";
        cols.push(
            <Col key={(colElem.elem as JSX.Element).key + "_col"} sm={size * (colElem.options.width || 1)}>
                {colElem.elem}
            </Col>
        )
    }
    key = key || "keyrow";

    return <Row key={key}>{cols}</Row>;
}



export function schemaElemColLayout(elemNodes: IElemNode[], jsxElems: JSX.Element[], columns: number) {

    if (columns) {
        const colElems: IColumnElem[] = [];

        for (const {args, jsxElem} of elemNodes) {
            const uiElem = args.uiElem;

            const colBehaviour = uiElem.colBehaviour || "normal";
            const width = uiElem.colWidth || 1;
            const pos = colElems.reduce((sum, cv) => sum + cv.options.width, 0);
    
            if (colElems.length > 0 && (colBehaviour === "alone" || colBehaviour === "break-before" || colBehaviour === "fullwidth" || pos + width > columns)) {
                jsxElems.push(addRowWithColumns(columns, colElems));
                colElems.splice(0, colElems.length);
            }
            colElems.push({ options: { width }, elem: jsxElem});
    
            if (pos + width >= columns || colBehaviour === "fullwidth" || colBehaviour === "break-after" || colBehaviour === "alone") {
                jsxElems.push(addRowWithColumns(colBehaviour === "fullwidth" ? 1 : columns, colElems));
                colElems.splice(0, colElems.length);
            }
        }

        if (colElems.length > 0) {
            jsxElems.push(addRowWithColumns(columns, colElems));
            colElems.splice(0, colElems.length);
        }
    
    } else {
        for (const {jsxElem} of elemNodes) {
            jsxElems.push(jsxElem as JSX.Element);
        }
    }
}
