import React, { Suspense, lazy } from "react";

import { IUiSchemaElemArgs } from './SchemaController';
import { registerComponentHandler } from "./SchemaExtensions";

const BarChartJs = lazy(() => import("./ChartJs"));


const chartjsExtension = (args: IUiSchemaElemArgs) => {

	const { key, value } = args;
	let   { dataset, x, y } = value;

	if (dataset && typeof x === "string" && typeof y === "string") {
		x = dataset.map(p => p[x]);
		y = dataset.map(p => p[y]);
	}

	const data = {
		labels: x || [],
		datasets: [
		  {
			type: "line",
			label: "Dataset 1",
			borderColor: "rgb(54, 162, 235)",
			borderWidth: 2,
			fill: false,
			data: y || [],
		  },
		],
	};

	return (
		<Suspense key={key} fallback={<div><i className="fa-regular fa-spinner fa-spin"></i></div>}>
			<BarChartJs  data={data as any} />
		</Suspense>
	);
};


registerComponentHandler("chartjs", chartjsExtension);
