import { createContext } from 'react';
import { ISchemaModalHooks } from 'src/components/SchemaModal/SchemaModal';
import { IUser } from 'src/dassTypes';
import { PageStateType } from 'src/datatypes/datatypes';



export interface INavbarState {
    forwardedSignIn: boolean;
    language: string;
    numMenusVisible: number;
    originalUser: any;
    profiles: any;
    signedIn: boolean;
    user: IUser;
    userid_name: string;
    helpdeskMailto: string;
    menu: string;
    click: string;
    smallScreen:boolean;
}
type UserType = "customer" | "organisation" | "application" | "user" | "login";

export interface IDassUiAppContext {

	navbarSchemaHook: ISchemaModalHooks;
	user: IUser;
	updateUser: (user: IUser) => void;
	recordCount: number;
	setRecordCount: (set: (prevstate: number) => number) => void; // NOT USED
	isLoading: boolean;
	setIsLoading: any;

	pageState: PageStateType;
	setPageState: (set: PageStateType | ((prevstate: PageStateType) => PageStateType)) => void;

	navBarState: INavbarState;
	setNavBarState: (set: INavbarState | ((prevstate: INavbarState) => INavbarState)) => void;

	signOut: () => void;

	UserSettings: () => Promise<void>;
	signInForward: () => Promise<void>;
	selectAccount: (account: string) => Promise<void>

	changeLanguage: (event: any) => void;
	isSignedIn: (user: IUser) => boolean;

	loadUser: () => Promise<IUser>;

	accountType: (user: IUser) => UserType;

	CheckSystemErrorTimer: NodeJS.Timeout;
	SessionCheck: (time?: number) => NodeJS.Timeout;

	CheckSystemError: () => Promise<void>;

	countLabel: string;
	setCountLabel: (set: string | ((prevstate: string) => string)) => void;

	isForwardedSignIn: () => boolean;
	
	retrnToOldUser: () => Promise<void>;	
	GetCurrentSessionInfo?: () => any;
	
	debugMessage: string;
	setDebugMessage: (key: string, msg: string) => void;
}

const AppContext = createContext<IDassUiAppContext>(null);

export default AppContext;