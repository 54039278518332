
import { faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import "react-toastify/dist/ReactToastify.css";
import { strings } from "../../services/Localization";
import "./VerifyEmail.css";
import { isMobile } from "react-device-detect";
import { IConstants } from "src/types";

declare const constants: IConstants;
declare const headerContentTypeJson;

interface IVerifyEmail {

    userid: string;
    showContent: boolean;
    emailToken: string;
    waiting: boolean;
    email: string;
    success: boolean;
    messageHtml: string;
}

export class VerifyEmail extends React.Component<any, IVerifyEmail> {

    constructor(props) {
        super(props);
        this.state = {
            userid: "",
            showContent: false,
            emailToken: "",
            email: "",
            waiting: true,
            success: false,
            messageHtml: "",
        };
    }

    public async componentDidMount() {

        const searchObj: any = {};
        if (window.location.search) {
            for (const arg of window.location.search.slice(1).split("&")) {
                const i = arg.indexOf("=");
                if (i > 0) {
                    searchObj[arg.slice(0, i)] = decodeURIComponent(arg.slice(i + 1));
                }
            }
        }

        if (searchObj["email_token"]) {
            try {
                const emailToken = searchObj["email_token"];
                const tok = JSON.parse(atob(emailToken.split(".")[1]));

                this.setState({
                    email: tok.email,
                    emailToken,
                    userid: tok.userid,
                });
                if (Date.now()/1000 + 5 * 60 > tok.exp) {
                    const messageHtml = strings.EMAIL_VERIFICATION_EXPIRED;
                    this.setState({ emailToken: "", messageHtml });
                    return;
                }

                try {
                    await constants.wait;
                    const response = await fetch("/verify_email_token", {
                        credentials: "same-origin",
                        headers: headerContentTypeJson,
                        method: "POST",
                        body: JSON.stringify({ verify_email_token: searchObj["email_token"] }),
                    });
                    await response.text();

                    if (response.status === 200) {

                        const messageHtml = strings.EMAIL_VERIFICATION_SUCCESSFUL;
                        this.setState({ emailToken: "", messageHtml, success: true });
                        return;

                    } else if (response.status === 410) {
                        const messageHtml = strings.EMAIL_VERIFICATION_EXPIRED;
                        this.setState({ emailToken: "", messageHtml });
                        return;
                    }

                 } catch (e) {
                     console.log(e.message);
                 }
            } catch (e) {
                console.log(e.message);
            }
        }

        const messageHtml = strings.EMAIL_VERIFICATION_INVALID;
        this.setState({ emailToken: "", messageHtml });
    }





    public render() {
        const loaderStyle:any = {
            position: 'absolute',
            top: "50%",
            left: "50%"
        }
        const loaderMobileStyle:any = {
            position: 'absolute',
            top: "40%",
            left: "45%"
        }

        return (
            <div>
                <div className="container-fluid background-login results title verify-email">
                    <div className="row login-wrapper">
                        <div className="form-signin">

                                <div className="verify-email-status-panel">

                                    <div className="container">

                                        {!this.state.messageHtml && (
                                            <Col sm={12} style={isMobile ? loaderMobileStyle : loaderStyle} className="LoaderWrapper">
                                                <i className="fas fa-spinner fa-spin fa-5x"></i>
                                            </Col>
                                        )}
                                        {this.state.messageHtml &&  <Alert  variant="success">

                                            <div className="verify-email-message">
                                                {!this.state.success && <FontAwesomeIcon icon={faExclamationTriangle} size={"lg"}/>}
                                                {this.state.success && <FontAwesomeIcon icon={faCheckCircle} size={"lg"}/>}

                                            <span className="form-verify-email-message"
                                                dangerouslySetInnerHTML={{__html: this.state.messageHtml }}>
                                            </span>
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            <a href="/app/SignIn" className="returnLogin a-link">
                                                {strings.RETURN_TO_LOGIN}
                                            </a>
                                        </div>

                                        </Alert>}
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
