import { faCloudArrowUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import { useDropzone } from "react-dropzone";

export const FileDropZone = (props) => {

    const [selectedFiles, setSelectedFile] = React.useState([]);

    const onDrop = React.useCallback((acceptedFiles) => {

        setSelectedFile(acceptedFiles.map((file) => {
            props.uploadFile(file);

            return Object.assign(file, {
                preview: URL.createObjectURL(file)
            })
        }))
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const selected_files = selectedFiles?.map((file, index) => {
        return (<div key={index}>{file.path}</div>)
    })

    return (
        <div className="d-flex flex-1 w-100">
            <div className="d-flex align-items-center justify-content-center w-100" style={{ border: '2px dashed' }}>
                <div {...getRootProps()} className="d-flex flex-column align-items-center justify-content-center w-100 py-3" >
                    <input {...getInputProps()} className="w-100 h-100"></input>
                    <div style={{ paddingBottom: 5 }} >
                        <FontAwesomeIcon size="4x" icon={faCloudArrowUp} />
                    </div>
                    <div style={{ paddingBottom: 5 }}>
                        <Button variant="outline-dark">{`Choose CSV/JSON/JSONL files to upload`}</Button><br />
                    </div>
                    <div style={{ paddingBottom: 5 }}>
                        {selected_files.length > 0 && selected_files}
                        {selected_files.length === 0 && `or drag and drop theme here`}
                    </div>

                </div>
            </div>
        </div>
    )
}