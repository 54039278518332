import React, {useState, useEffect, useContext } from "react";
import { useParams, useNavigate  } from 'react-router-dom';
//import { Outlet  } from 'react-router-dom';

import { faRefresh }  from '@fortawesome/free-solid-svg-icons';

import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

import { strings } from "./../../services/Localization";
import { checkLogin } from "./../../services/Login";
import AppContext from '../../context/AppContext'
import { /*DataTable, PageHeader, DetailPageContainer, DataTableWrapper,*/ dialog } from '../../components/Common';

import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { toast } from "./../../utils/Toaster";

//import { AlertModal } from "./../../components/Modals/AlertModal";

import { appBaseUrl, getAppBase } from '../../utils/consts';


import { TAB_ACCEPT_GATEWAY } from '../../datatypes/tabsconstants'
import PageContent from "../PageContent";

import { IUser } from "../../../src/dassTypes";
import { ActionType, BulkActionType, ColumnType, OptionType } from "../../../src/components/Common/DataTable/DataTypes";

declare const constants;


export const pageTitle = "Pending Gateways"
export const pageUrlPart = "blocked-gateways";



export interface IRowType {
    id: number;
    uuid: string;
    date: string;
    reason: string;
    usedPwd: string;
    gtwIp: string;
    occurence: number;
    tenantid: string;
}




type FilterOptionType = {
    label: string;
    value: string;
}

interface IBlockedGatewaysStates {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    pageTitle: string;
    editEntityId: string | null;            // The entity in this view is a gateway
    schemaMethod: "post" |"put" | "" | string;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;
    categoryEnum: FilterOptionType[];
    isRowDeleted?:boolean;
}

interface IBlockedGatewaysProps {
}




const BlockedGateways: React.FC<IBlockedGatewaysProps> = (props) =>  {

    const { id, tabname } = useParams();
    const navigate = useNavigate();
    const AppContextObj = useContext(AppContext);



    const stateInit: IBlockedGatewaysStates = {
        loggedUser: null,
        showAlertModal: false,
        pageTitle,
        editEntityId: '',
        schemaMethod: "",
        breadCrumbArr: [{label:strings.NAV_GATEWAYS_ITEM, url:`${getAppBase()}/omc-gateways`}
        ,{label: pageTitle, url:''}],
        refresh:false,
        categoryEnum: [],
        isRowDeleted:false
    };

    const [state, setState] = useState<IBlockedGatewaysStates>(stateInit)

    //const can_list_profile = "can_list_profile";

    useEffect(() => {
        if (constants.enable_profiles !== true) {
            window.location.href = appBaseUrl;
        } else {
            if(checkLogin(AppContextObj.user)) {

                setState(prevState => { return {...prevState, loggedUser: AppContextObj.user}})
                // user = AppContextObj.user;

            }else {
                window.location.href = appBaseUrl + "/signin";
            }
            //LoggedIn();
        }
    },[])




    const detailPageNav = ( navigate, tab, id, row = null) => {
        if(id) {
            navigate(`${getAppBase()}/${pageUrlPart}/${id}/${tab}`, {state: {row: row, prevPageUrl:`${getAppBase()}/blocked-gateways`}})
        }
    }

/*
    const detailPageAdd = ( navigate, tab) => {

        navigate(`${getAppBase()}/${pageUrlPart}/${tab}`, {state: {tab: tab}})
    }
*/



    const deleteRow = async ( param: IRowType ) => {

        const confirmDialogeSettings = {
            title:  "You are about to delete a authorization request",
            description: "Delete '" + param.uuid + "' request?",
            actionLabel: 'Delete',
        };

        if (await dialog(confirmDialogeSettings) === true) {
            try {
                await GenericDassQuery("/rest/omc/blocked-gateways/" + param.id, { method: "DELETE" });
                toast.success("Gateway authorization request is deleted");
            } catch (e) {
                toast.error(e.message);
            }
            refreshTable(true);
        }
    }

    const deleteBlockedGateways = async (selectedRows: IRowType[]) => {
        
        const blockedgtwids = selectedRows.map((row) => {
            return row['id'];
        })

        if(blockedgtwids && blockedgtwids.length > 0) {
            const confirmDialogeSettings = {
                title:  `You are about to delete ${blockedgtwids.length} blocked gateways`,
                description:'',
                actionLabel: 'Delete',
            };
    
            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(blockedgtwids.map((blockedgtwId) => {
                        return GenericDassQuery("/rest/omc/blocked-gateways/" + blockedgtwId, { method: "DELETE" })
                    })).then((values) => {
                        refreshTable(true);
                        toast.success("Gateways authorization request is deleted");
                    });
                } catch (e) {
                    toast.error(e.message);
                }                
            }
        }
    }

/*
    const acceptRow = async ( row: IRowType ) => {

        let exists = false;
        let gatewayName = "";
        try {
            const gateway = await GenericDassQuery("/rest/omc/gateways/" + row.uuid);
            gatewayName = gateway.data.name;
            exists = true;
        } catch (e) {}

        const confirmDialogeSettings = {
            title:  exists ? "You are about to re-authorize a gateway" : "You are about to authorize a gateway",
            description: "Authorize '" + row.uuid + "'" + (gatewayName ? " (" + gatewayName + ")" : "") + "?",
            actionLabel: 'Authorize',
        };

        if (await dialog(confirmDialogeSettings) === true) {
            try {
                if (exists) {
                    await GenericDassQuery("/rest/omc/gateways/" + row.uuid, {
                        method: "PUT",
                        data: { authKey: row.usedPwd },
                    });
                } else {
                    await GenericDassQuery("/rest/omc/gateways", {
                        method: "POST",
                        data: { uuid: row.uuid, authKey: row.usedPwd },
                    });
                }

                await GenericDassQuery("/rest/omc/blocked-gateways/" + row.id, { method: "DELETE" });
                toast.success("Gateway is authorized");

            } catch (e) {
                toast.error(e.message);
            }
            refreshTable();
        }
    }
*/



    const  getActions = () => {

        const actions: ActionType[] = [
/*
            {
                type: "action",
                text: "Authorize Gateway",
                icon: faCheck,
                action: (tag) => acceptRow(tag),
                visible: (tag) => true,
            },
*/
            {
                type: "action",
                text: "Delete Authorize Request",
                icon: faTrashAlt,
                action: (tag) => deleteRow(tag),
                visible: (tag) => true,
            }
        ];
        const bulkActions: BulkActionType[] = [
            {
                type: "action",
                text: "Delete Authorize Requests",
                icon: faTrashAlt,
                action: (selectedRows) =>  deleteBlockedGateways(selectedRows),
            }
        ];

        return { actions, bulkActions };
    }


    const  initDataTable = () => {

        let actions;
        var pageButtons;

        actions = getActions();

        const columns: ColumnType[] = [
            {
                key: "uuid",
                title: "Gateway ID",
                type: "text",
                sortKey: "sort_by_uuid",
                sortable: true,
                filterable: true,
                filterField: 'search_uuid',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined,
                },
                detailLink: true,
                detailPageNav: (row: IRowType) => detailPageNav(navigate, TAB_ACCEPT_GATEWAY, row.id, row),
                cellWidth:16,
                newCellWidth: "180px"
            },

            {
                key: "reason",
                title: "Reason",
                type: "text",
                sortKey: "sort_by_reason",
                sortable: true,
                filterable: true,
                filterField: 'search_reason',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined,
                },
                detailPageNav: (row: IRowType) => detailPageNav(navigate, TAB_ACCEPT_GATEWAY, row.id, row),
                cellWidth:40,
                newCellWidth: "150px"
            },
            {
                key: "date",
                title: "Date",
                type: "text",
                sortKey: "sort_by_date",
                sortable: true,
                filterable: false,
                detailPageNav: (row: IRowType) => detailPageNav(navigate, TAB_ACCEPT_GATEWAY, row.id, row),
                cellWidth:40,
                newCellWidth: "100px"

            },
            {
                key: "usedPwd",
                title: "Authorization key",
                type: "text",
                sortKey: "sort_by_usedpwd",
                sortable: true,
                filterable: false,
                detailPageNav: (row: IRowType) => detailPageNav(navigate, TAB_ACCEPT_GATEWAY, row.id, row),
                cellWidth:16,
                newCellWidth: "150px"
            },
            {
                key: "occurence",
                title: "Attempts",
                type: "text",
                sortKey: "sort_by_occurence",
                sortable: true,
                filterable: false,
                detailPageNav: (row: IRowType) => detailPageNav(navigate, TAB_ACCEPT_GATEWAY, row.id, row),
                cellWidth:2,
                dataAlign:'center'

            },
        ];

        let options: OptionType = {
            url:'/uiapi/rest/omc/blocked-gateways',
            query_param: {
                get_pages: true,
                limit: 20,
                stream: 'progress',
            } as any,                       // TODO: if optional parameters are not provided here, then where?
            serial_number: false,
            id_field: 'id',
            oboe_path: 'pages.*',
            available_key: 'id'
        }

        return {...actions, columns:columns, ...options, ...pageButtons };
	}

    const refreshTable = (isRowDeleted=false) => {
        setState(prevState => {
            return {...prevState, refresh:!prevState.refresh, isRowDeleted:isRowDeleted}
        })
    }


    const  getPageButtons = () => {

        var  pageButtons: PageButtonType[] = [

            {
                title: strings.REFRESH,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh,
            },
        ]

        return pageButtons;
    }

    return (<PageContent
        name="blocked-gateway" 
        id={id} 
        tabname={tabname} 
        actions={getActions()} 
        breadCrumbArr={state.breadCrumbArr} 
        pageButtons={getPageButtons()} 
        countLabel={`Requests`} 
        isRowDeleted={state.isRowDeleted}
        dataTableOption={initDataTable()} 
        refresh={state.refresh}>
    </PageContent>)


}

export default BlockedGateways;