// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#leaflet-map {
    height: calc(100vh - 81px);
}

#leaflet-map .leaflet-control-zoom {
    margin-top: 20px;
    margin-bottom: 40px;
}

#leaflet-map .marker_pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #37a7da;
    position: absolute; 
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -15px 0 0 -15px;
}

#leaflet-map .marker_pin > i {
    position: absolute;
    width: 22px;
    font-size: 16px;
    left: 0;
    right: 0;
    margin: 8px auto;
    text-align: center;
    transform: rotate(45deg);
    color: #fff;
}

#leaflet-map .device_icon .marker_pin {
    background: #d33d2a;
}


/* .mycluster {
    width: 40px;
    height: 40px;
    background-color: greenyellow;
    text-align: center;
    font-size: 24px;
  }
  
  .custom-marker-cluster{
    background: #ffffff;
    border: 1px solid #f00800;
    border-radius: 50%;
    color: #000000;
    height: 33px;
    line-height: 37px;
    text-align: center;
    width: 33px;
  } */`, "",{"version":3,"sources":["webpack://./app_react/src/components/Device/NetworkMap/Map/style.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,4BAA4B;IAC5B,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,SAAS;IACT,QAAQ;IACR,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,OAAO;IACP,QAAQ;IACR,gBAAgB;IAChB,kBAAkB;IAClB,wBAAwB;IACxB,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;;AAGA;;;;;;;;;;;;;;;;;KAiBK","sourcesContent":["#leaflet-map {\n    height: calc(100vh - 81px);\n}\n\n#leaflet-map .leaflet-control-zoom {\n    margin-top: 20px;\n    margin-bottom: 40px;\n}\n\n#leaflet-map .marker_pin {\n    width: 30px;\n    height: 30px;\n    border-radius: 50% 50% 50% 0;\n    background: #37a7da;\n    position: absolute; \n    transform: rotate(-45deg);\n    left: 50%;\n    top: 50%;\n    margin: -15px 0 0 -15px;\n}\n\n#leaflet-map .marker_pin > i {\n    position: absolute;\n    width: 22px;\n    font-size: 16px;\n    left: 0;\n    right: 0;\n    margin: 8px auto;\n    text-align: center;\n    transform: rotate(45deg);\n    color: #fff;\n}\n\n#leaflet-map .device_icon .marker_pin {\n    background: #d33d2a;\n}\n\n\n/* .mycluster {\n    width: 40px;\n    height: 40px;\n    background-color: greenyellow;\n    text-align: center;\n    font-size: 24px;\n  }\n  \n  .custom-marker-cluster{\n    background: #ffffff;\n    border: 1px solid #f00800;\n    border-radius: 50%;\n    color: #000000;\n    height: 33px;\n    line-height: 37px;\n    text-align: center;\n    width: 33px;\n  } */"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
